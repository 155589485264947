.container {
    padding: 4.213em 0;
    margin: auto;
    display: block;
    max-width: 75em;
}


.heading {
    display: block;
    overflow: auto;
    /* border: solid; */
}

.title {
    /* float: left; */
    color: #450028;
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text_body {
    color: #747474;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: keep-all;
    /* text-align: left; */
}

.sub_container {
    display: flex;
    flex-wrap: wrap;
    /* border: solid; */
    /* align-content: center;
  justify-content: center; */
}


@media (max-width: 1024px) {
    .container {
        padding: 4.213em 0;
        margin: auto 1em;
        overflow: hidden;
    }

    .title {
        display: block;
        float: none;
        text-align: left;
        font-size: 23px;
    }

    .text_body {
        text-align: left;
        font-size: 18px;
    }

    .sub_container {
        gap: 1em;
        flex-wrap: nowrap;
        /* margin: auto 1em; */
        transition: margin-left .5s linear;
        scroll-behavior: smooth;
    }

}