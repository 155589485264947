.container {
    padding: 2em 0;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    max-width: 75em;
}

@media (max-width: 1024px) {
    .container {
        padding: 2em 0;
        flex-direction: column;
        /* border: solid; */
    }
}