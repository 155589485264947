.container {
    background: #FFE7F4;
    padding: 8.125em 0 7.216em;
}

.tag {
    color: #8F8F8F;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.title {
    color: var(--Primary, #EB2590);
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 52.36px */
    text-transform: capitalize;
    word-break: keep-all;
  
}

.dynamic {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
    text-transform: capitalize;
    
}

.frmInp {
    display: block;
    margin: auto;
    width: 36.077em;
}

.lblEmail, .lblMessage {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    margin: auto;
    margin-bottom: .3em;
    max-width: 36.577em;
    /* border: solid; */
    margin-top: 4em;
}

.txtEmail, .txtMessage {
    background: url("/public/images/mail.png") no-repeat 13px center;
    background-color: #FFF;
    padding-left: 3em;
    border: none;
    width: 36.077em;
    height: 3.931em;
    display: block;
    margin: auto;
}

.lblMessage {
    margin-top: 1.3em;
}

.txtMessage {
    background: #fff;
    height: 10.505em;
    padding-top: 1em;
    resize: none;
}

.sub {
    width: 25.077em;
    height: 2.375em;
    display: block;
    margin: auto;
    margin-top: 1em;
    border-radius: 12px;
    border: 1px solid var(--Primary, #EB2590);
    background: #EB2590;
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sub:hover {
    cursor: pointer;
    color: #EB2590;
    background: #FFF;
}

@media (max-width: 1024px) {

    .container {
        padding-bottom: 0;
    }

    .tag {
        font-size: 12px;
    }

    .title, .dynamic {
        font-size: 31px;
        
    }

    .title {
        width: 85%;
        display: block;
        margin: auto;
        margin-bottom: 2em;
    }

    .frmInp {
        width: 100%;
        background: #FFF;
        padding-top: 4em;
        /* border: solid; */
        padding-left: 1em;
        padding-right: 1em;
    }

    .lblEmail, .lblMessage {
        width: 100%;
        font-size: 13px;
        /* border: solid; */
    }
    
    .txtEmail, .txtMessage {
        width: 100%;
        background-color: #F9F9F9;
        border-radius: 9px;
        height: 2.875em;
        font-size: 15px;
        margin-bottom: 4em;
        /* padding-left: 1em; */
        
    }
    .txtMessage {
        width: 100%;
        padding-top: .5em;
        height: 10.505em;
        text-align: left;
        padding-left: .3em;
        font-size: 12px;
    }

    .sub {
        width: 16.077em;
        font-size: 15px;
    }
}