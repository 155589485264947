.container {
    background: #F5F5F5;
    padding: 10.188em 3.75em;
}

.top {
    max-width: 75em;
    margin: auto;
    display: block;
}

.top_text {
    display: flex;
    border-radius: 0px 0px 37px 37px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url("/public/images/pattern_white.png"), lightgray 50% / cover no-repeat;
    background-color: #141414;
    background-blend-mode: soft-light;
    padding: 9.063em 4.438em 6.438em;
    /* height: 20.75em; */
}



.img {
    display: block;
    width: 100%;
}

.top_title {
    flex: 1;
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 47px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: center;
}

.top_body {
    flex: 1;
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 29px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.link > a {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.link::after {
    content: url("/public/images/Arrow_right_gray.png");
    vertical-align: middle;
    margin-left: 1em;
}

.sub_container {
    display: flex;
    max-width: 75em;
    margin: auto;
}

.sub_title {
    color: var(--Footer-Background, #141414);
    /* text-align: center; */
    font-family: 'Lato', sans-serif;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 44.03px */
    text-transform: capitalize;
}

.text_body {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.applications, .sessions {
    flex: 1;
    padding: 6.125em 5.188em;
}

.applications > .sub_title::before {
    content: url("/public/images/applications.png");
    margin-right: 1em;
}

.sessions > .sub_title::before {
    content: url("/public/images/sessions.png");
    margin-right: 1em;
}

.tab {
    display: none;
}


@media (max-width: 1024px) { 
    .container {
        padding: 10.188em 1em 14.188em;
    }

    .top_text {
        display: block;
        padding: 9.063em 1em 6.438em;
    }

    .link > a {
        font-size: 16px;
    }

    .link::after {
        margin-left: .5em;
    }

    .sub_container {
        display: flex;
        overflow: hidden;
        scroll-behavior: smooth;
        transition: margin-left .5s linear;
        /* padding-right: .5em; */
        /* border: solid; */
        gap: 1em;
        /* margin-right: 1em; */
        /* word-wrap: break-word; */
    }

    .sub_title {
        font-size: 33px;
    }

  
    .top_title {
        font-size: 28px;
    }

    .top_body {
        font-size: 20px;
        /* border: solid; */
        max-width: 14.5em;
        margin: auto;
        display: block;
    }

    
    .tab {
        border-radius: 20px;
        background: #ECECEC;
        display: block;
        margin: auto;
        /* width: 19.625em; */
        height: 2.538em;
        padding: .65em 0;
        text-align: center;
        display: flex;
        margin-top: 3em;
    }

    .tab1 {
        flex: 1;
        color: #545454;
        text-align: center;
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 119%; /* 20.23px */
        text-transform: capitalize;
    }

    .tab2 {
        flex: 1;
        color: #545454;
        text-align: center;
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 119%; /* 20.23px */
        text-transform: capitalize;
    }

    .active {
        background: #848484;
        color: #FFF;
        border-radius: 20px;
        width: 19.625em;
        height: 2.538em;
        padding: .65em 0;
        margin: -.7em 0;
    }

    .sub_title {
        display: none;
    }
  
    
    
    .applications, .sessions {
        display: flex;
        padding: 3.125em 0;
        justify-content: space-between;
        align-items: center;
        min-height: 16em;
        max-height: 16em;
        /* border: solid; */
        /* min-width: 100vw;
        max-width: 100vw; */
        /* padding-right: 1em; */
        min-width: 95vw;
        max-width: 95vw;
    }

    .applications > .sub_logo {
        flex: .1;
        display: block;
        content: url("/public/images/applications.png");
        margin-right: 1em;
    }
    
    .sessions > .sub_logo {
        flex: .1;
        display: block;
        content: url("/public/images/sessions.png");
        margin-right: 1em;
        margin-left: .5em;
    }
    
    .text_body {
        flex: 1;
        color: var(--Footer-Dark, #747474);
        font-family: 'Lato', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        /* word-wrap: break-word; */
        /* padding-right: 1em; */
        margin-right: 1em;
    }

    .sessions > .text_body {
        margin-left: 1em;
    }
}