.container {
    padding: 3em 0 2em;
    display: block;
    max-width: 75em;
    margin: auto;
}

.title {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 44.03px */
    text-transform: capitalize;
}

.text_body {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.next_line {
    display: block;

}

@media (max-width: 1024px) {
    .container {
        padding: 2em 1em 2em;
        display: block;
        text-align: center;
    }

    .next_line {
        display: inline;
    }

    .title {
        font-size: 33px;
        max-width: 9em;
        margin: .5em auto;
    }

    .text_body {
        font-size: 13px;
        max-width: 22em;
        margin: auto;
    }
}