.container {
    padding: 1em 4.5em;
}


.title {
    color: #450028;
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%; /* 37.62px */
    text-align: left;
    max-width: 23.4em;
    /* border: solid; */
    margin: auto;
    margin-top: 1em;
}

.note {
    color: var(--text-2, #283655);
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%; /* 17.1px */
    text-align: left;
    max-width: 50.4em;
    /* border: solid; */
    margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;
   
}

.sub_container {
    display: block;
    padding: 4.213em 17.868em;
    border-radius: 28px;
    background: #FFF8FD;
}

.form {
    display: block;
    max-width: 50.4em;
    margin: auto;
    /* border: solid; */
}


.row {
    display: flex;
    margin: auto;
    /* border: solid; */
  
    /* overflow: auto; */
}

.form_group, .form_group_single {
    flex: 1;
    /* display: inline-block; */
    /* float: left; */
    /* border: solid; */
    /* margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 1em;
    margin-top: .5em;
    width: 22.813em; */
}

/* .form_group_single {
    width: 48em;
} */

.label {
    color: #283655;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%; /* 13.68px */
    display: block;
    padding-left: .5em;
    margin-top: 0.906em;
    margin-bottom: .3em;
    /* border: solid; */
}

.form_group > input[type = 'text'], .form_group > input[type = 'email'], .file, .select, .form_group_single > input[type = 'text']  {
    border-radius: 10px;
    background: #F4F4F7;
    color: var(--text-2, #283655);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%; /* 13.68px */
    padding: 1.438em 1.371em;
    margin-bottom: .3em;
    border: none;
    width: 90%;
}

.form_group_single  > .select {
    width: 100%;
}

.form_group  > .select {
    width: 100%;
}

.file, .form_group_single > input[type = 'text']  {
    width: 95%;
}

.file, .position {
    /* width: 95%; */
    border-radius: 10px;
    border: 1px dashed #C9C9C9;
    background: transparent;
}

input[type = 'file'] {
    display: none;
}

.form_group > label:hover {
    cursor: pointer;
}

.submit {
    clear: both;
    display: block;
    border-radius: 5.626px;
    background: var(--SHE-COLOR-1, #EB2590);
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 17.514px;
    font-style: normal;
    font-weight: 400;
    line-height: 153.2%; /* 26.832px */
    text-transform: uppercase;
    height: 3.516em;
    width: 14.125em;
    border: none;
    margin-top: 5em;
    display: inline-block;
}

.step_nav {
    display: inline-block;
    margin-left: 20em;
    /* border: solid; */
    vertical-align: bottom;
}

.step_note {
    color: var(--text-2, #283655);
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%; /* 18.24px */

}

.indicator_container {
    display: block;
    padding: 0 .75em;
}

.indicator {
    width: 2.625em;
    height: 0.5em;
    border-radius: 2px;
    background: #E3E3E3;
    display: inline-block;
    margin: auto .2em;
}

.active {
    border-radius: 2px;
    background: var(--Primary, #EB2590);
}

.navigator {
    display: flex;
    max-width: 50em;
    margin: auto;
    margin-bottom: 4em;
    /* border: solid; */
}

.step {
    flex: 1;
    /* color: #EB2590; */
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.24px;
}

.img {
    height: 2px;
    width: 50px;
    /* vertical-align: middle; */
    margin: auto;
    margin-right: 3em;
}

.num {
    background: #767676;
    color: #FFF;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px; /* 150% */
    letter-spacing: 0.16px;
    width: 32px;
    height: 32px;
    padding: .7em 1em;
    border-radius: 50px;
    vertical-align: middle;

}

.active_step > .num {
    background: #EB2590;
    color: #fff;
}

.active_step {
    color: #EB2590;
}


@media (max-width: 1024px) {
    .container {
        padding: 1em 1em;
    }

    .sub_container {
        padding: 3em 1em;
    }

    .row {
        flex-direction: column;
    }
    
  
 
    
    .form_group, .form_group_single {
        width: 100%;
        /* border: solid; */
        margin: auto;
        display: block;
    }
    

    .form_group > input[type = 'text'], .form_group > input[type = 'email'], .file, .select {
        width: 100%;
    }

    .file {
        width: 100%;
    }

    .submit {
        /* clear: both; */
        width: 13em;
        height: 2em;
        margin: auto;
        display: block;
        margin-top: 4em;
    }

    .form_group_single > input[type = 'text']  {
        width: 100%;
    }

    .title {
        max-width: 8em;
        /* border: solid; */
        margin: auto;
        margin-top: 1em;
    }
    
    .note {
        max-width: 18em;
        /* border: solid; */
        margin: auto;
        margin-top: 2em;
        margin-bottom: 2em;
       
    }

    .num {
       padding: .2em .5em;
    }

    .step_nav {
        display: block;
        margin: auto;
        /* border: solid; */
        text-align: center;
        margin-top: 1em;
    }

    .step {
        color: #747474;
        font-family: 'Lato', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        /* margin-right: 1; */
        /* border: solid; */
        min-width: 6em;
        max-width: 6em;
    }

    .step:last-child {
        min-width: 9em;
        max-width: 9em;
    }

    .img {
        height: 2px;
        width: 30px;
        margin: auto;
        margin-top: .7em;
        /* margin-right: 0em; */
        /* margin-left: 0; */
        /* border: solid; */
    }
    
}