.container {
    padding: 10.5em 4.313em;
    margin: auto;
    display: block;
    max-width: 75em;
}

.quote {
    color: #000;
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    max-width: 26.875em;
    float: right;
    overflow: auto;
}

.highlight {
    color: var(--Secondary, #F73);
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.quoter {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: right;
    clear: both;
}

@media (max-width: 1024px) { 
    .container {
        padding: 10.5em 1em 6.5em;
    }

    .quote {
        text-align: right;
        font-size: 20px;
        float: none;
    }

    .quoter {
        font-size: 13px;
    }

    .highlight {
        font-size: 20px;
    }
}