.container {
    background: #FFF8FD;
    padding: 6.438em 0 17.438em 0;
}

.sub_container {
    display: flex;
    margin: auto;
    max-width: 75em;
}

.step-one {
    width: 23.063em;
    border-radius: 20px;
    background: #EB2590;
}

.step-one > span {
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: block;
    text-align: left;
    padding: .5em 0 .5em 3.188em;   
}

.step-two {
    width: 31.875em;
    background: #FF3CA5;
}

.step-two > span {
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: block;
    text-align: left;
    padding: .5em 0 .5em 3.188em; 
}

.step-three {
    width: 40.938em;
    background: #F598D8;
}

.step-three > span {
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 49px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: block;
    text-align: left;
    padding: .5em 0 .5em 3.188em; 
}

.partition-one {
    flex: 1;
}
.partition-two {
    flex: 0.5;
    /* border: solid; */
}

.img {
    width: 16.673em;
    height: 23.125em;
    /* margin-top: -24em; */
    margin-left: 15.1em;
    margin-bottom: -7.9em;
}

.title {
    color: #000;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 2.96em;
    word-break: keep-all;
    margin-left: auto;
    margin-right: 0;
}

.mission {
    color: var(--Primary, #EB2590);
    font-family: 'Playfair Display', serif;
    font-size: 70px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}

.text-body {
    color: #5D5D5D;
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: keep-all;
    /* width: 18em; */
    /* border: solid; */
    margin: auto;
}
.kettle {
    margin-right: -15.2em;
    margin-left: 9.75em;
    margin-bottom: -12.29em;
}
.cash {
    margin-right: -12.5em;
    margin-left: 10.3em;
    margin-bottom: -8.65em;
}

@media (max-width: 1024px) {
    .container {
        display: block;
        padding: 5.438em 1.2em 12.438em 1.2em;
    }

    .sub_container {
        flex-direction: column;
        max-width: auto;
    }

    .img {
        width: 6.978em;
        height: 9.678em;
        margin: auto;
        position: absolute;
        margin-top: -6.5em;
        margin-left: 6.3em;
        margin-bottom: -7em;
        display: block;
        /* border: solid; */
    }

    .step-one {
        
        border-radius: 20px;
        width: 10.7em; 
    }
    .step-one > span {
        display: block;
        font-size: 25px;
        padding: .4em .6em .4em 1.3em;
        text-align: right;
    }

    .step-two {
        width: 14em;
    }
    .step-two > span {
        font-size: 25px;
        display: block;
        padding: .5em .6em .5em 0em; 
        width: 7.9em;
        text-align: right;
    }
    
    .step-three {
        width: 17em;
    }
    .step-three > span {
        font-size: 25px;
        display: block;
        padding: .5em .6em .5em 1.3em; 
        /* width: 6.5em; */
        text-align: right;
    }
    
    .kettle {
        margin: -1.5em 0 0 5em;
        position: absolute;
        width: 1.71em;
        height: 1.51em;
    }
    .cash {
        margin: -2.3em 0 0 5.2em;
        position: absolute;
        width: 1.31em;
        height: 1.01em;
    }

    .title, .mission {
        font-size: 42px;
    }
    .text-body {
        text-align: right;
        word-break: keep-all;
        width: auto;
        font-size: 16px;
    }

    .partition-one {
        flex: none;
    }
    .partition-two {
        flex: none;
        /* border: solid; */
    }
}