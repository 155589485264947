.title {
    color: #450028;
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* padding-left: 2.991em; */
    word-break: keep-all;
    /* border: solid; */
    max-width: 43em;
    display: block;
    margin: auto;
    margin-bottom: 1em;
}

@media (max-width: 1024px) {
    .title {
        padding-left: 1em;
        padding-right: 1em;
        display: flex;
        text-align: left;
        font-size: 24px;
        margin-top: 2em;
    }
    .num {
       flex: .15;
    }
    .text {
        flex: 1;
     }
}