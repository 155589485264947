.testimonial__container {
    margin: auto;
    border-radius: 34px;
    background: linear-gradient(50% 50% at 50% 50%, rgba(245, 152, 216, 0.40) 0%, rgba(217, 217, 217, 0.00) 100%);
    display: flex;
    /* background: #fff; */
    padding-bottom: 4.875em;
    margin-bottom: 8em;
    gap: 5.438em;
    max-width: 75em;
    /* border: solid; */
}
.left_pane {
    flex: 1;
    padding: 8.125em 0 0 0;
    /* border: solid; */
}
.right_pane {
    flex: 1;
    /* border: solid; */
    padding: 2.938em 0 0 0;
}

.right__pic {
    max-width: 100%; /* 35.063em */
    height: 45.5em;
    border-radius: 0px 45px 0px 0px;
    display: block;
    margin: auto;
    background: url("/public/images/Onome Aluko.jpg");
    background-size: cover;
    padding-top: 25em;
    
}
.left__pic {
    width: 100%; /* 32.063em */
    height: 45.5em;
    background: url("/public/images/Doris Omoyeni.jpg");
    background-size: cover;
    padding-top: 29em;
    
}



.pink {
    color: #EB2590;
    font-family: 'Playfair Display', serif;
    font-size: 63px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.tagline {
    color: #949494;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

.title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: .5em;
}

.next_line {
    display: block;
}

.right_pane > p {
    color: #000;
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: auto;
    display: block;
    /* border: solid; */
    max-width: 100%;
    margin-top: 2.688em;
    
}

.right_pane > .link {
    display: block;
    margin-top:2em;
}
.right_pane > .link > a {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.testimony__container {
    width: 26.563em;
    height: auto;
    border-radius: 32px 0px;
    background-color: #FFF;
    padding: 0 1.375em 1em;
    display: block;
    margin: auto;
    /* border: solid; */
}

.heading {
    display: flex;
    /* border: solid; */
    padding: 0;
}


.testifier {
    flex: 1;
    background: linear-gradient(#EB2590 27.79%, #F73 76.01%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Playfair Display', serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: block;
    margin-top: .3em;
    margin-bottom: 0;
    /* border: solid; */
}

.right__pic > .testimony__container > .heading > .testifier {
    text-align: right;
    
}

.testifier__position {
    flex: 1;
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin-bottom: 0;
    display: block;
    margin-top: 1.3em;
    text-align: right;
    /* border: solid; */
}

.right__pic > .testimony__container > .heading > .testifier__position {
    text-align: left;
    margin-top: 1.5em;
    /* border: solid; */
}


.testimony {
    clear: both;
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: justify;
    /* border: solid; */
    display: block;
    margin: auto;
    margin-top: 0.938em;
    padding: 0 1em;
}

@media (max-width: 1024px) {
    .testimonial__container {
        display: block;
        /* border: solid; */
        padding-top: 4em;
        margin-bottom: 5em;
    }

    .left_pane {
        display: block;
        padding: 0;
        /* border: solid; */
    }
    
    .right_pane {
        display: block;
        padding: 0;
        padding-left: 1em;
    }
    
    .right__pic {
        width: 18em;
        height: 30.5em;
        margin-left: auto;
        margin-right: 0;
        display: block;
        /* border: solid; */
        border-radius: 0;
        /* box-shadow: 10px 10px 10px #aaaaaa; */
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        padding-top: 15.5em;
    }

    .left__pic {
        width: 18em;
        height: 30.5em;
        /* margin: auto; */
        display: block;
        margin-bottom: 0;
        /* box-shadow: 10px 10px 10px #aaaaaa; */
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        padding-top: 18em;
        /* padding-bottom: auto; */
        margin-bottom: 2em;
        background-repeat: no-repeat;
    }
    
    .testimony__container {
        width: 14.57em;
        height: auto;
        margin: auto;
        /* margin-bottom: 3em; */
        padding-top: .5em;
        padding-left: 1.5em;
        padding-bottom: .5em;
        display: block;
        /* border: solid; */
        /* box-shadow: 10px 10px 10px #aaaaaa; */
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border-radius: 32px 0px;
    }
    
    .testifier {
        display: block;
        float: left;
        margin-right: 0;
        margin-bottom: 0;
        font-size: 12px;
    }
  
    .testifier__position {
        display: block;
        float: right;
        margin: 2.4em 1em 0 0;
        font-size: 7px;
    }

    .left__pic > .testimony__container > .heading > .testifier__position, .right__pic > .testimony__container > .heading > .testifier__position {
        margin-top: 1em;
    }

    
    .testimony {
        word-break: keep-all;
        /* border: solid; */
        /* width: 14em; */
        display: block;
        margin: auto;
        clear: both;
        padding: .7em .7em 0 0;
        font-size: 12px;
    }
    
    .right_pane > p {
        /* border: solid; */
        width: 100%;
        margin: auto;
        margin-top: 2.688em;
        word-break: keep-all;
        text-align: left;
        padding-right: 1em;
    
    }

    
    .other {
       display: block;
        margin: auto;
        padding-left: 0.3em;
        padding: 0 0.6em;
        margin-top: .2em;
        margin-bottom: .3em;
        word-break: keep-all;
    }
    
    .voices {
        display: block;
        margin: auto;
        padding-left: 0.3em;
        margin-top: 1em;
        padding: 0 0.6em;
        word-break: keep-all;
     }

     .pink {
        font-size: 35px;
        font-weight: 400;
    }
    
    .next_line {
        font-size: 28px;
        font-weight: 700;
        font-family: 'Playfair Display', serif;
        font-style: italic;
    }
    
    .title {
        text-align: center;
     }
    
     .right_pane > .link {
        margin-top: 1em;
    }

    .right_pane > .link::after {
       content: url("/public/images/Arrow\ -\ Right\ 2.png");
       vertical-align: middle;
       margin-left: 1em;
    }
}