.container {
    margin: 8.438em auto;
    border-radius: 37px;
    background: #1C1C1C;
    background-repeat: no-repeat;
    max-width: 75em;
    display: flex;
    padding: 0;
}

.text_container {
    flex: 1;
    /* border: solid; */
    padding: 0 1.5em;
    display: grid;
    align-items: center;
}

.img_container {
    flex: 1;
}
.img {
    display: block;
    /* margin-bottom: -.3em; */
    border-radius: 0 37px 0;
    /* border: solid; */
}


.statement {
    color: #F598D8;
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: keep-all;
    text-align: left;
}

.name {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column-reverse;
        padding-top: 3em;
        margin: 8.438em 1em;
    }

    .img {
        width: 14em;
        display: block;
        margin: auto;
    }

    .statement {
        font-size: 26px;
    }

    .name {
        font-size: 17px;
    }
}