.switch-lang {
    
    text-align: left;
    cursor: pointer;
    z-index: 50;
    /* position: absolute ; */
    top: 20px;
    right: 20px;
}
.current-lang::after {
    content: url("/public/images/Arrow\ -\ Down\ 3.png");
    float: right;
}
  
.switch-lang:hover .lang-dropdown {
    display: block;
    position: absolute;
    z-index: 1111;
    background-color: #fff;
    height: 12em;
}
      
.switcher-link {
    color: #fff;
}

.switcher-link:hover {
    color: #fff;
}
      
.current-lang {
    /* background: #34495E; */
    padding: 3px 5px 0px 5px;
    border-radius: 5px;
}
  
.lang-flag {
    width: 20px;
    display: inline-block;
}
  
.lang-text {
    display: inline-block;
    margin-left: 5px;
    vertical-align: top;
    margin-top: 2px;
    color: #5B5B5B;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
  
.lang-dropdown {
    /* display: none; */
    background: #fff;
    border-radius: 5px;
    margin-top: 2px;
    width: 130px;
    /* border: solid; */
    z-index: 1999999;
    height: 0;
    position: absolute;
    overflow: hidden;
    transition: height .5s linear;
}
  
.selecting-lang {
    padding: 10px 5px .5px;
  cursor: pointer;
}

.selecting-lang:hover {
    background-color: #ebebeb;
    color: #fff;
}
  
/* .selecting-lang:hover {
    background: #22313F;
} */