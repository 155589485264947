.container {
    background: #FFF8FD;
}

.sub_container {
    display: flex;
    /* padding: 6.604em 4em; */
    /* border: solid; */
    width: calc(74em + ((100vw - 75em) / 2 ));
    margin: auto;
    margin-right: 0;
}

.text_container, .img_container {
    flex: 1;
}

.text_container {
    padding: 12.938em .375em 7.875em;
}

.img_container {
    background: url("/public/images/about_shecluded.png");
    background-size: cover;
}

.text_body {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.so_far {
    color: #141414;
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.next_line {
    display: block;
}

.stat {
    color: #EB2590;
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}



@media (max-width: 1024px) {
    .container {
        display: block;
        padding: 6.604em 1em;
    }

    .sub_container {
        flex-direction: column;
        width: auto;
        margin: auto;
    }

    .text_container {
        padding: 2.938em 0 2.875em;
        /* border: solid; */
    }

    .img_container {
        padding: 12.938em 1em;
    }

    .so_far, .stat, .text_body {
        font-size: 20px;
        text-align: center;
    }

    .next_line {
        display: inline;
    }

}