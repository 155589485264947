.banner__tagline {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* display: inline-block; */
    /* position: relative; */
    margin-left: -2.15em;
    /* border: solid; */
    /* width: 9.188em; */
    margin-top: 1.09em;
    flex: 1.2;
}

.banner__small {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: block;
    width: 30.5em;
    margin-top: 1.375em;
}
.img__container {
    flex: 1;
    /* border-radius: 54px; */
    background: radial-gradient(50% 50% at 50% 50%, rgba(245, 152, 216, 0.40) 0%, rgba(217, 217, 217, 0.00) 100%);
    /* background: radial-gradient(50% 50% at 50% 50%, rgba(245, 152, 216, 0.40) 0%, rgba(217, 217, 217, 0.00) 100%); */
    /* background-size: 200% 200%; */
    /* display: inline-block; */
    /* border: solid; */
    /* margin-left: 3.375em; */
    /* float: right; */
    /* margin-right: 3em; */
    /* margin: auto; */
    /* margin-top: 11.375em; */
    /* background: radial-gradient(50% 50% at 50% 50%, rgba(245, 152, 216, 0.40) 0%, rgba(217, 217, 217, 0.00) 100%); */
    /* background: linear-gradient(90deg, rgba(245, 152, 216, 0.40), rgba(217, 217, 217, 0.00)); */
    /* background-position: left;
    background-size: 10em; */
    /* background: url("/public/images/banner_img.png");
    background-color: linear-gradient(to bottom, rgba(245, 152, 216, 0.40), rgba(217, 217, 217, 0.00));
    height: 35em;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
}
    
.banner__actions > a > span {
    color: #000;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* vertical-align: middle; */
    margin-left: 1.5em;
}
.banner__actions > a > span::after {
    content: url("/public/images/Arrow\ -\ Left.png");
    vertical-align: -.2em;
    margin-left: 0.125em;
}
.banner {
    background: radial-gradient(50% 50% at 50% 50%, rgba(245, 152, 216, 0.40) 0%, rgba(217, 217, 217, 0.00) 100%);
    padding-top: 6.45em;
    padding-right: 3.813em;
    padding-bottom: 12.125em;
    /* margin-top: -4.5em; */
    display: flex;
    /* border: solid; */
    max-width: 80.813em;
    margin: auto;
}



@media (max-width: 1024px) {

    .banner__actions > a > span {
        margin-left: .5em;
        font-size: 15px;
    }

    .banner__tagline {
        display: block;
        /* position: relative; */
        /* border: solid; */
        flex: none;
        word-break: keep-all;
        margin: -9.1em .5em .1em;
        font-size: 34px;
        /* padding: .2em; */
    }
    
    .banner__small {
        width: 100%;
        font-size: 16px;
        margin: auto;
        margin-top: 2em;
        margin-bottom: 2em;
        /* border: solid; */
    }
    .img__container {
        flex: none;
        display: block;
        /* border: solid; */
        /* margin-left: 3.375em; */
        /* float: right; */
        /* margin-right: 3em; */
        margin: auto;
        /* margin-top: 1.001em; */
        width: 17em;
    }
  
    .banner {
        background: none;
        padding: 0;
        padding-top: 10em;
        /* padding-right: 0;
        padding-bottom: 0;
    
        padding: 10em 1em 0 1em; */
        /* margin-top: -4.5em; */
        display: block;
        /* border: solid; */
        clear: both;

        max-width: 20em;
    }
}

