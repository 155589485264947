.container {
    display: block;
    border-radius: 28px;
    margin: 2em auto;
    padding: 6.604em 4em;
    max-width: 75em;
    background-image:  linear-gradient(to bottom, rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url("/public/images/hub_banner2.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 38.438em;
}

.tag {
    color: #C4C4C4;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.title {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 67px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 76.16px */
    text-transform: capitalize;
    word-break: keep-all;
    margin-bottom: 1.7em;
}


.link {
    /* border: solid; */
    height: 3em;
    display: block;
    float: left;
    overflow: auto;
    margin-top: 1em;
    /* margin-left: 3em; */
}


.link::after {
    clear: both;
    content: url("/public/images/arrw_right_white.png");
    /* border: solid; */
    vertical-align: -.25em;
    margin-left: 1em;
}

.ecosystem {
    font-weight: 400;
}

.next_line {
    display: block;
}

@media (max-width: 1024px) {
    .container {
        background: #000;
        margin: 2em 1em;
        padding: 6.604em 0 0;
        background-image:  linear-gradient(to bottom, rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url("/public/images/sheluded_hub_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 32.563em;
        max-height: 32.563em;
    }

    .tag {
        text-align: center;
    }

    .title {
        font-size: 40px;
        text-align: center;
    }

    .link {
        display: block;
        text-align: left;
        /* display: block; */
        float: none;
        margin: auto;
        margin-top: 1.3em;
    }
}