.container {
    margin: 10.5em auto;
    padding: 0;
    max-width: 75em;
    display: block;
}

.sub_container {
    border-radius: 23px;
    background: var(--Primary, #EB2590);
    display: flex;
    padding: 4.563em 3.75em;
}

.title {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 45.22px */
    text-transform: capitalize;
    flex: 1;
}

.btn_container {
    flex: 1;
}

.next_line {
    display: block;
}

@media (max-width: 1024px) {
    .container{
        margin-top: 5em;
       padding: 0 1em;
    }

    .title {
        text-align: center;
        font-size: 27px
    }

    .sub_container {
        /* display: block; */
        flex-direction: column;
        padding: 4.563em 1em;
    }

    .next_line {
        display: inline;
    }

    .btn_container {
        margin: auto;
    }

}