.container {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.005), rgba(0, 0, 0, 0.005)), url("/public/images/World\ Map\ 1.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 13.375em 0;
    margin-bottom: 8em;
}

.top_title {
    color: var(--Footer-Background, #141414);
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 20em;
    display: block;
    margin: auto;
}

.top_note {
    color: var(--Footer-Background, #141414);
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 2em;
}

.pink {
    color: #EB2590;
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.hub__container {
    display: flex;
    background: linear-gradient(50% 50% at 50% 50%, rgba(245, 152, 216, 0.40) 0%, rgba(217, 217, 217, 0.00) 100%);
    /* border: solid; */
    max-width: calc(74em + ((100vw - 75em) / 2));
    margin-left: auto;
    overflow: hidden;
    margin-bottom: -12em;
}
.left_pane {
    flex: 1;
    padding: 12em 0 0 0;
    /* border: solid; */
}
.right_pane {
    flex: 0;
    padding: 12em 0 0 0;
    /* border: solid; */
}
.left_pane > .hub__tag {
    width: 20.063em;
    height: 2.313em;
    border-radius: 13px;
    background: #FFE8F8;
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0.75em 1.25em 2em;
    /* margin-left: 4.625em; */
    word-break: keep-all;
    /* border: solid; */
}
.title {
    /* border: solid; */
    width: 10em;
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 65px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.left_pane > .title > span {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* margin-left: 1.625em; */
}

.left_pane >  .title > span > span {
    color: #767676;
    font-family: 'Lato', sans-serif;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.hub__small {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    /* margin-left: 3.625em; */
    margin-top: 0.813em;
    width: 70%;
    word-break: keep-all;
}

.hub__pic {
    width: 38.75em;
    height: 46.75em;
    border-radius: 40px 0px 0px 0px;
    margin: auto;
}

.hub__small > a:hover {
    text-decoration: none;
}

@media (max-width: 1024px) {
    .hub__container {
        display: block;
        padding-top: 0;
        background: none;
    }
    
    .left_pane {
        padding-left: 1em;
        padding-right: 1em;
        display: block;
        /* border: solid; */
        padding-top: 8em;
        background: radial-gradient(50% 50% at 20% 50%, rgba(245, 152, 216, 0.40) 0%, rgba(217, 217, 217, 0.00) 100%);

    }

    .right_pane {
        display: block;
        /* border: solid; */
        padding: 5em 0 0 0;
    }

    .left_pane > .title > span {
        margin: auto;
        /* border: solid; */
    }

    .hub__small {
        margin-left: 0;
        width: 18em;
        /* border: solid; */
        margin: auto;
        margin-top: 1.813em;
        font-size: 16px;
    }

    .left_pane > .hub__tag {
        margin-left: 0;
        width: 11.063em;
        border-radius: 13px;
        background: #FFE8F8;
        margin: auto;
        margin-bottom: 2em;
        
    }

    .hub__pic {
        width: 100%;
        height: auto;
        display: block;
        margin: auto;
        border-radius: 0;
        /* margin-right: 0; */
        /* border: solid; */
    }
    .title {
        /* border: solid; */
        width: 6.5em;
        margin: auto;
        text-align: center;
        font-size: 42px;
    }
    .top_title {
        width: 14em;
        font-size: 22px;
        /* border: solid; */
    }

    .top_note {
        font-size: 15px;
    }
    
    .pink {
        font-size: 22px;
    }
}