.container {
    display: flex;
    margin: 0;
    padding: 4em 0;
    gap: 2em;
}

.partition1, .partition2, .partition3, .partition4 {
    flex: 1;
    border-radius: 0px 27px 27px 0px;
    padding: 8.063em 0 8.938em;
    background-repeat: no-repeat;
    background-size: cover;
}

.partition1 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url("/public/images/partition1.png");
}
.partition2 {
    border-radius: 27px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.51)), url("/public/images/partition2.png");
}
.partition3 {
    border-radius: 27px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.60)), url("/public/images/partition3.png");
}
.partition4 {
    border-radius: 27px 0px 0px 27px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.70)), url("/public/images/partition4.png");
}

.number {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 51px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    display: block;
    margin: auto;
}

.text {
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 7em;
    display: block;
    margin: auto;
}

.footnote {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    margin-bottom: 4em;
}

.bold {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pink {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


@media (max-width: 1024px) {
    .container {
        display: flex;
        padding-top: 0;
        scroll-behavior: smooth;
        transition: margin-left .2s linear;
        /* width: 55em; */
        overflow: hidden;
        /* border: solid; */
    }

    .partition1, .partition2, .partition3, .partition4 {
        /* width: 20em; */
        height: 22.41em;
        margin: auto;
        display: block;
        border-radius: 27px;
        margin-top: 0;
        /* overflow: hidden; */
        padding: 8.063em 1em 8.938em;
        background-repeat: no-repeat;
    }

    .footnote {
        width: 12em;
        margin: auto;
        margin-bottom: 2em;
        /* border: solid; */
    }

    .footnote, .bold, .pink {
        font-size: 25px;
        text-align: left;
    }

    .number {
        font-size: 45px;
    }

    .text {
        font-size: 24px;
    }

}