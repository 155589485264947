.container {
    padding: 4.213em 17.868em;
    background: #FFFAFD;
}


.title {
    color: #450028;
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%; /* 37.62px */
    text-align: center;
}

.note {
    color: var(--text-2, #283655);
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%; /* 17.1px */
    text-align: center;
}

.sub_container {
    display: block;
    margin: auto;
    max-width: 75em;
}

.form {
    display: block;
    max-width: 50.4em;
    margin: auto;
    /* border: solid; */
    /* max-width: 75em; */
}


.row {
    display: flex;
    margin: auto;
    /* border: solid; */
  
    /* overflow: auto; */
}

.form_group, .form_group_single {
    flex: 1;
    /* display: inline-block; */
    /* float: left; */
    /* border: solid; */
    /* margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 1em;
    margin-top: .5em; */
    /* width: 22.813em; */
    /* max-width: 100%; */
}



.label {
    color: #283655;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%; /* 13.68px */
    display: block;
    padding-left: .5em;
    margin-top: 0.906em;
    margin-bottom: .3em;
    /* border: solid; */
}

.form_group > input[type = 'text'], .form_group > input[type = 'email'], .file, .select {
    border-radius: 10px;
    background: #F4F4F7;
    color: var(--text-2, #283655);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%; /* 13.68px */
    padding: 1.438em 1.371em;
    margin-bottom: .3em;
    border: none;
    width: 100%;
}



.file, .position {
    /* width: 95%; */
    border-radius: 10px;
    border: 1px dashed #C9C9C9;
    background: transparent;
}

input[type = 'file'] {
    display: none;
}

.form_group > label:hover {
    cursor: pointer;
}

.submit {
    clear: both;
    display: block;
    border-radius: 5.626px;
    background: var(--SHE-COLOR-1, #EB2590);
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 17.514px;
    font-style: normal;
    font-weight: 400;
    line-height: 153.2%; /* 26.832px */
    text-transform: uppercase;
    height: 3.516em;
    width: 33em;
    border: none;
    margin: auto;
    margin-top: 5em;
    
}


@media (max-width: 1024px) {
    .container {
        padding: 4.213em 1em;
    }
    
    .row {
        flex-direction: column;
    }

 
    
    .form_group, .form_group_single {
        width: 100%;
        /* border: solid; */
        margin: auto;
        display: block;
    }
    
    
    .form_group > input[type = 'text'], .form_group > input[type = 'email'], .file, .select {
        width: 100%;
    }

    .file {
        width: 100%;
    }

    .submit {
        clear: both;
        width: 90%;
        height: 3em;
        margin-top: 2em;
        font-size: 17px;
    }

    .title {
        font-size: 33px;
    }

    .note {
        font-size: 15px;
    }
}