.container {
    display: block;
    /* border: solid; */
    padding: 6.875em 3.813em 5.063em;
    
}

.sub__container {
    border-radius: 28px;
    background: #FFF6FB;
    display: flex;
    max-width: 75em;
    margin: auto;
}

.partition_1, .partition_2 {
    flex: 1;
}

.partition_1 {
    flex: 2;
    padding: 6em 2.875em;
}



/* .img {
    float: left;
} */

.img {
    margin-top: -10em;
    /* width: 31.5em; */
}




.tagline {
    color: #000;
    font-family: 'Playfair Display', serif;
    font-size: 65px;
    font-style: normal;
    font-weight: 600;
    line-height: 119%; /* 92.82px */
    text-transform: capitalize;
    /* width: 7em; */
    /* border: solid; */
    margin-bottom: .7em;
    word-break: keep-all;
}

.dynamic__one {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 65px;
    font-style: normal;
    font-weight: 600;
    line-height: 119%;
    text-transform: capitalize;
}

.dynamic__two {
    color: var(--P2, #F598D8);
    font-family: 'Playfair Display', serif;
    font-size: 65px;
    font-style: italic;
    font-weight: 600;
    line-height: 119%;
    text-transform: capitalize;
    /* display: block; */
}

.small {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 20em;
    word-break: keep-all;
}

@media (max-width: 1024px) {
    .container {
        padding: 2em 1em;
        /* border: solid; */
    }
    
    .sub__container {
        /* border: solid; */
        display: block;
    }
    .partition_1, .partition_2 {
        padding: 3em 0 0;
        padding-left: 1em;
        padding-right: .9em;
        /* width: 18em; */
        /* border: solid; */
        display: block;
        margin: auto;
    }

    .partition_2 {
        padding-bottom: 2em;
    }
    .img {
        float: none;
        width: 15em;
        display: block;
        margin: auto;
        /* border: solid; */
        /* margin-top: -5em; */
    }
    .tagline, .dynamic__one, .dynamic__two {
        
        /* border: solid; */
        font-size: 40px;
    }
    .small {
        width: auto;
        font-size: 18px;
    }
}

