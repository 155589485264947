.container {
    padding: 10.702em 0 3.753em;
    overflow: hidden;
    max-width: 75em;
    display: block;
    margin: auto;
}

.title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 53px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 63.07px */
    text-transform: capitalize;
}

.next_line {
    display: block;
}

.impacts {
    display: block;
    width: 3000vw;
    /* border: solid; */
    overflow: hidden;
}

.img {
    display: block;
    height: 5em;
    /* border: solid; */
    /* width: 7.5em; */
}

.impact_1 > .img {
    margin-bottom: 1em;
}

.impact_2 > .img {
    margin-bottom: 1em;
}

.impact_1, .impact_2 {
    display: inline-block;
    width: 39.188em;
    max-height: 34.188em;
    min-height: 34.188em;
    /* float: left; */
    margin-right: 4em;
    padding: 1.8em 5.313em 1.8em 3.25em;
    vertical-align: middle;
}

.impact_1 {
    border-radius: 28px;
    background: #EBEBEB;
    box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.25);
}

.impact_2 {
    border-radius: 28px;
    background: #FFE1F6;
    box-shadow: 0px 4px 60px 0px rgba(225, 225, 225, 0.25);
}

.link > a {
    color: var(--Footer-Background, #141414);
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
}

.link {
    margin-top: 2em;
}

.link::after {
    content: url("/public/images/Arrow\ -\ Left.png");
    vertical-align: -.21em;
    margin-left: .8em;
}

.navigators {
    display: block;
    text-align: center;
    margin-top: 4.876em;
}

.nav {
    margin-left: 1.325em;
}

.nav:hover {
    cursor: pointer;
}

.impact_title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 41px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    height: 4em;
}

.impact_1 > .impact_title {
    padding-top: .5em;
}

.impact_2 > .impact_title {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 41px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.impact_body {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 119%; /* 26.18px */
    text-transform: capitalize;
    /* max-width: 29.813em; */
    height: 7em;
}

@media (max-width: 1024px) {
    .container {
        padding: 10.702em .7em 3.753em;
    }

    .impact_1, .impact_2 {
        width: 16.447em;
        max-height: 26em;
        min-height: 26em;
        margin-right: 1em;
        padding: 1.938em 1em;
    }

    .title {
        font-size: 28px;
        margin-bottom: 1em;
    }

    
    .impact_title, .impact_2 > .impact_title {
        font-size: 25px;
    }
    
    .impact_body {
        font-size: 13px;
    }

    .impact_1 > .impact_title {
        padding-top: 1.5em;
    }

    .impact_title {
        height: 5em;
        /* border: solid; */
    }

    .impact_2 >.img {
        display: block;
        /* width: 5.6em; */
        height: 3em;
        /* border: solid; */
    }

    .impact_1 >.img {
        display: block;
        /* width: 5em; */
        height: 3em;
        /* border: solid; */
    }

    .link > a {
        color: #000000;
        font-family: 'Lato', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .link::after {
        content: ">" ;
        color: #000000;
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        /* url("/public/images/Arrow\ -\ Right\ 2.png"); */
        vertical-align: -.1em;
    }
   
}

