.container {
    padding: 3.841em 0 5em;
    background: #FFFBF2;
}

.sub_container {
    display: flex;
    gap: 2em;
    margin: auto;
    /* border: solid; */
    flex-wrap: wrap;
    max-width: 75em;
}

.tagline {
    color: #949494;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.title {
    color: #000;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 49px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 58.31px */
    text-transform: capitalize;
    max-width: 15em;
    margin: auto;
    margin-top: 1em;
}

.navigators {
    display: none;
}





@media (max-width: 1024px) {
    .container {
        padding: 1em;
        overflow: hidden;
    }
    
    .sub_container {
       flex-wrap: nowrap;
       scroll-behavior: smooth;
       transition: margin-left .5s linear;
       margin-bottom: 4em;
    }

    .tagline {
        font-size: 15px;
    }

    .title {
        font-size: 26px;
    }

    .navigators {
        display: flex;
        /* border: solid; */
        margin: 2em auto;
        width: 13em;
        justify-content: center;
    }
    
    .nav {
        /* display: inline-block; */
        vertical-align: middle;
    }
    
    .nav:hover {
        cursor: pointer;
    }
    
    .more {
        margin: auto 2em;
        display: inline-block;
        color: var(--Footer-Dark, #747474);
        font-family: 'Lato', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}