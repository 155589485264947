.body, .li {
    color: #747474;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 1em 4em;
    word-break: keep-all;
    margin: auto;
    max-width: 62em;
    /* display: block; */
}

.li {
    padding: 0;
}

.ul {
    margin: .5em 5em;
    display: block;
    /* border: solid; */
    margin: auto;
    max-width: 69em;
}

.ul_2 {
    margin-top: .5em;
    list-style-type: disc;
}

.ol {
    margin-top: .5em;
}

@media (max-width: 1024px) {
    .ol {
        margin: .5em .2em;
    }
    
    .li {
        padding: .5em .5em;
        /* list-style-type: disc; */
    }
    
    .body {
        padding: .2em .5em .2em .5em;
    }
    
    .ul {
        margin: .5em auto;
        display: block;
        /* border: solid; */
        list-style-type: disc;
    }
    
    .ul_2 {
        margin-top: .5em;
        list-style-type: disc;
    }

    .li, .body {
        font-size: 15px;
    }
}