.container {
    padding: 4.313em 0;
    background: #FFF6FB;
}

.sub_container {
    display: flex;
    margin: auto;
    max-width: 75em;
    flex-wrap: wrap;
    /* justify-content: center; */
    /* align-items: center; */
    /* border: solid; */
}

.title {
    color: var(--Footer-Background, #141414);
    font-family: 'Lato', sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 119%; /* 54.74px */
    text-transform: capitalize;
    display: block;
    text-align: center;
    margin-bottom: 2em;
}

.navigators {
    display: none;
}

@media (max-width: 1024px) { 
    .container {
        padding: 4.313em 1em;
        /* border: solid; */
    }

    .sub_container {
        flex-wrap: nowrap;
        justify-content: left;
        overflow: hidden;
        gap: 1em;
        margin: 0;    
        max-width: 250em;
        /* width: 250em; */
        /* border: solid; */
    }

    .title {
        font-size: 33px;
    }

    .navigators {
        display: block;
        text-align: center;
        margin-top: 1.876em;
    }
    
    .nav {
        margin-left: 1.325em;
    }
    
    .nav:hover {
        cursor: pointer;
    }
}