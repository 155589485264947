// @import './styles.scss';

$side-padding-xs: 30px;
$side-padding-sm: 60px;
$side-padding-md: 40px;
$side-padding: 100px;
$side-padding-lg: 160px;

/******************************************************************************
* LG devices
*****************************************************************************/
@mixin flex-direction {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flexDirection($direction) {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: $direction;
}

/*******************************************************************************
*
* Variables
*
*****************************************************************************/
$app-name: shecluded;

$heading-font-family: 'Playfair Display', serif;
$body-font-family: 'Lato', sans-serif;
$base-font-size: 16px;
$base-font-weight: 400;

$theme-color: #f0008c;
$theme-secondary-color: #ffddf1;
$theme-color-alt: #c40074;
$theme-color-light-pink: #f1dce9;
$theme-color-black: #000;
$theme-color-white: #fff;
$theme-color-light-grey: #e7e7e7;
$theme-color-lighter-grey: #a6a6a6;
$theme-color-dark-grey: #777;
$theme-color-almost-grey: rgb(240, 240, 240);
$theme-color-almost-white: #fafafa;

$theme-text-color: $theme-color;
$theme-text-color-black: #000;
$theme-text-color-grey: #696768;
$theme-text-color-grey: $theme-color-dark-grey;
$theme-text-color-white: #fff;
$theme-text-color-disabled: #bdbdbd;

$theme-shadow-regular: 0px 5px 30px rgba(236, 0, 140, 0.15);
$theme-shadow-sm: 0px 5px 15px rgba(236, 0, 140, 0.15);

$theme-bg-white: #fff;
$theme-bg-grey: #fafafa;
$theme-bg-grey-mid: #dadada;
$theme-bg: #ec008c;

@media (max-width: 1199px) {
  /**
	* Home
  */
  .small-header {
    img {
      height: initial;
    }
  }

  .homepage-header {
    .header-text {
      margin-top: 3rem;
    }
  }

  .header-bottom {
    width: 90%;
    top: -60px;
  }

  .image-resize {
    width: 450px;
  }

  .timeline {
    .image-point-row:nth-child(3) .content,
    .image-point-row:nth-child(7) .content {
      left: 2%;
    }

    .image-point-row:nth-child(5) .content {
      left: 54%;
    }
  }

  .section-stories {
    .story-head {
      .story-holder {
        top: 30px;
        right: 0px;

        .story-card {
          position: relative;
        }
      }
    }

    .roller-row {
      overflow-x: scroll;
    }
  }
  /**
	* About
  */
  .about-section {
    max-width: 70%;
  }

  .justify-response {
    justify-content: space-between;
  }

  .team-member {
    margin-bottom: 20px;
  }
}

/******************************************************************************
* MD devices
*****************************************************************************/
$h1-font-size-md: 68px;
$h2-font-size-md: 42px;
$h3-font-size-md: 36px;
$h4-font-size-md: 24px;
$h5-font-size-md: 22px;
$h6-font-size-md: 18px;

$md-body-font-size: 14px;
$md-body-font-size-md: 16px;
$md-body-font-size-lg: 18px;
@media (max-width: 991px) {
  /**@media
	* General
  */
  .nav-item {
    &-no-border {
      border: 0px;
    }
    border: 1px solid #ec008c;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 284px;
    height: 51px;
    border-radius: 4px;
    margin: 15px 0px;
    a {
      padding-left: 10px;
      width: inherit;
      height: inherit;
      padding-top: 15px;
    }
    &-no-padding-top {
      a {
        padding-top: 0px;
      }
    }
  }

  .dashboard-side-kkk {
    display: none;
  }
  .etee {
    display: none;
  }
  .jshss {
    margin: 5px;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
  }
  .keeee {
    justify-content: center;
  }
  .ssdssss {
    flex-flow: wrap;
  }
  .center-main {
    display: none;
  }
  .pagination {
    // border: solid red 2px;
    li {
      width: 48px;
    }
  }
  .container-of-left-side {
    display: none;
  }
  .blog-details-wrapper {
    .heading {
      font-size: 32px;
    }
  }
  // .sksdjs{
  //   display: none;
  // }
  .kioss {
    left: 10px;
  }
  .kioss2 {
    left: 10px;
  }

  .ifeoma-info {
    margin: auto auto !important;
  }

  .ifeoma-text {
    width: 100%;
  }
  a.#{$app-name}-active {
    border-bottom: none;
    font-family: $heading-font-family;
  }
  .resourceCard {
    flex-flow: wrap;
    img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }

  .bottom-space {
    padding-bottom: 40px;
  }

  .bottom-space-sm {
    padding-bottom: 30px;
  }

  .section {
    padding: 50px;
    padding-left: $side-padding-sm;
    padding-right: $side-padding-sm;

    &.large-vertical-padding {
      padding-top: $side-padding-sm;
      padding-bottom: $side-padding-sm;
    }

    &.small-vertical-padding {
      padding-top: $side-padding-sm;
      padding-bottom: $side-padding-sm;
    }
  }

  .small-header {
    min-height: 250px;

    .small-header-overlay {
      height: 250px;
      padding-top: 90px;

      h1 {
        font-size: 36px;
      }

      h2 {
        font-size: 28px;
      }

      p.font-lg {
        font-size: 14px;
      }
    }

    img {
      position: absolute;
      width: 100%;
      left: 0;
    }
  }

  /**
	* Nav
	*/
  #mainNav {
    padding-left: $side-padding-sm;
    padding-right: $side-padding-sm;
  }

  /**
	* Footer
	*/
  footer {
    &.page-footer {
      .header {
        font-size: 50px !important;
      }

      .small-padding {
        padding: 0;
      }

      .justify-content-around {
        justify-content: flex-start !important;
      }
    }
  }

  /**
	* Home
  */
  .header-bottom {
    width: 100%;
    top: -50px;

    #text-demibold {
      font-size: 23px;
    }
  }

  .chunk-underlay {
    font-size: 2.5em;
  }

  .hero-header {
    padding: $side-padding-xs;
    padding-top: 150px;
  }

  .img-response {
    width: 330px;
  }

  .timeline {
    .content {
      position: absolute;
      width: 300px;
    }

    .image-point-row:nth-child(3) .content,
    .image-point-row:nth-child(7) .content {
      left: 32%;
    }

    .image-point-row:nth-child(5) .content {
      left: 32%;
    }
  }

  .image-resize {
    width: 450px;
  }

  .center {
    text-align: center !important;
  }

  .med-padding {
    padding: 3rem;
  }

  .section-stories {
    .story-head {
      .current-img-holder {
        height: 150px;

        .current-story-img {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .overlay {
          padding-left: 30px;
          padding-top: 20px;
        }
      }

      .story-holder {
        position: static;
        top: 0;
        right: 0;
        padding-bottom: 10px;

        .story-card {
          position: relative;
        }
      }
    }

    .roller-row {
      overflow-x: scroll;
    }
  }

  /**
	* About
  */
  .team-member {
    margin-bottom: 20px;
  }

  .about-feature {
    height: 800px !important;
  }

  /**
	* Loans
	*/
  .plans-row {
    .card {
      margin-bottom: 30px;
    }
  }

  .sidebar-toggler {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 100px;
  }

  .dashboard-sidebar {
    .links-col {
      .dashboard-links {
        .list-group-item {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 15px;
        }
      }
    }
  }
  .wealth-price-content-wrapper {
    flex-flow: wrap-reverse;
    justify-content: center;
  }

  .shecluded-loan-item-card2 {
    height: 820px;
  }
}
/******************************************************************************
* SM devices
*****************************************************************************/
$h1-font-size-sm: 68px;
$h2-font-size-sm: 42px;
$h3-font-size-sm: 36px;
$h4-font-size-sm: 22px;
$h5-font-size-sm: 22px;
$h6-font-size-sm: 18px;

$sm-body-font-size: 14px;
$sm-body-font-size-md: 16px;
$sm-body-font-size-lg: 18px;

@media (max-width: 767px) {
  /**
	* General
  */
  .sksdjs {
    border: solid red 5px;
    display: none !important;
  }
  .active-loan-percent,
  .active-loan-date {
    width: 100%;
  }
  .active-loan-amount {
    font-size: 16px;
  }
  .business-img,
  .goal-cost,
  .loan-state,
  .loan-info-container,
  .business-sector-filter,
  .shecluded-nav-list-items {
    display: none;
  }

  .shecluded-loan-item-card {
    height: 135px;
  }

  .shecluded-loan-item-card,
  .shecluded-loan-item-card2 {
    width: 95%;
  }
  .breastPmp-amount {
    width: 60%;
  }
  .breastPmp-img {
    width: 40%;
  }
  .shecluded-nav-menu,
  .box,
  .box2 {
    display: block;
  }

  .shecluded-nav-profile-name {
    display: none;
  }
  .business-search-form {
    height: 150px;
  }
  .business-form {
    flex: 1;
  }
  .no-headerXX {
    height: 100%;
    padding: 10px;
  }
  .blog-details-wrapper {
    .heading {
      font-size: 28px;
    }
  }
  .perks-wrapper {
    flex-flow: wrap;
  }
  .bottom-space {
    padding-bottom: 40px;
  }

  .bottom-space-sm {
    padding-bottom: 30px;
  }
  .wealth-sidebar-wrapper {
    display: none;
  }

  .section {
    padding: 50px;
    padding-left: $side-padding-xs;
    padding-right: $side-padding-xs;

    &.large-vertical-padding {
      padding-top: $side-padding-sm;
      padding-bottom: $side-padding-sm;
    }

    &.small-vertical-padding {
      padding-top: $side-padding-xs;
      padding-bottom: $side-padding-xs;
    }
  }

  .small-header {
    min-height: 250px;

    .small-header-overlay {
      height: 250px;
      padding-top: 90px;

      h1 {
        font-size: 34px;
      }

      h2 {
        font-size: 28px;
      }

      p.font-lg {
        font-size: 14px;
      }
    }

    img {
      position: absolute;
      width: 200%;
      height: 100%;
      left: -350px;
    }
  }
}
/**
	* Nav
	*/
#mainNav {
  padding-left: $side-padding-xs;
  padding-right: $side-padding-xs;
}

/**
	* Footer
	*/
footer {
  &.page-footer {
    .divider-row {
      border-left: 0 !important;
      &::before {
        border-left: 0 !important;
      }
    }
  }
}

/**
	* Home
  */
.main-header {
  padding: 0;
}

.header-bottom {
  display: none !important;
}

.hero-header {
  padding: $side-padding-xs;
  padding-top: 150px;
}

.section-padding {
  img {
    width: 315px;
  }
}

.focus-section {
  .feature-section {
    min-height: 650px;
  }
}

.timeline {
  .content {
    position: absolute;
    width: 300px;
  }

  .image-point-row:nth-child(3) .content,
  .image-point-row:nth-child(7) .content {
    left: 25%;
  }

  .image-point-row:nth-child(5) .content {
    left: 25%;
  }
}

.section-stories {
  .story-head {
    .current-img-holder {
      height: 150px;

      .current-story-img {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .overlay {
        padding-left: 30px;
        padding-top: 20px;
      }
    }

    .story-holder {
      position: static;
      top: 0;
      right: 0;
      padding-bottom: 10px;

      .story-card {
        position: relative;
      }
    }
  }

  .roller-row {
    overflow-x: scroll;
  }
}

/**
	* About
  */
.about-section {
  max-width: 100%;

  #list-item {
    width: 80%;
  }
}
.about-flex-flow {
  flex-flow: wrap;
}

.mission-vision-section {
  border-right: 2px solid;
  div {
    padding: $side-padding-xs;

    &:first-child {
      p {
        position: relative;
        right: 30px;
      }
    }

    p {
      width: 300px;
    }
  }
}

.team-member {
  margin-bottom: 20px;
}

/**
	* Register
  */
.react-modal-body {
  height: 93vh;
}

.register-modal-container {
  .auth-form,
  .register-top-modal {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

/**
	* Loans
	*/
.check-list {
  li {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}
.plans-row {
  .card {
    margin-bottom: 30px;
  }
}

.container-wealth {
  .wealth-hero-container {
    .wealth-content {
      width: 100%;
    }

    .wealth-hero-img-wrapper {
      .wealth-image-wrapper {
        display: none;
      }

      .wealth-hero-card {
        display: flex;
        div {
          text-align: left;
        }
        .wealth-card-text {
          width: 95%;
        }
      }
    }
  }
}
.use-wealth-cal {
  width: 100%;
}

@media (min-width: 767px) {
  footer {
    .footer-section-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
    }
    .mt-footer {
      margin-top: 0rem;
    }
    .big-padding {
      padding: 70px 130px !important;
    }
  }
}
@media (max-width: 378px) {
  .sub-footer {
    width: 150px;
  }
}
/******************************************************************************
* XS devices
*****************************************************************************/
$h1-font-size-xs: 68px;
$h2-font-size-xs: 34px;
$h3-font-size-xs: 24px;
$h4-font-size-xs: 16px;
$h5-font-size-xs: 22px;
$h6-font-size-xs: 18px;

$xs-body-font-size: 14px;
$xs-body-font-size-md: 16px;
$xs-body-font-size-lg: 18px;

@media (max-width: 576px) {
  /**
	* General Design
  */

  .business-card {
    width: 100%;
  }
  .boxwwee {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.05);
    margin: 6px 0px;
    padding: 10px 0 0 5px;
  }
  .loan-headinf-h1 {
    font-size: 2.3rem;
  }
  .getstartedemail {
    flex-flow: wrap;
  }
  .blog-details-wrapper {
    .heading {
      font-size: 22px;
    }
  }
  .blog {
    width: 98%;
  }
  .perks {
    width: 96%;
  }
  .result-eesdd {
    width: 100%;
  }
  .kioss {
    width: 97%;
  }
  .kioss2 {
    width: 97%;
  }
  .loan-img {
    width: 100%;
    height: 100%;
  }
  .font-md {
    font-size: $xs-body-font-size-md;
  }

  .font-lg {
    font-size: $xs-body-font-size-lg;
  }

  h1 {
    font-size: $h1-font-size-xs;
  }

  h2 {
    font-size: $h2-font-size-xs;
  }

  h3 {
    font-size: $h3-font-size-xs;
  }

  h4 {
    font-size: $h4-font-size-xs;
  }

  h5 {
    font-size: $h5-font-size-xs;
  }

  h6 {
    font-size: $h6-font-size-xs;
  }

  .bottom-space {
    padding-bottom: 60px;
  }

  .dashboard-header-content:nth-child(1),
  .dashboard-header-content:nth-child(2),
  .dashboard-header-content:nth-child(3) {
    border-right: unset;
    border-bottom: 1px solid #ec008c;
  }
  .dashboard-header-content {
    padding-top: 10px;
  }

  .bottom-space-sm {
    padding-bottom: 30px;
  }

  h2 br {
    display: none !important;
  }

  .section {
    padding: 50px;
    padding-left: $side-padding-xs;
    padding-right: $side-padding-xs;

    &.large-vertical-padding {
      padding-top: $side-padding-sm;
      padding-bottom: $side-padding-lg;
    }

    &.small-vertical-padding {
      padding-top: $side-padding-xs;
      padding-bottom: $side-padding-xs;
    }
  }

  .small-header {
    min-height: 450px;

    .small-header-overlay {
      height: 250px;
      padding-top: 90px;

      h1 {
        font-size: 34px;
      }

      h2 {
        font-size: 28px;
      }

      p.font-lg {
        font-size: 14px;
      }
    }

    img {
      position: absolute;
      width: 300%;
      left: -350px;
    }
  }

  /**
	* Nav
	*/
  #mainNav {
    padding-left: $side-padding-xs;
    padding-right: $side-padding-xs;
  }

  /**
	* Footer
	*/
  footer {
    &.page-footer {
      .divider-row {
        border-left: 0 !important;
        &::before {
          border-left: 0 !important;
        }
      }

      .header {
        font-size: 48px !important;
      }

      .small-justify {
        justify-content: start !important;
      }
    }
  }
  /**
	* Home
  */
  .focus-section {
    .feature-section {
      min-height: 680px;
      padding-top: 20px;
    }
  }

  .join-section {
    .btn-margin {
      margin-top: 0px;
    }
  }

  .hero-header {
    // height: 1000px;
    padding: $side-padding-xs;
    padding-top: 100px;

    img {
      width: 100%;
      height: 450px;
    }
  }

  .section-padding {
    img {
      width: 295px;
    }
  }

  .image-resize {
    width: 350px;
  }

  .big-padding {
    padding: 15px !important;
  }

  .lpadding {
    padding-bottom: 0;
  }

  .timeline {
    .content {
      position: absolute;
      width: 300px;
    }

    .image-point-row:nth-child(3) .content,
    .image-point-row:nth-child(7) .content {
      left: 20px;
    }

    .image-point-row:nth-child(5) .content {
      left: 20px;
    }
  }

  .section-stories {
    .story-head {
      .current-img-holder {
        height: 150px;

        .current-story-img {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .overlay {
          padding-left: 30px;
          padding-top: 20px;
        }
      }

      .story-holder {
        position: static;
        top: 0;
        right: 0;
        padding-bottom: 150px;

        .story-card {
          position: relative;
        }
      }
    }

    .roller-row {
      overflow-x: scroll;
    }
  }
  /**
	* About
  */
  .justify-response {
    justify-content: center;
  }

  .mission-vision-section {
    div {
      padding: $side-padding-xs;

      &:first-child {
        p {
          position: relative;
          right: 30px;
        }
      }

      p {
        width: 300px;
      }
    }
  }

  .team-member {
    margin-bottom: 20px;
  }

  /**
	* Loans
	*/
  .check-list {
    li {
      padding-top: 36px;
      padding-bottom: 36px;
    }
  }
  .plans-row {
    .card {
      margin-bottom: 30px;
    }
  }

  .request-complete-modal {
    .main-content {
      padding-top: 30px;
      height: 400px;
    }
  }
}

@media (max-width: 375px) {
  // Home
  #main-nav {
    padding: 0;
  }

  .focus-section {
    h3 {
      font-size: 22px;
    }
  }

  .image-resize {
    width: 250px;
  }

  .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 325px) {
  .focus-section {
    .feature-section {
      height: 750px;
    }
  }

  .small-header {
    .small-header-overlay {
      h1 {
        font-size: 28px;
      }
    }
  }
}

// Gbenga's Media Query

@media (max-width: 900px) {
  footer {
    .footer-bottom-section {
      grid-template-columns: 1fr;

      .social-media-icons {
        display: grid;
        grid-template-columns: 1fr 1fr;

        a {
          padding: 10px 0px;
        }
      }
    }
  }
  .blog-section {
    margin-top: 120px;
  }
  .pitch-video {
    video {
      max-height: 200px !important;
      max-width: 100%;
    }
  }
  #registerImage {
    display: none;
  }
  .user-layout {
    display: grid;
    grid-template-columns: 1fr;
  }

  .dashboard-main {
    margin-top: 20px;
    padding: 10px;
  }

  .roi-nav {
    grid-template-columns: 1fr;
  }

  .roi-calculator-holder {
    padding: 0px;
  }

  .dashboard-middle {
    grid-template-columns: 1fr;
  }

  .admin-dashboard-middle {
    grid-template-columns: 1fr;
  }

  .dashboard-bottom {
    grid-template-columns: 1fr;
  }

  .manage-profile {
    padding: 50px 5px;

    .manage-profile-header {
      h6 {
        margin-right: 20px;
        font-size: 14px;
      }
    }

    .manage-profile-card {
      padding: 20px;
    }
  }
  .loan-table {
    padding: 5px;
  }
  .sources-of-income-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .sources-of-income-body {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 20px;
  }
  .registration-page-layout {
    width: 100%;
    left: 0%;

    .source-of-income-fields {
      .btn.btn-shecluded {
        &.sidehustle-button {
          width: 60%;
          left: 20%;
        }
      }
    }

    .sources-of-income-navigation-buttons {
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;

      div {
        padding: 5px 2px;
      }
    }
  }
}

@media (max-width: 768px) {
  .resource-section {
    // background: rgba(229, 229, 229, 0.2);
    width: 100%;
    height: 350px;
    margin-top: 10em;
    @include flex-direction;

    .inner-section {
      width: 60%;
      height: 16em;
      // background-color: $theme-text-color-white;
      @include flexDirection(column);
    }
    .inner-section-content {
      justify-content: center;
    }
    .inner-section-content > div > h4 {
      font-weight: bolder;
      text-align: center;
    }
  }
  .content-end {
    @include flexDirection(column);
  }
  .content-end > div > input,
  .content-end > div > button {
    height: 4em;
  }
  .content-end > .input-div {
    @include flex-direction;
  }
  .content-end > .button-div {
    @include flex-direction;
  }
  .content-end > div > input {
    width: 20em;
    border: none;
    //  background-color: $theme-bg-grey;
    margin: 0.5em;
    margin-top: 0.5em;
    text-align: center;
  }
  .content-end > div > button {
    width: 10em;
    border: none;
    // background-color: $theme-color-black;
    // color: white;
    margin: 0.5em;
    margin-top: 0.5em;
  }
}

@media (max-width: 481px) {
  .manage-profile-header {
    h6 {
      margin-right: 5px;
      font-size: 14px;
    }
  }
  .resource-section {
    background: rgba(229, 229, 229, 0.2);
    width: 100%;
    height: 350px;
    margin-top: 10em;
    @include flex-direction;

    .inner-section {
      width: 70%;
      height: 16em;
      // background-color: $theme-text-color-white;
      @include flexDirection(column);
    }
    .inner-section-content {
      justify-content: center;
    }
    .inner-section-content > div > h4 {
      font-weight: bolder;
      text-align: center;
    }
  }
  .content-end {
    @include flexDirection(column);
  }
  .content-end > div > input,
  .content-end > div > button {
    height: 3em;
  }
  .content-end > .input-div {
    @include flex-direction;
  }
  .content-end > .button-div {
    @include flex-direction;
  }
  .content-end > div > input {
    width: 15em;
    border: none;
    //  background-color: $theme-bg-grey;
    margin: 0.5em;
    margin-top: 0.5em;
    text-align: center;
  }
  .content-end > div > button {
    width: 8em;
    border: none;
    margin: 0.5em;
    margin-top: 0.5em;
  }

  .modal {
    .modal-dialog {
      margin: 0;
      padding-top: 0;
      width: 100%;
      padding-top: 210px;

      .modal-content {
        height: 100%;
      }
    }

    .hmoLoans_card {
      width: 90%;

      p {
        width: 240px !important;
      }
    }
  }
}

// MULTI CHART DIV RESPONSE
@media (max-width: 768px) {
  #for-media {
    display: block;
  }
  #not-for-media {
    display: none;
  }
  .resource-multi-card {
    @include flexDirection(row);
    margin-top: 10em;
  }
  .first-div-multi-card {
    @include flexDirection(column);
    width: 100%;
  }

  .portion-one {
    height: 35em;
    margin: 1em;

    @include flexDirection(column);
  }
  .portion-one > div {
    height: 45%;
    width: 100%;
  }
  .portion-one > div > img {
    height: 100%;
    width: 100%;
  }
  .portion-one > .portion-two-div {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    flex-direction: column;
    margin-left: 1em;
    margin-right: 1em;
    width: 100%;
    height: 55%;
  }
  .Label {
    width: 8em;
    height: 2em;
    padding-left: 0.5em;
    display: flex;
    align-items: center;
    font-size: 1em;
  }
  .portion-two-div > h4 {
    font-weight: bolder;
    word-wrap: break-word;
  }
  .portion-two-div > p {
    font-size: 1.3em;
    word-wrap: break-word;
  }
  .container-div-a {
    display: inline;

    i {
      width: 1em;
      height: 1em;
      text-align: center;
    }
    a {
      padding-left: 1em;
    }
  }

  .portion-two {
    height: 35em;
    margin: 1em;

    @include flexDirection(column);
  }
  .portion-three {
    height: 35em;
    margin: 1em;

    @include flexDirection(column);
    background-color: #fafafa;
  }

  // DIV AFTER HOMEPAGE BOARD
  .Lower-div {
    @include flexDirection(column);
    margin-top: 5em;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 5em;

    .lower-div-one {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      font-style: normal;
      font-weight: 500;
      border-right: none;
      padding-bottom: 1em;

      .upper-div {
        display: flex;
        align-items: center;
        margin-right: 0.5em;
        margin-left: 0.5em;

        img {
          height: 2.5em;
          width: 2.5em;
        }

        .upper-div-img {
          margin: 0.5em;
        }
      }
      .lower-div-p {
        h5 {
          margin: 0;
          font: 2em;
        }
      }
    }

    .lower-div-two {
      @extend .lower-div-one;
      border-right: none;
    }
    .lower-div-three {
      @extend .lower-div-one;
    }
  }
}

@media (max-width: 481px) {
  #for-media {
    display: block;
  }
  #not-for-media {
    display: none;
  }
  .resource-multi-card {
    @include flexDirection(row);
    margin-top: 10em;
  }
  .first-div-multi-card {
    @include flexDirection(column);
    width: 100%;
  }

  .portion-one {
    height: 35em;
    margin: 1em;

    @include flexDirection(column);
  }
  .portion-one > div {
    height: 45%;
    width: 100%;
  }
  .portion-one > div > img {
    height: 100%;
    width: 100%;
  }
  .portion-one > .portion-two-div {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    flex-direction: column;
    margin-left: 0.6em;
    margin-right: 0.6em;
    width: 100%;
  }

  .Label {
    width: 8em;
    height: 2em;
    padding-left: 0.5em;
    display: flex;
    align-items: center;
    font-size: 1em;
  }
  .portion-two-div > h4 {
    font-weight: bolder;
    white-space: initial;
  }
  .portion-paragraph {
    width: 90%;
    font-size: 1.3em;

    .portion-two-div > p {
      font-size: 1.3em;
    }
  }

  .container-div-a {
    display: inline;

    i {
      width: 1em;
      height: 1em;
      text-align: center;
    }
    a {
      padding-left: 1em;
    }
  }
  .portion-three {
    margin-left: 0.6em;
    margin-right: 0.6em;
  }

  // FOR BOARD

  .cover-div {
    @include flex-direction;
    margin-top: 3em;

    .home-page-div {
      //  width: 80%;
      height: 35em;
      background-color: #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: cebter;
      .div-overall {
        @include flexDirection(row);
        width: 100%;
      }
      .div-one {
        word-wrap: break-word;
        text-align: center;
        width: 80%;

        h3 {
          word-wrap: break-word;
          // text-align: start;
        }
      }
      .div-two {
        display: none;
      }
    }
  }
}
// END MULTI CHART DIV RESPONSE

// {********************************       DASHBOARD HOMEPAGE   **************************************8}
@media (max-width: 1024px) {
  .hide-content {
    display: none;
  }
  .show-content {
    display: block;
  }
  .right-cross {
    @include flexDirection(row);
    width: 100%;

    .first-right-cross {
      width: 23%;
      height: 100%;
    }
    .second-right-cross {
      width: 100%;
    }
  }

  .Header-container {
    width: 100vw;
    .first-ihehd {
      display: flex;
    }
    .secondhalf-inner-text {
      display: none;
    }

    .firsthalf-header {
      width: 78%;

      .Search-bar {
        width: 80%;
      }
    }
    .mover {
      margin-right: 23%;
    }
    .non-mover {
      margin-right: 13%;
    }
    .secondhalf-header {
      .secondhalf-inner {
        svg {
          cursor: pointer;
          display: block;
        }
      }
    }
  }

  // LEFT SIDE BAR

  .Dashboard-main-page {
    height: auto;
    @include flexDirection(column);

    .Dashboard-main-page-main {
      width: 100%;
      padding: 0 5px;
    }

    .Dashboard-main-page-side {
      width: 100%;
    }
    // DASHBOARD LEFT SIDE
    .container-of-left-side {
      @include flexDirection(column);
      display: none;

      .leftbar-card-1 {
        height: 40%;
        margin: 0 auto;
        width: 90%;
        margin-top: 2em;
        margin-bottom: 1em;
        // overflow: auto;
      }
      .leftcard-card-2 {
        height: 40%;
        margin: 0 auto;
        width: 90%;
        margin-top: 1em;
        margin-bottom: 1em;
      }
      .leftcard-card-3 {
        margin: 0 auto;
        width: 90%;
        margin-top: 1em;
        margin-bottom: 2em;
        height: 30%;
      }
    }
  }

  .Dashboard-main-page {
    height: auto;
    @include flexDirection(column);

    .Dashboard-main-page-main {
      width: 100%;
    }

    .Dashboard-main-page-side {
      width: 100%;
    }
    // DASHBOARD LEFT SIDE
    .container-of-left-side {
      height: auto;
      @include flexDirection(column);
      display: none;
    }
    .leftbar-card-1 {
      height: 40%;
      width: 100%;
      margin-top: 2em;
      margin-bottom: 1em;
      // background-color: #c40074;
    }
    .leftcard-card-2 {
      height: 40%;
      width: 100%;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    .leftcard-card-3 {
      width: 100%;
      margin-top: 1em;
      margin-bottom: 2em;
      height: 40%;
    }
  }

  // FOR MAIN PAGE DASHBOARD

  // {********************************* BEGINNING OF MAIN DASHBOARD **************************************}

  .maindashboardbody {
    background-color: $theme-color-almost-white;
    display: flex;
    flex-direction: column;
    width: 100%;

    .section-maindashboardbody {
      width: 100%;

      .maindashboard-body-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 85%;

        .two-maindash {
          margin-right: 2em;
        }

        .three-maindash {
          margin-top: 0.5em;
        }
      }
    }

    .middle-body {
      width: 100%;
      margin-top: 2em;
      display: flex;
      flex-direction: column;

      .middle-body-one {
        width: 95%;
        margin-left: 2em;
        border-right: none;

        .inn-inner-top-layer {
          width: 90%;

          .mdbf-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h1 {
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 28px;
            }
            p {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 23px;
            }

            i {
              font-size: 1.5em;
            }
          }
          .mdbf-middle {
            background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0) 0%,
                #f24aaa 100%
              ),
              url("../Imagestyle/Background.png");
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 30%;
            color: white;
            height: 15rem;
            border-radius: 4px;

            .mdbf-text {
              margin-left: 2em;

              .h1-mdbf-text {
                margin-bottom: 0.5em;

                h1 {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 33px;
                  line-height: 38px;
                  margin: 0;
                  padding-top: 1em;
                  // margin-left: 1em;
                }
              }
              .mdbf-text-div {
                width: 40%;
                margin-bottom: 0.5em;
                //  margin: 1em;

                span {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 16px;
                }
              }

              button {
                border: none;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                padding-left: 2em;
                padding-right: 2em;
                margin-bottom: 0.5em;
                box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
                border-radius: 2px;
                // margin: 1em;
              }

              .smaller-mdbf-text {
                //  margin: 1em;
                margin-top: 0.5em;

                span {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 9px;
                  line-height: 10px;
                  padding-right: 1em;
                }
              }
            }
          }
        }

        .mdbf-middle-img-div {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
        }
        .mdbf-middle-img {
          margin-top: -18em;
        }

        .mdbf-bottom {
          width: 90%;
          display: flex;
          flex-direction: row;
          margin-top: 2em;
          height: 20%;

          .mdbf-bottom-text-first {
            background-color: $theme-bg-white;
            width: 49%;
            margin-right: 1em;
            display: flex;
            flex-direction: row;

            .mdbf-text-fist {
              margin-left: 1em;
              @include flexDirection(column);
              margin-top: 1.5em;

              h1 {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 10px;
                margin: 0;
                margin-bottom: 0.5em;
              }

              p {
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 13px;
                // padding-top: 0.3em;
              }
            }

            .icon-first {
              @include flex-direction;
              margin-left: 1em;
            }
            .icon-first-i {
              background-color: rgb(255, 229, 145);
              border-radius: 50%;
              color: rgb(250, 189, 76);
              height: 2.5em;
              width: 2.5em;
              @include flex-direction;
              font-size: 1.2em;
            }

            .last-icon {
              margin-right: 0.5em;
              margin-top: 0.4em;
            }

            .last-icon-i {
              @include flex-direction;
              background-color: $theme-color-almost-grey;
              border-radius: 50%;
              height: 1.5em;
              width: 1.5em;
              color: $theme-color-black;
              font-size: 0.7em;
            }
          }
          .mdbf-bottom-text-second {
            background-color: $theme-bg-white;
            width: 49%;
            margin-left: 1em;
            display: flex;
            flex-direction: row;

            .mdbf-text-second {
              margin-left: 1em;
              @include flexDirection(column);
              margin-top: 1.5em;

              h1 {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 10px;
                margin: 0;
                margin-bottom: 0.5em;
              }

              p {
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 13px;
              }
            }

            .icon-second {
              @include flex-direction;
              margin-left: 1em;
            }
            .icon-second-i {
              background-color: rgb(181, 157, 204);
              border-radius: 50%;
              color: rgb(180, 110, 245);
              height: 2.5em;
              width: 2.5em;
              @include flex-direction;
              font-size: 1.2em;
            }

            .last-icon {
              margin-right: 0.5em;
              margin-top: 0.4em;
            }

            .last-icon-i {
              @include flex-direction;
              background-color: $theme-color-almost-grey;
              border-radius: 50%;
              height: 1.5em;
              width: 1.5em;
              color: $theme-color-black;
              font-size: 0.7em;
            }
          }
        }
      }
      // middle second side
      .middle-body-two {
        height: 100%;
        width: 92%;

        .second-inn-inner-top-layer {
          width: 90%;
          // margin-left: 6.5%;
          margin-top: 2em;

          .mdbs-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h1 {
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 28px;
            }
            p {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 23px;
            }

            i {
              font-size: 1.5em;
            }
          }

          .mdbs-middle {
            width: 100;
            display: flex;
            flex-direction: row;
            // margin-top: 1em;

            .mdbs-middle-inner {
              padding-left: 5%;
              width: 49%;
              height: 12em;
              background: #ffe9f6;
              border-radius: 4px;

              .first-mdbs-icon {
                margin-right: 0.5em;
                margin-top: 0.4em;
                @include flex-direction;
                justify-content: flex-end;
              }

              .first-mdbs-icon-i {
                @include flex-direction;
                background-color: $theme-color-white;
                border-radius: 50%;
                height: 2em;
                width: 2em;
                color: $theme-color;
                font-size: 0.7em;
              }
              .mdbs-middle-inner-text {
                h6 {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 15px;

                  color: #f0008c;
                }
                h1 {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 33px;
                  line-height: 38px;

                  color: #f0008c;
                }
              }

              .mbds-span {
                width: 90%;
                background-color: $theme-bg-white;
                height: 0.4em;
                border-radius: 2px;
                margin-top: 1em;
                // border-bottom: 3px solid $theme-bg-white;

                .mbds-inner-span {
                  width: 85%;
                  background-color: $theme-color;
                  height: 0.4em;
                  border-radius: 2px;
                }
              }

              .mdbs-lower-text {
                background-color: $theme-bg-white;
                margin-top: 1.3em;
                width: 75%;
                height: 1.6em;
                display: flex;
                flex-direction: row;
                align-items: center;

                span {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 11px;
                  line-height: 13px;
                  margin-left: 0.5em;
                  color: #f0008c;
                }

                .l-lower-text {
                  margin-left: 0.5em;
                }

                .mdbs-lower-text-i {
                  @include flex-direction;
                  background-color: #ffe9f6;
                  border-radius: 50%;
                  height: 1.5em;
                  width: 1.5em;

                  color: #f0008c;
                  font-size: 0.7em;
                }
              }
            }

            .mdbs-side-middle-inner {
              background: $theme-color-white;
              width: 49%;
              height: 12em;
              margin-left: 1.2em;
            }

            .mdbs-side-first {
              height: 5em;

              .md-first-top {
                @include flexDirection(row);
                justify-content: flex-start;
                margin-left: 1em;
                margin-top: 1em;

                .md-first-top-d {
                  margin-right: 1em;
                }

                .md-first-top-d-i {
                  @include flex-direction;
                  background-color: #fcedee;
                  border-radius: 50%;
                  height: 2.2em;
                  width: 2.2em;
                  color: #f03e30;
                  font-size: 1em;
                }

                h5 {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  margin: 0;
                  color: #000000;
                }
                span {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 11px;

                  color: rgba(0, 0, 0, 0.4);
                }
              }
              .mds-first-bottom-div {
                margin-top: 1em;
              }
              .md-first-bottom {
                @include flexDirection(row);
                justify-content: flex-start;
                margin-left: 1em;
                //  padding-bottom: 2em;

                span {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 11px;

                  color: rgba(0, 0, 0, 0.4);
                }

                .mds-bottom {
                  @include flexDirection(row);
                  margin-left: 3.9em;
                  align-items: center;
                }

                .mds-bottom-i {
                  @include flex-direction;
                  background-color: #fcedee;
                  border-radius: 50%;
                  height: 1.5em;
                  width: 1.5em;
                  color: #f03e30;
                  font-size: 0.5em;
                }

                .span-i {
                  font-size: 9px;
                  line-height: 9px;

                  color: #f03e30;
                }
              }
            }

            .mdbs-side-second {
              height: 6em;
              border-top: 0.5px solid $theme-color-almost-white;
              margin-bottom: 1em;

              .md-first-top {
                @include flexDirection(row);
                justify-content: flex-start;
                margin-left: 1em;
                margin-top: 1em;

                // .first-top-div{
                //     padding-left: 1em;
                //   }
                .md-first-top-d {
                  // margin-right: 0.5em;
                  // margin-top: 0.4em;
                  margin-right: 1em;
                }

                .md-first-top-d-i {
                  @include flex-direction;
                  background-color: #edf9ee;
                  border-radius: 50%;
                  height: 2.2em;
                  width: 2.2em;
                  color: #38d57e;
                  font-size: 1em;
                }

                h5 {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  margin: 0;
                  color: #000000;
                }
                span {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 11px;

                  color: rgba(0, 0, 0, 0.4);
                }
              }
              .mds-first-bottom-div {
                margin-top: 1em;
              }
              .md-first-bottom {
                @include flexDirection(row);
                justify-content: flex-start;
                margin-left: 1em;

                span {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 11px;

                  color: rgba(0, 0, 0, 0.4);
                }

                .mds-bottom-b {
                  @include flexDirection(row);
                  margin-left: 5em;
                  align-items: center;
                }

                .mds-bottom-i {
                  @include flex-direction;
                  background-color: #edf9ee;
                  border-radius: 50%;
                  height: 1.5em;
                  width: 1.5em;
                  color: #38d57e;
                  font-size: 0.5em;
                }

                .span-i {
                  font-size: 9px;
                  line-height: 9px;

                  color: #38d57e;
                }
              }
            }
          }

          .mdbs-lower {
            // width: 95%;
            height: 13em;
            margin-top: 1em;
            background-color: $theme-bg-white;
            padding-bottom: 3em;
            border-bottom: 2px solid $theme-bg-grey;

            .lower-mdbs-icon {
              margin-right: 0.6em;
              padding-top: 0.6em;
              @include flex-direction;
              justify-content: flex-end;
            }

            .lower-mdbs-icon-i {
              @include flex-direction;
              background-color: $theme-color-almost-grey;
              border-radius: 50%;
              height: 2em;
              width: 2em;
              color: $theme-color-black;
              font-size: 0.7em;
            }

            .overall-for-lower-mdbs-div {
              direction: flex;
              flex-direction: row;
              justify-content: space-between;

              .lower-mdbs-div {
                border: 0.8px solid #d8d8d8;
                box-sizing: border-box;
                border-radius: 2px;
                width: 8em;
                margin-top: 1em;
              }
              .sub-lower {
                direction: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }

  // {********************************* END OF MAIN DASHBOARD **************************************}

  .body-conttainer-savingsdashboard {
    display: flex;
    flex-direction: column;

    .one {
      width: 95%;
    }
    .two {
      width: 95%;
    }
  }

  .my-savings-inner {
    display: flex;
    flex-direction: row;
    //  position: absolute;
  }
  .overall-one-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 15rem;
    background-color: $theme-bg-white;
    box-sizing: border-box;
    margin: 1em;
    // margin-bottom: 1em;
    // margin-right: 2em;
    justify-content: space-between;
  }

  .overall-inner-body {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .financial-inner {
    display: flex;
    width: 90%;
    flex-direction: column;
    background-color: $theme-bg-white;
    box-sizing: border-box;
    margin: 1em;
    justify-content: space-between;
  }

  .overall-sendmoney {
    width: 95%;
    // margin: 1em;
  }

  .over-all-recenttransactions {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: flex-end;
    margin: 2em 2em;
    position: relative;
    width: 95%;
  }
}

@media (max-width: 787px) {
  // FOR DASHBOARD
  .right-cross {
    width: 100%;

    .first-right-cross {
      width: 200px;
      height: 100%;
    }
    .second-right-cross {
      width: 100%;
    }
  }

  //FOR ON CLICK ON THE SIDE BAR HAM VIEW
  .tablet-view {
    @include flexDirection(row);
    width: 100%;

    .first-tablet-view {
      width: 10%;
      height: 7em;
    }
    .second-tablet-view {
      width: 90%;

      .navformedia-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        // width/: 70%;

        ul {
          margin: 0;
          padding: 0;
          height: 0%;
          list-style: none;
          display: flex;
          flex-direction: column;
          justify-content: stretch;
        }

        li {
          height: 100%;
        }
      }
    }
  }
  .smallscreen {
    display: block;
  }
  .mobile {
    display: none;
  }
  .large-screen {
    display: none;
  }
  .navfortab {
    display: block;
  }
  .navformedia {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 7em;
    position: fixed;
    overflow-x: hidden;
    z-index: 1;
    width: 10%;
  }

  .nav-bar-bar-fixed {
    width: 150px;
    background: white;
    z-index: 999;
  }

  // LEFT SIDE BAR

  .Dashboard-main-page {
    height: auto;
    @include flexDirection(column);

    .Dashboard-main-page-main {
      width: 100%;
    }

    .Dashboard-main-page-side {
      width: 100%;
    }
    // DASHBOARD LEFT SIDE
    .container-of-left-side {
      height: auto;
      @include flexDirection(column);
      display: none;
    }
    .leftbar-card-1 {
      margin: 0 auto;
      height: 40%;
      width: 90%;
      margin-top: 2em;
      margin-bottom: 1em;
    }
    .leftcard-card-2 {
      margin: 0 auto;
      height: 40%;
      width: 90%;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    .leftcard-card-3 {
      margin: 0 auto;
      width: 90%;
      margin-top: 1em;
      margin-bottom: 2em;
      height: 40%;
    }
  }

  // FOR MIDDLE MAIN DIV
  .container-div-middle-dashboard {
    .div-middle-dashboard {
      .div-middle-i {
        display: none;
      }
    }
  }

  // FOR END OF DASHBOARD
  .container-End-main-dashboard {
    @include flex-direction;

    .End-dashboard-main {
      background-color: $theme-bg-white;
      width: 90%;

      .End-dashboard-main-inner {
        margin: 4%;

        .main-inner-text {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }
        .apos {
          color: red;
        }
        .main-inner-bottom {
          border: 2px dashed $theme-text-color-disabled;
          border-radius: 2px;
          width: 95%;
          height: 3.2em;
          margin-top: 0.8em;
          @include flex-direction;

          .main-inner-bottom-text {
            @include flex-direction;
            color: $theme-color;
            padding-left: 0.5em;
            margin-top: 0.4em;
          }
        }
        .main-inner-text-two-div {
          margin-top: 2.5em;

          .main-inner-text-two {
            // @extend .main-inner-text;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
          }

          .text-two-one {
            margin-top: 0.8em;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: center;

            input {
              //  width: 50%;
              height: 3.2em;
              border: none;
              background-color: $theme-color-light-grey;
              border-radius: 2px;
            }
            .in-nner {
              width: 95%;
              height: 3.2em;
              border: none;
              // margin-top: 0.3em;
              background-color: $theme-color-light-grey;
            }
            .inn-ner {
              width: 95%;
              margin-top: 0.5em;
              height: 3.2em;
              border: none;
              background-color: $theme-color-light-grey;
            }

            .first-input {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-content: center;
              margin-left: 0.5em;
              margin-right: 90%;
            }
          }

          .text-two-two {
            margin-top: 0.5em;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: center;

            input {
              height: 3.2em;
              border: none;
              border-radius: 2px;
            }
            .in-nner {
              width: 95%;
              height: 3.2em;
              border: none;
            }
            .inn-ner {
              width: 95%;
              height: 3.2em;
              border: none;
              margin-top: 0.5em;
            }
          }

          .text-two-three {
            margin-top: 0.5em;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: center;

            .i-nnner {
              width: 95%;
              height: 3.2em;
              border: none;
              // background-color: $theme-color-light-grey;
            }
            .i-nnner-sec {
              width: 95%;
              margin-top: 0.5em;
              // margin-top: 0;
            }
            .three-text {
              width: 95%;
              height: 3.2em;
              border: none;
              // background-color: $theme-color-light-grey;
            }
          }
        }

        .mainside-button-div {
          margin-top: 3em;

          .mainside-button {
            background-color: #ec008c;
            color: $theme-bg-white;
            height: 3em;
            width: 35%;
            border: none;
            border-radius: 2px;
          }
          .mainside-button:hover {
            background-color: white;
            color: $theme-color;
            border: 1px solid $theme-color;
          }
        }
      }
    }
  }

  .overall-mysavings {
    margin-left: 1em;
    margin-right: 2em;
    width: 90%;
  }

  .inner-finincail {
    width: 100%;
  }
  .financial-inner {
    display: flex;
    flex-direction: column;
    // width: 230px;
    width: 95%;
    height: 160px;
    background-color: $theme-bg-white;
    box-sizing: border-box;
    margin: 1em;
  }

  .secondone {
    justify-content: space-between;
  }
  .my-savings-inner {
    display: flex;
    flex-direction: column;
    //  position: absolute;
  }

  .overall-inner-body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .overall-one-body {
    width: 95%;
    height: 12rem;
  }

  .mdbs-lower {
    width: 90%;
    .lower-mdbs-div {
      box-sizing: border-box;
      border: 0.8px solid #d8d8d8;
      box-sizing: border-box;
      border-radius: 2px;
      width: 50%;
      margin-top: 1em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-left: 1em;
      padding-top: 1em;
      padding-bottom: 1em;

      .lower-mdbs-span-one {
        background-color: #e9f4ff;
        padding-left: 0.5em;
        padding-right: 0.5em;
        color: #367bf5;
      }
    }
  }

  .body-conttainer-savingsdashboard {
    margin-top: 7em;

    font-style: normal;
    height: calc(100vh - 7em);
    overflow-x: hidden;
    background-color: $theme-color-almost-grey;
    display: flex;
    flex-direction: column;

    .one {
      width: 100%;
    }
    .two {
      width: 100%;
    }
  }

  .over-all-recenttransactions {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: flex-end;
    margin: 2em 2em;
    position: relative;
    width: 85%;
  }

  .small-scr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
  .src-1 {
    width: 100%;
    margin: 0 auto;
  }
  .src-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 2em;
  }
}

@media (max-width: 481px) {
  .btn-roi {
    width: 80% !important;
  }

  .right-cross {
    @include flexDirection(row);
    width: 100%;

    .second-right-cross {
      width: 100%;
    }

    .navformedia {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 7em;
      // position: fixed;

      .navformedia-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        // position: fixed;
      }
    }

    // LEFT SIDE BAR

    .Dashboard-main-page {
      height: auto;
      @include flexDirection(column);

      .Dashboard-main-page-main {
        width: 100%;
      }

      .Dashboard-main-page-side {
        width: 100%;
      }
      // DASHBOARD LEFT SIDE
      .container-of-left-side {
        height: auto;
        @include flexDirection(column);
        display: none;
      }
      .leftbar-card-1 {
        height: 40%;
        margin: 0 auto;
        width: 90%;
        margin-top: 2em;
        margin-bottom: 1em;
      }
      .leftcard-card-2 {
        height: 40%;
        margin: 0 auto;
        width: 90%;
        margin-top: 1em;
        margin-bottom: 1em;
      }
      .leftcard-card-3 {
        margin: 0 auto;
        width: 90%;
        margin-top: 1em;
        margin-bottom: 2em;
        height: 30%;
      }
    }

    // TOP MAIN BAR
    .center-main {
      @include flex-direction;
      display: none;

      .top-main-div {
        width: 99%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        margin-top: 8.6em;

        font-style: normal;
        height: 10%;
      }
      .center-main-content {
        //  margin: 0;
        margin-right: 0;
        margin-top: 1em;
        margin-bottom: 0;
        margin-left: 1em;

        h2 {
          font-size: 32px;
        }

        .center-mains-button {
          margin-right: 0;
          margin-top: 0;
          margin-bottom: 1em;
          margin-left: 0;
        }
      }
    }
    // FOR MIDDLE MAIN DIV
    .container-div-middle-dashboard {
      .div-middle-dashboard {
        .div-middle-i {
          display: none;
        }
      }
    }

    // FOR END OF DASHBOARD
    .container-End-main-dashboard {
      @include flex-direction;

      .End-dashboard-main {
        background-color: $theme-bg-white;
        width: 90%;

        .End-dashboard-main-inner {
          margin: 4%;

          .main-inner-text {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
          }
          .apos {
            color: red;
          }
          .main-inner-bottom {
            border: 2px dashed $theme-text-color-disabled;
            border-radius: 2px;
            width: 95%;
            height: 3.2em;
            margin-top: 0.8em;
            @include flex-direction;

            .main-inner-bottom-text {
              @include flex-direction;
              color: $theme-color;
              padding-left: 0.5em;
              margin-top: 0.4em;
            }
          }
          .main-inner-text-two-div {
            margin-top: 2.5em;

            .main-inner-text-two {
              // @extend .main-inner-text;

              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
            }

            .text-two-one {
              margin-top: 0.8em;
              // @include flexDirection(row);
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-content: center;

              input {
                //  width: 50%;
                height: 3.2em;
                border: none;
                background-color: $theme-color-light-grey;
                border-radius: 2px;
              }
              .in-nner {
                width: 95%;
                height: 3.2em;
                border: none;
                // margin-top: 0.3em;
                background-color: $theme-color-light-grey;
              }
              .inn-ner {
                width: 95%;
                margin-top: 0.5em;
                height: 3.2em;
                border: none;
                background-color: $theme-color-light-grey;
              }

              .first-input {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-content: center;
                margin-left: 0.5em;
                margin-right: 90%;
              }
            }

            .text-two-two {
              margin-top: 0.5em;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-content: center;

              input {
                height: 3.2em;
                border: none;
                // background-color: $theme-color-light-grey;
                border-radius: 2px;
              }
              .in-nner {
                width: 95%;
                height: 3.2em;
                border: none;
                // background-color: $theme-color-light-grey;
              }
              .inn-ner {
                width: 95%;
                height: 3.2em;
                border: none;
                margin-top: 0.5em;
                // background-color: $theme-color-light-grey;
              }
            }

            .text-two-three {
              margin-top: 0.5em;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-content: center;

              input {
                height: 3.2em;
                border: none;
                // background-color: $theme-color-light-grey;
                border-radius: 2px;
              }
              .i-nnner {
                width: 95%;
                height: 3.2em;
                border: none;
                // background-color: $theme-color-light-grey;
              }
              .i-nnner-sec {
                width: 95%;
                margin-top: 0.5em;
                // margin-top: 0;
              }
              .three-text {
                width: 95%;
                height: 3.2em;
                border: none;
                // background-color: $theme-color-light-grey;
              }
            }
          }

          .mainside-button-div {
            margin-top: 3em;

            .mainside-button {
              background-color: #ec008c;
              color: $theme-bg-white;
              height: 3em;
              width: 35%;
              border: none;
              border-radius: 2px;
            }
            .mainside-button:hover {
              background-color: white;
              color: $theme-color;
              border: 1px solid $theme-color;
            }
          }
        }
      }
    }
  }

  .full-cross {
    // display: none;

    .full-cross-content {
      @include flexDirection(row);
      width: 100%;

      .side-left-cross {
        width: 15%;
        background-color: $theme-color-white;
        height: 7em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .side-right-cross {
        width: 95%;
      }
    }

    .body-cross {
      .navformedia-content {
        display: flex;
        flex-direction: column;
        height: 100vh;

        ul {
          margin: 0;
          padding: 0;
          height: 100%;
          list-style: none;
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          margin: 0 auto;
        }

        li {
          height: 100%;
        }
      }
    }
  }
  .mobile {
    display: block;
  }
  .large-screen {
    display: none;
  }

  .mdbs-lower {
    display: none;
  }

  .overall-one-body {
    width: 95%;
    height: 13rem;
  }
  .overall-mysavings {
    margin-left: 1em;
    margin-right: 2em;
    width: 90%;
  }

  .overall-inner-body {
    display: flex;
    flex-direction: column;
    width: 95%;
  }
  .inner-finincail {
    width: 100%;
  }

  .financial-inner {
    display: flex;
    flex-direction: column;
    // width: 230px;
    width: 100%;
    height: 160px;
    background-color: $theme-bg-white;
    box-sizing: border-box;
    margin: 1em;
  }

  .overall-sendmoney {
    display: none;
  }

  .over-all-recenttransactions {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: flex-end;
    margin: 2em 2em;
    position: relative;
    width: 85%;
  }
  .body-conttainer-savingsdashboard {
    margin-top: 7em;

    font-style: normal;
    height: calc(100vh - 7em);
    overflow-x: hidden;
    background-color: $theme-color-almost-grey;
    display: flex;
    flex-direction: column;

    .one {
      width: 100%;
    }
    .two {
      width: 100%;
    }
  }

  .header-employed {
    margin-left: 2em;
    margin-right: 2em;
    margin-top: 5em;
  }
  .header-owner {
    margin: 10px;
  }
}

@media (max-width: 633px) {
  // FOR MAIN PAGE DASHBOARD

  // {********************************* BEGINNING OF MAIN DASHBOARD **************************************}

  .overview-result {
    flex-direction: column;
    justify-content: flex-start;
  }
  .overview-result-right,
  .overview-result-left {
    width: 90%;
  }
  .maindashboardbody {
    .section-maindashboardbody {
      .maindashboard-body-inner {
        width: 85%;
      }
    }

    .middle-body {
      width: 100%;

      .middle-body-one {
        width: 95%;

        .inn-inner-top-layer {
          .mdbf-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .mdbf-middle {
            width: 100%;

            .mdbf-text {
              .mdbf-text-div {
                width: 40%;
              }
            }
          }
        }

        .mdbf-middle-img-div {
          display: none;
        }
        .mdbf-middle-img {
          display: none;
        }

        .mdbf-bottom {
          width: 90%;
          display: flex;
          flex-direction: column;
          margin-top: 2em;

          .mdbf-bottom-text-first {
            width: 100%;

            .last-icon {
              margin-right: 0.5em;
              margin-top: 0.4em;
              margin-left: 10%;
            }
          }
          .mdbf-bottom-text-second {
            width: 100%;
            margin-top: 1em;
            margin-left: 0;

            .last-icon {
              margin-left: 10%;
            }
          }
        }
      }

      .middle-body-two {
        width: 94%;
        // margin-top: 2.5em;

        .second-inn-inner-top-layer {
          width: 90%;
          margin-left: none;
          margin-top: 3em;

          .mdbs-middle {
            width: 100;
            display: flex;
            flex-direction: column;

            .mdbs-middle-inner {
              width: 100%;

              .mdbs-lower-text {
                width: 50%;
              }
            }

            .mdbs-side-middle-inner {
              width: 100%;
              margin-top: 1em;
              margin-left: 0;
            }

            .mdbs-side-first {
              height: 5em;

              .md-first-top {
                .md-first-top-d {
                  margin-right: 1em;
                }

                // .md-first-top-d-i{

                // }
              }
              .md-first-bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .mds-bottom {
                  margin-right: 1em;
                }
              }
            }

            .mdbs-side-second {
              // .md-first-top{

              // }
              .md-first-bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-left: 1em;

                span {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 11px;

                  color: rgba(0, 0, 0, 0.4);
                }

                .mds-bottom-b {
                  align-items: center;
                  margin-right: 1em;
                }
              }
            }
          }

          .mdbs-lower {
            height: 10.13em;
            margin-top: 1em;
            background-color: $theme-bg-white;

            .lower-mdbs-icon {
              margin-right: 0.6em;
              padding-top: 0.6em;
              @include flex-direction;
              justify-content: flex-end;
            }

            .lower-mdbs-icon-i {
              @include flex-direction;
              background-color: $theme-color-almost-grey;
              border-radius: 50%;
              height: 2em;
              width: 2em;
              color: $theme-color-black;
              font-size: 0.7em;
            }

            .overall-for-lower-mdbs-div {
              direction: flex;
              flex-direction: row;
              justify-content: space-between;

              .lower-mdbs-div {
                border: 0.8px solid #d8d8d8;
                box-sizing: border-box;
                border-radius: 2px;
                width: 8em;
                margin-top: 1em;
              }
              .sub-lower {
                direction: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }

  // {********************************* END OF MAIN DASHBOARD **************************************}
}

@media (max-width: 608px) {
  // FOR MAIN PAGE DASHBOARD

  // {********************************* BEGINNING OF MAIN DASHBOARD **************************************}

  .maindashboardbody {
    .section-maindashboardbody {
      .maindashboard-body-inner {
        width: 85%;
        height: 6em;
      }
    }

    .middle-body {
      width: 100%;

      .middle-body-one {
        width: 95%;

        .inn-inner-top-layer {
          .mdbf-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .mdbf-middle {
            width: 100%;

            .mdbf-text {
              .h1-mdbf-text {
                margin-bottom: 0.5em;

                h1 {
                  //
                  // font-style: normal;
                  // font-weight: 500;
                  // font-size: 33px;
                  // line-height: 38px;
                  // margin: 0;
                  padding-top: 0.5em;
                  // margin-left: 1em;
                }
              }

              .mdbf-text-div {
                width: 100%;
                margin-bottom: 0.3em;
              }

              button {
                margin-bottom: 0;
                // margin: 1em;
              }
            }
          }
        }

        .mdbf-middle-img-div {
          display: none;
        }
        .mdbf-middle-img {
          display: none;
        }
      }

      .middle-body-two {
        width: 95%;
        // margin-top: 2.5em;

        .second-inn-inner-top-layer {
          width: 90%;
          margin-left: none;
          //  margin-top: 6em;

          .mdbs-middle {
            width: 100;
            display: flex;
            flex-direction: column;

            .mdbs-middle-inner {
              width: 100%;

              .mdbs-lower-text {
                width: 50%;
              }
            }

            .mdbs-side-middle-inner {
              width: 100%;
              margin-top: 1em;
              margin-left: 0;
            }

            .mdbs-side-first {
              height: 5em;

              .md-first-top {
                .md-first-top-d {
                  margin-right: 1em;
                }

                // .md-first-top-d-i{

                // }
              }
              .md-first-bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .mds-bottom {
                  margin-right: 1em;
                }
              }
            }

            .mdbs-side-second {
              // .md-first-top{

              // }
              .md-first-bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-left: 1em;

                span {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 11px;

                  color: rgba(0, 0, 0, 0.4);
                }

                .mds-bottom-b {
                  align-items: center;
                  margin-right: 1em;
                }
              }
            }
          }

          .mdbs-lower {
            height: 13em;
            margin-top: 1em;
            background-color: $theme-bg-white;

            .lower-mdbs-icon {
              margin-right: 0.6em;
              padding-top: 0.6em;
              @include flex-direction;
              justify-content: flex-end;
            }

            .lower-mdbs-icon-i {
              @include flex-direction;
              background-color: $theme-color-almost-grey;
              border-radius: 50%;
              height: 2em;
              width: 2em;
              color: $theme-color-black;
              font-size: 0.7em;
            }

            .overall-for-lower-mdbs-div {
              direction: flex;
              flex-direction: row;
              justify-content: space-between;

              .lower-mdbs-div {
                border: 0.8px solid #d8d8d8;
                box-sizing: border-box;
                border-radius: 2px;
                width: 8em;
                margin-top: 1em;
              }
              .sub-lower {
                direction: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }

  // {********************************* END OF MAIN DASHBOARD **************************************}
}

@media (max-width: 359px) {
  // FOR MAIN PAGE DASHBOARD

  // {********************************* BEGINNING OF MAIN DASHBOARD **************************************}

  .maindashboardbody {
    .section-maindashboardbody {
      .maindashboard-body-inner {
        width: 85%;
        height: 6em;
      }
    }

    .middle-body {
      width: 100%;

      .middle-body-one {
        width: 95%;

        .inn-inner-top-layer {
          .mdbf-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .mdbf-middle {
            width: 100%;

            .mdbf-text {
              .h1-mdbf-text {
                margin-bottom: 0.5em;

                h1 {
                  //
                  // font-style: normal;
                  // font-weight: 500;
                  // font-size: 33px;
                  // line-height: 38px;
                  // margin: 0;
                  padding-top: 0.5em;
                  // margin-left: 1em;
                }
              }

              .mdbf-text-div {
                width: 100%;
                margin-bottom: 0.3em;
              }

              button {
                margin-bottom: 0;
                // margin: 1em;
              }
            }
          }
        }

        .mdbf-middle-img-div {
          display: none;
        }
        .mdbf-middle-img {
          display: none;
        }
      }

      .middle-body-two {
        width: 95%;
        // margin-top: 2.5em;
        margin-top: 5%;

        .second-inn-inner-top-layer {
          width: 90%;
          margin-left: 1em;
          //  padding-top: 5%;

          .mdbs-middle {
            width: 100;
            display: flex;
            flex-direction: column;

            .mdbs-middle-inner {
              width: 100%;

              .mdbs-lower-text {
                width: 50%;
              }
            }

            .mdbs-side-middle-inner {
              width: 100%;
              margin-top: 1em;
              margin-left: 0;
            }

            .mdbs-side-first {
              height: 5em;

              .md-first-top {
                .md-first-top-d {
                  margin-right: 1em;
                }

                // .md-first-top-d-i{

                // }
              }
              .md-first-bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .mds-bottom {
                  margin-right: 1em;
                }
              }
            }

            .mdbs-side-second {
              // .md-first-top{

              // }
              .md-first-bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-left: 1em;

                span {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 11px;

                  color: rgba(0, 0, 0, 0.4);
                }

                .mds-bottom-b {
                  align-items: center;
                  margin-right: 1em;
                }
              }
            }
          }

          .mdbs-lower {
            height: 10.13em;
            margin-top: 1em;
            background-color: $theme-bg-white;

            .lower-mdbs-icon {
              margin-right: 0.6em;
              padding-top: 0.6em;
              @include flex-direction;
              justify-content: flex-end;
            }

            .lower-mdbs-icon-i {
              @include flex-direction;
              background-color: $theme-color-almost-grey;
              border-radius: 50%;
              height: 2em;
              width: 2em;
              color: $theme-color-black;
              font-size: 0.7em;
            }

            .overall-for-lower-mdbs-div {
              direction: flex;
              flex-direction: row;
              justify-content: space-between;

              .lower-mdbs-div {
                border: 0.8px solid #d8d8d8;
                box-sizing: border-box;
                border-radius: 2px;
                width: 8em;
                margin-top: 1em;
              }
              .sub-lower {
                direction: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }

  // {********************************* END OF MAIN DASHBOARD **************************************}
}

//IWD PAGE
@media (max-width: 1024px) {
  .iwd-container {
    .initiative-container,
    .collaborate-container {
      padding: 50px 10px;
    }
    .header-container,
    .aboutus-container {
      padding: 50px 50px;
    }
    .impact-container {
      .wrapper {
        width: 860px;
        margin: 0 auto;

        .big {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .iwd-container {
    .impact-container {
      .container {
        padding: 10px 10px;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-auto-rows: 120px;
      }
      .wrapper {
        width: 500px;
      }
    }
  }
}

@media (max-width: 529px) {
  .iwd-container {
    h2 {
      line-height: 35px;
    }
    .initiative-container,
    .aboutus-container,
    .collaborate-container,
    .header-container {
      padding: 50px 10px;
    }
    .header-container {
      h1 {
        line-height: 60px;
      }
      p {
        width: 100% !important;
      }
    }
    .impact-container {
      padding: 50px 10px;
      .container {
        padding: 10px 10px;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-auto-rows: 80px;
      }
      .wrapper {
        width: 350px;
      }
    }
  }
}

@media (max-width: 302px){
  .impact-container {
    padding: 50px 10px;
    .container {
      padding: 10px 10px;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-auto-rows: 80px;
    }
    .wrapper {
      width: 300px;
    }
  }
}

//{********************************      END DASHBOARD  HOMEPAGE  **************************************8}
