.container {
    padding: 2em 4.5em;
}

.sub_container {
    background: #FFF3F9;
    display: flex;
    margin: auto;
    padding: 8.641em 4.5em ;
    border-radius: 28px;
    flex-wrap: wrap;
    max-width: 75em;
    margin-bottom: 2em;
}

.text_container, .img_container {
    flex: 1;
}

.img {
    display: block;
    margin: auto;
}

.tagline {
    color: #4C4C4C;
    font-family: 'Lato', sans-serif;
    font-size: 29px;
    font-style: normal;
    font-weight: 900;
    line-height: 119%; /* 34.51px */
    text-transform: capitalize;
}

.title {
    color: #F73;
    font-family: 'Lato', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: 119%; /* 52.36px */
    text-transform: capitalize;
}

.text_body {
    color: #747474;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    line-height: 140%; /* 52.36px */
}

.navigators {
    display: block;
    /* border: solid; */
    margin: 2em auto;
    flex: 0 0 100%;
    width: 8em;
    text-align: center;
}

.nav {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1em;
    margin-left: 1em;
}

.nav:hover {
    cursor: pointer;
}

.last {
    color: #A3A3A3;
    font-family: 'Lato', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: 119%; /* 52.36px */
    text-transform: capitalize;
    text-align: center;
}

@media (max-width: 1024px) {
    .container {
        padding: 2em 1em;
    }

    .sub_container {
        /* display: flex; */
        flex-direction: column;
        padding: 8.641em 1em ;
        border-radius: 28px;
    }

    .text_body {
        color: #747474;
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .title {
        color: #F73;
        font-family: 'Lato', sans-serif;
        font-size: 35px;
        font-style: normal;
        font-weight: 900;
        line-height: 119%; /* 41.65px */
        text-transform: capitalize;
    }

    .img { 
        width: 17em;
    }

    .last {
        font-size: 33px;
    }

    .step_1 {
        color: #4C4C4C;
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 119%; /* 23.8px */
        text-transform: capitalize;
    }
}