.container {
    background: #FFF4FA;
    padding: 7.375em 2.5em 4.563em;
}

.title {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 65.45px */
    text-transform: capitalize;
    width: 20em;
    display: block;
    margin: auto;
    word-break: keep-all;
}

.dynamic {
    color: #010101;
    font-family: 'Lato', sans-serif;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
    text-transform: capitalize;
}

.number {
    color: #303030;
    font-family: 'Lato', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.details {
    color: #303030;
    font-family: 'Lato', sans-serif;
    font-size: 18.495px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    word-break: keep-all;
}

.statistics {
    display: flex;
    max-width: 82em;
    margin: auto;
    margin-top: 2.5em;
}

.partition {
    flex: 1;
    /* border: solid; */
}

.partition:first-child > .details, .partition:nth-child(2) > .details {
    text-align: center;
}

.partition:last-child > .details {
    /* border: solid; */
    width: 13em;
}

.number, .details {
    text-align: center;
    margin-bottom: 0;
    /* max-width: 15em; */
}

.details {
    text-align: left;
    margin: auto;
    /* border: solid; */
}


@media (max-width: 1024px) {
    .container {
        padding: 7.375em .5em 4.563em;
    }
    
    .title {
        margin: auto;
        display: block;
        width: 12.4em;
        /* border: solid; */
    }

    .statistics {
        flex-wrap: wrap;
    }
    
    .partition {
       flex: 1;
        /* border: solid; */
        margin-top: 2em;
    }

    .title, .dynamic {
        font-size: 23px;
    }

    .number {
        font-size: 40px;
    }

    .details, .partition:last-child > .details {
        font-size: 14.495px;
        text-align: center;
        width: 9.9em;
        /* border: solid; */
    }
}