.container {
    background: #FFF8FD;
    padding: 10.702em 4em 3.753em;
    /* overflow: hidden; */
}

.tagline {
    color: #535353;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* border: solid; */
    display: block;
    max-width: 60em;
    margin: auto;
}

.title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 38.41px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 45.708px */
    text-transform: capitalize;
    /* border: solid; */
    display: block;
    max-width: 31.5em;
    margin: auto;
    margin-top: .5em;
}

.next_line {
    display: block;
}

.job_container {
    overflow: hidden;
    scroll-behavior: smooth;
    max-width: 75em;
    margin: auto;
    margin-top: 2em;
}

.job_categories {
    /* display: block; */
    /* width: 3000vw; */
    /* border: solid; */
    scroll-behavior: smooth;
    display: flex;
}

.img {
    display: block;
    width: 100%;
    height: 16.347em;
    /* border: solid; */
}

.category {
    /* display: inline-block; */
    min-width: 26.447em;
    /* float: left; */
    margin-right: 4em;
}

.link {
    margin-top: 0;
    background: #FFF;
    box-shadow: 0px 4px 60px 0px rgba(196, 196, 196, 0.25);
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 21.41px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 25.478px */
    text-transform: capitalize;
    padding: 2.284em 1.039em 1.803em;
}

.link::after {
    content: url("/public/images/Arrow\ -\ Right\ 2.png");
    vertical-align: -.14em;
    margin-left: .8em;
}

.navigators {
    display: flex;
    text-align: center;
    margin-top: 4.876em;
    justify-content: center;
}

.nav {
    margin-left: 1.625em;
}

.nav:hover {
    cursor: pointer;
}

@media (max-width: 1024px) {
    .container {
        padding: 10.702em 1.3em 3.753em;
    }

    .category {
        /* width: 15.447em; */
        margin-right: 1.3em;
    }

   
    .navigators {
        /* border: solid; */
        display: none;
        /* justify-content: center; */
    }

    .tagline {
        font-size: 16px;
    }

    .title {
        font-size: 32.41px;
    }

    .next_line {
        display: inline;
    }

    .job_categories {
        display: block;
        margin: auto;
        width: 20em;
        /* border: solid; */
        
    }

    .category {
        /* display: inline-block; */
        min-width: 18.447em;
        max-width: 19.847em;
        /* float: left; */
        margin-right: 0;
    }
}

