.container {
    padding: 8.428em 0 8.375em;
    /* border: solid; */
   
    background: #FFF8FD;
}

.title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 52.36px */
    text-transform: capitalize;
    word-break: keep-all;
    /* border: solid; */
    max-width: 27em;
    margin: auto;
    margin-bottom: 1em;
}

.next_line {
    display: block;
    
}

.sub_container {
    display: flex;
    /* border: solid; */
    /* width: 72.563em; */
    margin: auto;
    height: 42em;
    max-width: 75em;
}

.text_container {
    flex: 1;
    border-radius: 50px 0 0 50px;
    background: #EB2590;
    /* border: solid; */
    padding: 2.438em 2.625em;
}

.img_container {
    flex: 1;
    /* border: solid; */
    padding: 0;
    margin: 0;
    border-radius: 0px 50px 50px 0px;
    overflow: hidden;
}


.img {
    width: 40em;
    max-width: 40em;
    height: 45em;
}

.text_loan {
    color: #3A001F;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 2em;
}

@media (max-width: 1024px) {

    .container {
        padding: 11.428em 0.75em 8.375em;
    }

    .sub_container {
        display: block;
    }

    .title {
        font-size: 27px;
        text-align: center;
        margin: auto;
        margin-bottom: 1em;
        display: block;
    }

    .text_container {
        padding: 2.438em 0.625em;
        height: 55em;
        border-radius: 50px;
    }

    .img {
        display: none;
        width: 18em;
        margin: auto;
        border-radius: 20px;
        margin-top: 2em;
    }

    .text_loan {
        font-size: 15px;
        margin-top: 18em;
    }

    .img_container {
        display: none;
    }
}