.body {
    color: #747474;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 1em 4em 3em;
    word-break: keep-all;
    max-width: 63em;
    display: block;
    margin: auto;
}

@media (max-width: 1024px) {   
    .body {
        padding: .2em 1em .2em 1em;
        font-size: 15px;
    }
}