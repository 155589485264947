.card_container {
    flex: 33%;
    display: block;
    /* border: solid #000; */
    max-width: 22.771em;
    margin: auto;
    margin-top: 5em;
    
    
}



.text_title {
    color: #141414;
    font-family: 'Lato' sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-break: keep-all;
}

.details {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato' sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    height: 8.5em;
}



.text_container {
    background: #FFF;
    box-shadow: 0px 4px 4px 3px rgba(220, 220, 220, 0.25);
    display: block;
    /* border: solid; */
    padding: 1.688em 1em 0.688em;
    /* height: 16.301em; */

    max-height: 17em;
    min-height: 17em;
    overflow: hidden;
}


.text_title {
    color: #141414;
    font-family: 'Lato' sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-break: keep-all;
}


.link {
    /* position: absolute; */
    display: flex;
    /* margin-bottom: 0; */
    /* height: 2em; */
    align-items: flex-end;

}





.link::after, .link::before {
    display: block;
    content: " ";
    clear: both;
}

.story {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* float: left;
     */
     display: block;

     
}

.instagram {
    display: block;
    margin-left: auto;
  margin-right: 0;
}   

.img {
    border-radius: 170px 170px 0px 0px;
    /* border: solid; */
    width: 22.8em;
    height: 24.3em;
}

@media (max-width: 1024px) {
    .text_container {
        padding: 1.688em 1em 0.688em;
        width: 18em;
        min-width: 18em;
        max-width: 18em;
        max-height: 15em;
        min-height: 15em;
    }


    .img {
        width:  18em;
        min-width: 18em;
        max-width: 18em;
        height: 19em;
    }

    .details {
        font-size: 100;
        height: 9em;
        /* border: solid; */
    }

    .text_title, .details {
        font-size: 15px;
    }

    .link {
        margin-top: 0;
        /* border: solid; */
    }

    .story, .instagram {
        /* color: var(--Primary, #EB2590); */
        font-family: 'Lato', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        /* float: left;
        */
        display: block;
    }

   
}