.container {
    padding: 12.041em 0;
    max-width: 75em;
    margin: auto;
}

.tagline {
    color: #535353;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.title {
    color: var(--Footer-Background, #141414);
    font-family: 'Lato', sans-4emserif;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 83.3px */
    text-transform: capitalize;
}

.next_line {
    display: block;
}

.pink {
    color: var(--Primary, #EB2590);
}

.img {
    border-radius: 170px 170px 0px 0px;
    /* border: solid; */
    width: 22.8em;
    height: 24.3em;
}

.sub_container {
    display: flex;
    margin: auto;
    
    /* border: solid; */
}


.partition {
    flex: 1;
    /* border: solid; */
    margin-right: 2em;
    max-height: 35em;
    min-height: 35em;
    min-width: 18em;

}

.card_container {
    display: block;
    /* border: solid #000; */
    max-width: 22.771em;
    margin: 2em auto;
}

.text_container {
    background: #FFF;
    box-shadow: 0px 4px 4px 3px rgba(220, 220, 220, 0.25);
    display: block;
    /* border: solid; */
    padding: 1.688em 1em 0.688em;
    max-height: 17em;
    min-height: 17em;
    /* max-width: 20em; */
}


.text_title {
    color: #141414;
    font-family: 'Lato' sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-break: keep-all;
}

.details {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato' sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.link {
    display: block;
    margin-top: .274em;
}

.partition:last-child > .card_container > .text_container > .link {
    margin-top: 4.8em;
}


.link::after, .link::before {
    display: block;
    content: " ";
    clear: both;
}

.story {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    float: left;
}

.instagram {
  float: right;
}

@media (max-width: 1024px) {
    .container {
        padding: 12.041em 1em;
    }

    .next_line {
        display: inline;
    }

    .tagline {
        font-size: 16px;
    }

    .title, .pink {
        font-size: 37px;
    }

    .sub_container {
        /* display: block; */
        overflow: hidden;
        scroll-behavior: smooth;
        transition: margin-left .5s linear;
        /* border: solid; */
        max-width: 100em;
    }

    .partition {
        display: block;
        margin: auto;
        margin-top: 2em;
        margin-right: 2em;
        max-height: 39em;
        min-height: 39em;
        min-width: 18em;
    }

    .img {
        width: 18em;
        height: 19em;
        display: block;
        margin: auto;
    }

    .text_container {
        padding: 1.688em 1em 0.688em;
        max-height: 15em;
        min-height: 15em;
    }

    .text_title, .details {
        font-size: 15px;
    }

    .link {
        margin-top: 0em;
        /* margin-bottom: -15em; */
        margin-bottom: auto;
    }

    .story, .instagram {
        font-size: 15px;
    }

    .partition:last-child > .card_container > .text_container > .link {
        margin-top: 5.3em;
    }

}