.header{
    position: relative;
    top: 0;
    width: 100%;
    margin: 0;
    /* border: solid; */
    overflow: none;
    padding: 1.25em 4em;
    z-index: 2;
    background-color: rgba(255, 255, 255, 1);
    /* padding-right: 3.938em; */
    display: block;
    /* flex-direction: row; */
    height: 5em
}

.sub {
    max-width: 75em;
    display: flex;
    margin: auto;
    /* border: solid; */

}


.second_background {
    background: #FFE7F4;
}

.img_container {
    clear: both;
    /* width: 9.438;
    height: 2.938em; */
    /* float: left; */
    padding: .5em 0 0 0;
    /* margin-top: .2em; */
    /* margin-top: auto; */
    /* display: inline-block; */
    flex: 1;
    /* border: solid 1px #000; */
    margin: auto;
    display: block;
}

.logo {
    width: 9.438em;
}


.__right {
    flex: 8;

    /* padding: 1.38em 4em; */
    /* float: right; */
    /* border: solid 1px #000; */
    /* display: inline-block; */
    /* margin-right: 6em; */
    /* text-align: center; */
    /* margin-left: auto; */
    /* float: right; */
    /* margin-top: auto; */
    /* vertical-align: top; */
    margin-left: auto;
    display: block;
}

/*.__left {
    padding: 1.75em 4em;
    float: left;
    border: solid 1px #000;
} */

@media (max-width: 1024px) {
    /* .__left {
        float: none;
        padding: 0;
    } */
    .__top {
        position: absolute;
        background-color: #EB2590;
        display: block;
        width: 100vw;
        height: 0;
        top: 0;
        /* bottom: 0; */
        /* padding-top: 0.688em; */
        /* top: -110vh; */
        transition-property: height;
        transition-duration: .5s;
        transition-timing-function: ease;
        transition-delay: 0s;
        overflow-y: auto;
        position: fixed;
        /* padding-left: 0.5em; */
    }
    .top-anim {
        height: 100vh;
    }

    .sub {
        max-width: 100%;
    }


    .logo {
       margin-left: 1em;
       /* width: 9.5em; */
       width: 151.004px;
       /* height: 1.563em; */
       /* margin-bottom: 2em;
       margin-top: 1em; */
    }

    .logo1 {
        margin-left: 1em;
        /* width: 9.5em; */
        width: 151.004px;
        /* height: 1.563em; */
        margin-bottom: 2em;
        margin-top: 1em;
     }

    .header {
        /* position: fixed; */
        padding: 1em 0 0;
        width: 100%;
        /* border: solid; */
        height: 4em;
    }

}