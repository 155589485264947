.container {
    padding: 4.213em 0;
    margin: auto;
    display: block;
    max-width: 75em;
}

.heading {
    display: block;
    overflow: auto;
    /* border: solid; */
}

.title {
    /* float: left; */
    color: #450028;
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.deadline {
    float: right;
    color: #8F8F8F;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 2em;
}

.text_body {
    color: #747474;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: keep-all;
    /* text-align: left; */
}


@media (max-width: 1024px) {
    .container {
        padding: 4.213em 0;
        margin: auto 1em;
    }

    .title, .deadline {
        display: block;
        float: none;
        text-align: left;
        font-size: 23px;
    }

    .text_body {
        text-align: left;
        font-size: 18px;
    }

}