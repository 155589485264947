.container {
    position: relative;
    background: #FFF8FD;
    padding: 8.438em 0;
    z-index: 2;
}

.top_container {
    display: flex;
    margin: auto;
    max-width: 75em;
}

.text_container, .img_container {
    flex: 1;
}

.text_container {
    border-radius: 23px 0px 0px 23px;
    opacity: 0.85;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url("/public/images/pattern_white.png"), lightgray 50% / cover no-repeat;
    background-color: #EB2590; 
    background-blend-mode: soft-light;
    padding: 6.75em 5.25em;
    
}

.img_container {
    background: url("/public/images/annual_cohort.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0px 23px 23px 0px;

}

.title {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 47px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.text_body {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 29px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.link > a {
    color: #FFFFFF;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.link::after {
    content: url("/public/images/arrw_right_white.png");
    vertical-align: middle;
    margin-left: 1em;
}

.bottom_container {
    display: flex;
    margin: auto;
    max-width: 75em;
}

.curriculum, .alumni_base {
    flex: 1;
    padding: 7.5em 5.25em;
    /* border: solid; */
}

.bottom_title {
    color: var(--Secondary, #F73);
    /* text-align: center; */
    font-family: Lato;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 44.03px */
    text-transform: capitalize;
}

.curriculum > .bottom_title::before {
    content: url("/public/images/curriculum.png");
    margin-right: 1em;
}

.alumni_base > .bottom_title::before {
    content: url("/public/images/alumni_base.png");
    margin-right: 1em;
}

.bottom_body {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.app_note {
    color: var(--Footer-Dark, #747474);
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


@media (max-width: 1024px) { 
    .container {
        padding: 8.438em 1em;
    }

    .top_container {
        /* display: block; */
        flex-direction: column;
    }

    .title {
        font-size: 28px;
    }

    .text_body {
        font-size: 20px;
    }
    
    
    .img_container {
        background: url("/public/images/annual_cohort_mobile.png");
        min-height: 18.625em;
        border-radius: 0px 0px 23px 23px;
    }
    
    .text_container {
        border-radius: 23px 23px 0px 0px;
        padding: 6.75em 1em;
        background-blend-mode: soft-light;
        
    }

    .link > a {
        font-size: 16px;
    }
    
    .link::after {
        margin-left: 1em;
        vertical-align: -.2em;
    }

    .bottom_container {
        display: block;
    }
    
    .curriculum, .alumni_base {
        display: block;
        padding: 2.5em 0;
        /* border: solid; */
    }

    .alumni_base {
        padding: 1.5em 0;
    }

    .bottom_title {
        font-size: 33px;
    }

    .bottom_body {
        font-size: 15px;
    }

    .app_note {
        font-size: 16px;
    }
}