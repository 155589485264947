.container {
    display: flex;
    background: #FFF;
    padding: 13.375em 0 8.813em 0;
    max-width: 75em;
    margin: auto;
    /* margin-top: 20em; */
    /* border: solid; */
}


.partition {
    flex: 1;
}

.title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    word-break: keep-all;
    /* border: solid; */
    margin-top: 2em;
}

.vision {
    color: var(--Primary, #EB2590);
    font-family: 'Playfair Display', serif;
    /* font-size: 70px; */
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}

.text-body {
    color: #5D5D5D;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 24em;
    word-break: keep-all;
}

.img {
    display: block;
    margin: auto 0 auto auto;
}

@media (max-width: 1024px) {
    .container {
        display: block;
        padding: 1.375em 1em 7.813em;
    }

    .partition {
        display: block;
    }
    .title {
        text-align: center;
        font-size: 42px;
    }

    .text-body {
        font-size: 16px;
        width: auto;
        display: block;
        margin: auto;
        text-align: center;
    }

    .img {
        margin: auto;
        margin-top: 2em;
        width: 75%;
        display: block;
    }
}