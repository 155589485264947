.container {
    padding: 4.213em 0;
    margin: auto;
    display: block;
    max-width: 75em;
    margin-bottom: 7em;
}


.title {
    color: #450028;
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%; /* 37.62px */
}

.note {
    color: var(--text-2, #283655);
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%; /* 17.1px */
}

.sub_container {
    display: flex;
    /* border: solid; */
}

.form {
    flex: 2;
    /* display: block; */
    /* float: left; */
    /* overflow: hidden; */
}
.img {
    flex: 1;
    width: 28.25em;
    height: 37.688em;
    /* display: block; */
    /* margin: auto; */
    /* border: solid; */
}

.row {
    display: flex;
}

.form_group {
    /* display: block;
    float: left; */
    flex: 1;
    /* border: solid; */
    margin-bottom: 1em;
    margin-top: .5em;
    /* width: 23.813em; */
    margin-right: 5em;
}

.label {
    color: #283655;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%; /* 13.68px */
    display: block;
    padding-left: .5em;
    margin-top: 0.906em;
    margin-bottom: .3em;
    /* border: solid; */
}

.form_group > input[type = 'text'], .form_group > input[type = 'email'], .file, .select {
    border-radius: 10px;
    background: #F4F4F7;
    color: var(--text-2, #283655);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%; /* 13.68px */
    padding: 1.438em 1.371em;
    margin-bottom: .3em;
    border: none;
    width: 100%;
}


.file, .position {
    /* width: 95%; */
    border-radius: 10px;
    border: 1px dashed #C9C9C9;
    background: transparent;
}

input[type = 'file'] {
    display: none;
}

.form_group > label:hover {
    cursor: pointer;
}

.submit {
    /* clear: both; */
    display: block;
    border-radius: 5.626px;
    background: var(--SHE-COLOR-1, #EB2590);
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 17.514px;
    font-style: normal;
    font-weight: 400;
    line-height: 153.2%; /* 26.832px */
    text-transform: uppercase;
    height: 3.516em;
    width: 23.813em;
    border: none;
    margin-top: 3em;
    
}


@media (max-width: 1024px) {
    .img {
        display: none;
    }

    .title {
        margin: .5em .5em;
    }

    .sub_container {
        display: block;
        margin: auto 1em;
    }

    .row {
        display: block;
    }

    .form_group {
        margin-right: 0;
    }

    .submit {
        width: 20.188em;
        height: 3.128em;
    }
}