.container {
    border-top: solid 1px #EFEFEF;
    border-bottom: solid 1px #EFEFEF;
    max-width: 75em;
    margin: auto;
    padding: 1em;
}

.lang-selector {
    display: inline-block;
    /* border: solid; */
    width: 8.375em;
    margin-right: 30em;
}

.socials {
    /* border: solid; */
    float: right;
}

@media (max-width: 1024px) {
    .container {
        display: none;
        max-width: 20em;
    }
}