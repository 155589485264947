.container {
    padding: 4.213em 0;
    max-width: 75em;
    margin: auto;
    display: block;
}

.heading {
    display: flex;
    overflow: auto;
    /* border: solid; */
}

.title {
    /* float: left; */
    color: #450028;
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* border: solid; */
}

.deadline {
    /* float: right; */
    color: #8F8F8F;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* margin-top: 1em; */
    margin-left: auto;
    margin-right: 0;
    padding-top: .5em;
    /* border: solid; */
}

.text_body {
    color: #747474;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    word-break: keep-all;
    /* text-align: left; */
}


@media (max-width: 1024px) {
    .container {
        padding: 4.213em 1.5em;
    }

    .title, .deadline {
        display: flex;
        /* float: none; */
        /* text-align: center; */
    }

    .title {
        font-size: 21px;
    }

    .deadline {
        font-size: 12px;
    }

    .text_body {
        font-size: 18px;
        font-weight: 300;
        text-align: left;
        /* border: solid; */
    }

}