.container {
    display: none;
}


@media (max-width: 1024px) {
    .container {
        color: #949494;
        text-align: center;
        font-family: 'Lato', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 11px;
        border: 1px solid #CDCDCD;
        width: 8.375em;
        height: 2.513em;
        padding-top: .5em;
        margin: auto;
    }
    
}