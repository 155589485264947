.container {
    margin-top: 2.273em;
    background: #FFF6FB;
    padding: 5.08em 1em 8em;
    overflow: hidden;
}

.container > a:hover {
    text-decoration: none;
}

.title {
    text-align: center;
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.short_note {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
}

.articles_container {
    display: flex;
    max-width: 75em;
    gap: 1.5em;
    padding-top: 6.697em;
    margin: auto;
}

.top {
    padding: 1.983em 1.983em .483em;
}

.card_title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    float: left;
}

.date {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    float: right;
}

.bottom > img {
    margin-bottom: -.27em;
    margin-right: -.08em;
    display: block;
    width: 100%;
}

.article_link {
    clear: both;
    display: block;
    text-align: left;
    width: 19em;
    height: 8em;
    margin: auto;
    padding: 2em 0;
    word-break: keep-all;
}

.article_link > a {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.article_link > a:hover {
    color: #FF7733;
    text-decoration: none;
}

@media (max-width: 1024px) {

    .container {
        padding: 5.08em 1.534em 8.236em 1.323em;
        /* width: 18em; */
    }
    
    .title, .short_note {
        word-break: keep-all;
        font-size: 14.3px;
        /* border: solid; */
        /* width: 16em; */
    }

    .title {
        font-size: 35px;
        /* width: 8em; */
    }
    
    .articles_container {
        display: block;
        padding-top: 6.697em;
        /* width: 16em; */
        margin: auto;
    }

    .bottom > img {
        width: 100%;
    }

    .top {
        padding-left: 1em;
    }

    .article_link {
        width: auto;
        /* border: solid; */
        text-align: left;
    }
    
    .article_link > a {
        font-size: 18px;
    }

    .card_title {
        font-size: 13px;
    }
    
    .date {
        font-size: 15px;
        margin-top: -.09em;
    }

}
