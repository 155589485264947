.header-nav__ul {
    list-style: none;
    display: inline-block;
    /* border: solid 1px #000; */
    margin: 0;
    overflow: hidden;
    vertical-align: middle;
}

.header-nav__li {
    float: left;
    padding: .58em;
    /* border: solid 1px #000; */
    margin-right: 2.75em;
    text-decoration: none;
}

.header-nav__li > a, .header-nav__li > a:hover {
    text-decoration: none;
    color: #2C2C2C;
}

.header-nav__li:first {
    margin-left: 0;
}

.header-nav__li a {
    display: block;
    text-decoration: none;
    color: #2C2C2C;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.header-nav_reg > a {
    color: #EB2590;
    font-size: 17px;
    font-weight: bolder;
}



.header-nav__submenu {
    margin: 0;
    padding: 0;
    position: absolute;
    border-radius: 18px;
    background-color: #FFF;
    box-shadow: 4px 4px 4px 2px rgba(157, 157, 157, 0.25);
    z-index: 1;
    list-style: none;
    display: none;
    width: 23.125em;
    text-align: left;
    padding-top: 1em;
    margin-left: -1em;
    text-decoration: none;
}
.header-nav__li:hover .header-nav__submenu {
    display: block;
    text-decoration: none;
}
.header-nav__submenu > li {
    padding: 0.5em 1em 0.5em 1em;
}
.header-nav__submenu > li:hover{
    cursor: pointer;
    background-color: #f4f0f0;
    text-decoration: none;
}
.header-nav__submenu > li > a {
    color: #56002F;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    /* border: solid; */
}

.header-nav__submenu > li > a > p {
    color: #8F8F8F;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

a:hover {
    text-decoration: none;
    color: #2C2C2C;
}



@media (max-width: 1024px) {
    .header-nav__ul {
        list-style: none;
        display: block;
        margin: 0;
        overflow: hidden;
        padding: 0;
        background-color: #EB2590;
        /* border: solid 1px #000; */
        /* width: 100px; */
        
    }

    .header-nav__li {
        float: none;
        padding: 0.513em 1em;
        margin: auto;
        margin-left: 0;
        /* border: solid; */
    }

    .header-nav_reg {
        padding-top: 1.5em;
    }

   

    .header-nav__li a::after {
        content: url("/public/images/Arrow\ -\ Down\ 4.png");
        float: right;
        margin-top: .2em;
    }

    .up a::after {
        content: url("/public/images/Arrow\ -\ Down\ 4\ opp.png");
    }

    .header-nav__li a:last-child::after {
        content: none;
    }
    .header-nav__li a {
        margin-left: 0;
        color: #FFF;
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .header-nav__li a:active, .header-nav__li a:hover {
        text-decoration: none;
        color: #FFF;
    }

    .header-nav_reg > a {
        color: rgb(255, 182, 193);
        font-weight: bolder;
    }

    .header-nav__submenu {
        margin: 0;
        padding: 0;
        position: relative;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
        list-style: none;
        display: block;
        width: 100%;
        text-align: left;
        padding-top: 1em;
        margin-left: -1em;
        overflow: hidden;
        height: 0;
        transition: height .5s ease;
    }

   
    .header-nav__submenu > li:hover{
        cursor: pointer;
        background-color: transparent;
    }

    .header-nav__submenu > li {
        padding: 0.5em 0em 0.5em 1em;
    }

    .header-nav__submenu > li > a {
        font-family: 'Lato', sans-serif;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .header-nav__submenu > li > a > p {
        font-family: 'Lato', sans-serif;
        color: #FFB8E9;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

  

    .display {
        /* display: block !important; */
        height: 13em;
        
    }

}