.container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #fff;
    top: 0;
    z-index: 19999999;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .loader .dot {
    width: 20px;
    height: 20px;
    background-color: #EB2590;
    border-radius: 50%;
    margin: 0 5px;
    animation: bounce 1.1s ease infinite;
  }
  .loader .dot:nth-child(1) {
    animation-delay: 0s;
  }
  .loader .dot:nth-child(2) {
    animation-delay: 0.1s;
  }
  .loader .dot:nth-child(3) {
    animation-delay: 0.2s;
  }
  .loader .dot:nth-child(4) {
    animation-delay: 0.3s;
  }
  .loader .dot:nth-child(5) {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-60px);
    }
    60% {
      transform: translateY(25px);
    }
  }