.container {
    padding: 4.213em 0;
    max-width: 75em;
    margin: auto;
    display: block;
}

.title {
    color: #450028;
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ol {
    color: #747474;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    word-break: keep-all;
}


@media (max-width: 1024px) {
    .container {
        padding: 0;
        border-bottom: 1px solid #EFEFEF;
        margin: auto 1em 4em;
    }
    
    .title {
        font-size: 21px;
        margin: 0;
    }
    
    .ol {
        padding-top: 1em;
        font-size: 15px;
        font-weight: 300;
        height: 0;
        overflow: hidden;
        transition: height .5s linear;
    }

    .title::after {
        content: url("/public/images/Arrow\ -\ Down\ 2.png");
        float: right;
    }

    .down::after {
        content: url("/public/images/Arrow\ -\ Down\ 2\ opp.png");
    }
}