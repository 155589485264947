.container {
    margin-top: 2.273em;
    background: #FFF;
    padding: 5.08em 0 8.236em;
    max-width: 75em;
    margin: auto;
    display: block;
}

.pink {
    color: var(--Primary, #EB2590);
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 30.94px */
    text-transform: capitalize;
}

.title {
    color: #000;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 49px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 58.31px */
    text-transform: capitalize;
}

.short_note {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
}

.articles_container {
    display: flex;
    padding-top: 6.697em;
}

.top {
    padding: 1.983em 1.983em;
}

.card_title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    float: left;
}

.date {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    float: right;
}

.bottom > img {
    margin-bottom: -.27em;
    margin-right: -.08em;
   
}

.next_line {
    display: block;
}

.article_link {
    clear: both;
    display: block;
    text-align: left;
    width: 18em;
    height: 8.514em;
    margin: auto;
    padding: 3.251em 0;
    word-break: keep-all;
}

.article_link > a {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.article_link > a:hover {
    text-decoration: none;
    color: #FF7733;
}



@media (max-width: 1024px) {

    .container {
        padding: 5.08em 1.534em 8.236em 1.323em;
    }
    
    .pink {
        font-size: 19px;
    }

    .next_line {
        display: inline;
    }


    .title, .short_note {
        word-break: keep-all;
    }

    .title {
        font-size: 33px;
    }
    
    .articles_container {
        display: block;
        padding-top: 6.697em;
    }

    .bottom > img {
        width: 100%;
    }

    .article_link {
        width: auto;
        text-align: left;
    }

    .article_link > a {
        font-size: 23px;
        text-decoration: none;
    }

    

   

    .card_title, .date {
        font-size: 16px;
    }

}
