/* Gbenga's styles */
.user-layout {
    display: grid;
    grid-template-columns: 1fr;
    // background: #FFFFFF;
    // box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.05);
    box-sizing: border-box;
    box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
  }

  .container {
    max-width: 75em;
    margin-top: 4em;
    margin-bottom: 7em;
  }

  .manage-profile-header {
    display: flex;
    // border-bottom: 1px solid #ec008c;
    align-items: center;
    background: #fafafb;
    box-shadow: 0px 4px 16px rgba(31, 32, 65, 0.05);
    min-height: 80px;
    flex-flow: wrap;
    .personal-detailsX {
      min-height: 80px;
      // box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 15px;
      cursor: pointer;
    }

}

.activeX {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    border-bottom: 3px solid #f0008c;
    padding-left: 15px;
    cursor: pointer;
  }

  
  