.container {
    display: block;
    padding: 2.979em 0;
    margin: auto;
    max-width: 75em;
}

.position {
    display: block;
    border-radius: 23px;
    background: #F1F1F1;
    padding: 0.813em 2.188em;
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 1em;
    float: left;
}

.active {
    background: #EB2590; 
    color: #fff;  
}


@media (max-width: 1024px) {
    .container {
        padding: 2.979em 1em;
        /* border: solid; */
        text-align: center;
        display: flex;
        justify-content:center;
        flex-direction: row;
        /* flex-wrap: wrap; */
    }


    .position {
        font-size: 8.5px;
    }
  

}
