.container {
    /* margin-top: .375em; */
    background: #FFFBF2;
}
.sub_container {
    padding: 6.5em 1.3em;
    display: flex;
    flex-wrap: wrap;
    max-width: 77em;
    margin: auto;
}

.text_container {
    flex: 1;
}

.img_container {
    flex: .5;
}

.img {
    border-radius: 250px 250px 0 0;
    margin: auto;
    display: block;
    height: 35em;
}

.her {
    color: #EB2590;
    font-family: 'Lato', sans-serif;
    font-size: 59px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 59px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    word-break: keep-all;
}

.next_line {
    display: block;
}

.testimony__container {
    width: 35.063em;
    height: 16.938em;
    border-radius: 90px 0px;
    background: #FFF;
    backdrop-filter: blur(2px);
    padding-left: 1.375em;
    margin-top: 4em;
    margin-bottom: 2em;
}

.testifier {
    background: linear-gradient(92deg, #EB2590 33.09%, #F73 88.68%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Playfair Display', serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: inline-block;
    margin-right: 3.844em;
    margin-bottom: 0;
    margin-left: 1.844em;
}

.testifier__position {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
    margin-left: 9.844em;
    margin-bottom: 0;
}

.testimony {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
    /* border: solid; */
    display: block;
    margin: auto;
    padding-left: 1.563em;
    padding-right: 1.563em;
    margin-top: 0.938em;
    word-break: keep-all;
}

.link_p {
    flex: 0 0 100%;
    margin-left: 2em;
}

.link_a {
    color: var(--Secondary, #F73);
    font-family: 'Lato', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.link_a:hover {
    text-decoration: none;
    color: var(--Secondary, #F73);
}

.link_p::after {
    content: url("/public/images/Arrow_right.png");
    margin-left: 1.2em;
    vertical-align: -.1em;
}

@media (max-width: 1024px) {
    .container {
        display: block;
        height: 80em;
    }
    
    .sub_container {
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 6.5em 0;
    }

    .img {
        width: 17em;
        height: 24.082em;
        display: block;
        margin: auto;
        margin-top: 8em;
    }

    .testimony__container {
        width: 92%;
        height: 14em;
        display: block;
        margin: auto;
        padding: 1em 0;
        margin-top: 5em;
    }

    .testifier {
        font-size: 18px;
        /* display: block; */
        margin: auto;
        text-align: center;
        margin-top: 2em;
        margin-left: 1em;
    }

    .testifier__position {
        font-size: 11px;
        /* display: block; */
        margin: auto;
        text-align: center;
        margin-top: 3.8em;
        /* border: solid; */
        margin-right: 1em;
        float: right;
    }

    .testimony {
        font-size: 15px;
        display: block;
        margin: auto;
        padding-left: 1em;
        padding-right: 1.7em;
        margin-top: 0.938em;
        word-break: keep-all;
        text-align: left;
    }

    .link_p {
        text-align: left;
        margin-top: -31em;
    }
    
    .link_a {
        font-size: 15px;
    }

    .title, .her {
        text-align: center;
        font-size: 31px;
    }
}