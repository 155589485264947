.btn {
    border-radius: 12px;
    border: 1px solid var(--Primary, #EB2590);
    background: #EB2590;
    width: 256.168px;
    height: 54px;
    flex-shrink: 0;
    margin-left: 3.5em;
    margin: auto;
    padding: 1em;
    text-align: center;
    margin-bottom: 8em;
}

.btn > a {
    color: #FFF;
    font-family: Lato;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.btn > a:hover {
    cursor: pointer;
}
