.container {
    position: relative;
    background: #FFF5E0;
    padding: 8.438em 3.75em;
    z-index: 2;
}

.top_container {
    display: flex;
    margin: auto;
    max-width: 75em;
}

.text_container, .img_container {
    flex: 1;
}

.text_container {
    border-radius: 23px 0px 0px 23px;
    /* opacity: 0.95; */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url("/public/images/pattern_white.png"), lightgray 50% / cover no-repeat;
    background-color: #ff7733;
    background-blend-mode: soft-light;
    padding: 6.75em 5.25em;
    
}

.img_container {
    background: url("/public/images/inc_person.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0px 23px 23px 0px;
}

.title {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 47px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.text_body {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 29px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.link > a {
    color: #FFD786;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.link::after {
    content: url("/public/images/right_orange.png");
    vertical-align: middle;
    margin-left: 1em;
}

.bottom_container {
    display: flex;
    margin: auto;
    max-width: 75em;
}

.curriculum, .alumni_base {
    flex: 1;
    padding: 7.5em 5.25em;
    /* border: solid; */
}

.bottom_title {
    color: var(--Secondary, #F73);
    /* text-align: center; */
    font-family: Lato;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 44.03px */
    text-transform: capitalize;
}

.curriculum > .bottom_title::before {
    content: url("/public/images/curriculum.png");
    margin-right: 1em;
}

.alumni_base > .bottom_title::before {
    content: url("/public/images/alumni_base.png");
    margin-right: 1em;
}

.bottom_body {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.tab {
    display: none;
}


@media (max-width: 1024px) { 
    .container {
        padding: 8.438em 1em 5.438em;
    }

    .title {
        font-size: 28px;
    }

    .text_body {
        font-size: 20px;
    }

    .top_container {
        flex-direction: column;
    }

    .text_container {
        /* padding: 1.75em 5.25em 1.75em; */
        border-radius: 23px 23px 0px 0px;
        /* border: solid; */
    }

    .img_container {
        min-height: 21.625em;
        border-radius: 0 0 23px 23px;
    }
    
    .text_container {
        padding: 6.75em 1em;
        
    }

    .link > a {
        font-size: 17px;
    }
    
    .link::after {
        margin-left: 1em;
    }

    .bottom_container {
        display: flex;
        overflow: hidden;
        transition: margin-left .5s linear;
    }
    
    .curriculum, .alumni_base {
        display: block;
        padding: 3.5em 0;
        /* border: solid; */
    }

    .bottom_title {
        font-size: 33px;
    }

    .tab {
        border-radius: 20px;
        background: #ECECEC;
        display: block;
        margin: auto;
        /* width: 19.625em; */
        height: 2.538em;
        padding: .65em 0;
        text-align: center;
        display: flex;
        margin-top: 3em;
    }

    .tab1 {
        flex: 1;
        color: #545454;
        text-align: center;
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 119%; /* 20.23px */
        text-transform: capitalize;
    }

    .tab2 {
        flex: 1;
        color: #545454;
        text-align: center;
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 119%; /* 20.23px */
        text-transform: capitalize;
    }

    .active {
        background: var(--Secondary, #F73);;
        color: #FFF;
        border-radius: 20px;
        width: 19.625em;
        height: 2.538em;
        padding: .65em 0;
        margin: -.7em 0;
    }

    .bottom_title {
        display: none;
    }

    .curriculum, .alumni_base {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 15em;
        /* border: solid; */
        gap: 1em;
        min-width: 90vw;
        max-width: 90vw;
        /* margin-right: 1em; */
    }
    
    .alumni_base {
        margin-left: 2em;
    }

    .bottom_body {
        font-size: 15px;
        flex: 1;
    }

    .curriculum > .bottom_logo {
        flex: .1;
        content: url("/public/images/curriculum.png");
    }

    .alumni_base > .bottom_logo {
        flex: .1;
        content: url("/public/images/alumni_base.png");
    }
}