.container{
    background: #FFF8FD;
    padding-bottom: 4em;
    margin-bottom: 4em;
}

.testimonial__container {
    background: radial-gradient(50% 50% at 50% 50%, rgba(245, 152, 216, 0.40) 0%, rgba(217, 217, 217, 0.00) 100%);
    border-radius: 34px;
    display: flex;
    gap: 5em;
    padding-bottom: 4.875em;
    margin: auto;
    max-width: 75em;
    margin-bottom: 4em;
}
.left_pane {
    flex: 1;
    padding: 15.125em 0 0;
    overflow: hidden;

}
.right_pane {
    flex: 1;
    padding: 12.938em 0 0;
    overflow: hidden;
    /* margin-bottom: 3em; */
}

.right__pic {
    padding: 0 4.5em;
    flex: 1;
    width: 35.063em;
    height: 45.313em;
    border-radius: 0px 45px 0px 0px;
    display: block;
    margin: auto;
    margin-right: 0;
    background: url("/public/images/Pelumi Ola.jpg");
    padding-top: 30em;
}
.left__pic {
    flex: 1;
    padding: 0 4.5em;
    width: 35.063em;
    height: 45.441em;
    background: url("/public/images/Doris Omoyeni.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 28em;
    /* border: solid; */
}



.pink {
    color: #EB2590;
    font-family: 'Playfair Display', serif;
    font-size: 63px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.tagline {
    color: #949494;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

.title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: .5em;
}

.next_line {
    display: block;
}

.right_pane > p {
    color: #000;
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: auto;
    display: block;
    /* border: solid; */
    max-width: 30.063em;
    margin-top: 2.688em;
}

.right_pane > .link {
    margin-top: 0;
}
.right_pane > .link > a {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.testimony__container {
    width: 26.563em;
    /* height: 11.188em; */
    height: fit-content;
    border-radius: 32px 0px;
    background-color: #FFF;
    padding: .5em 1.375em;
    display: block;
    margin: auto;
    /* border: solid; */
}

.heading {
    display: flex;
    /* border: solid; */
    padding: 0;
}


.testifier {
    flex: 1;
    background: linear-gradient(#EB2590 27.79%, #F73 76.01%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Playfair Display', serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: block;
    margin-bottom: 0;
    /* border: solid; */
}

.right__pic > .testimony__container > .heading > .testifier {
    text-align: right;
}

.testifier__position {
    flex: 1;
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin-bottom: 0;
    display: block;
    margin-top: 1em;
    text-align: right;
    /* border: solid; */
}

.right__pic > .testimony__container > .heading > .testifier__position {
    text-align: left;
    /* border: solid; */
}


.testimony {
    clear: both;
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: justify;
    /* border: solid; */
    display: block;
    margin: auto;
    margin-top: 0.938em;
    padding: 0 1em;
}

.navigators {
    display: flex;
    /* border: solid; */
    margin: 4em auto;
    width: 13em;
    justify-content: center;
}

.nav {
    /* display: inline-block; */
    vertical-align: middle;
}

.nav:hover {
    cursor: pointer;
}

.more {
    margin: auto 2em;
    display: inline-block;
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (max-width: 1024px) {

    .container {
        padding-bottom: 8em;
    }

    .testimonial__container {
        display: flex;
        /* border: solid; */
        padding-top: 4em;
        flex-direction: column;
        margin-bottom: 0;
        padding-bottom: 1.5em;
    }

    .left_pane {
        display: block;
        /* padding: 0 0 0 1em; */
        /* border: solid; */
        max-width: 22em;
        min-width: 22em;
        margin: auto;
        margin-right: 0;
        /* padding: 1em 0; */
    }
    
    .right_pane {
        display: block;
        padding: 0;
        margin-bottom: 0;
        max-width: 22em;
        min-width: 22em;
        /* border: solid; */
    }
    
    .right__pic {
        padding: 0;
        max-width: 22em;
        min-width: 22em;
        height: 42em;
        margin: auto;
        margin-left: 0;
        display: block;
        border-radius: 0;
        /* box-shadow: 10px 10px 10px #aaaaaa; */
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        padding-top: 28em;
        background-size: cover;
        /* margin-right: 2.4em; */
    }

    .left__pic {
        padding: 0;
        max-width: 22em;
        min-width: 22em;
        height: 42em;
        margin: auto;
        margin-right: 0;
        display: block;
        margin-bottom: 0;
        /* box-shadow: 10px 10px 10px #aaaaaa; */
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        padding-top: 23em;
        /* padding-bottom: auto; */
        margin-bottom: 2em;
        
    }
    
    .testimony__container {
        width: 18.57em;
        height: auto;
        margin: auto;
        padding: 1em 1.2em 1.5em;
        display: block;
        /* border: solid; */
        /* box-shadow: 10px 10px 10px #aaaaaa; */
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border-radius: 0 0 32px 32px;
        /* margin-bottom: 2em; */
    }
    
    .testifier {
        display: block;
        float: left;
        margin-right: 0;
        margin-bottom: 0;
        font-size: 22px;
    }
  
    .testifier__position {
        display: block;
        float: right;
        margin: 1.1em 1em 0 0;
        font-size: 10px;
    }

    
    .testimony {
        word-break: keep-all;
        /* border: solid; */
        /* width: 14em; */
        display: block;
        margin: auto;
        clear: both;
        padding: .7em 0 0;
        font-size: 16px;
        text-align: left;
    }
    
    .right_pane > p {
        /* border: solid; */
        width: 15em;
        margin: auto;
        margin-top: 2.688em;
        word-break: keep-all;
        display: none;
    }
    
    .other {
       display: block;
        margin: auto;
        padding-left: 0.3em;
        padding: 0 0.6em;
        margin-top: .2em;
        margin-bottom: .3em;
        word-break: keep-all;
    }
    
    .voices {
        display: block;
        margin: auto;
        padding-left: 0.3em;
        margin-top: 1em;
        padding: 0 0.6em;
        word-break: keep-all;
     }

     .tagline {
        font-size: 13px;
     }

     .title {
        font-size: 36px;
        max-width: 6.5em;
     }

     .pink {
        font-size: 43px;
     }
    
     .next_line {
        display: inline;
     }

     .navigators {
        margin: 3em auto;
     }
}