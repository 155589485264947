.container {
    background: #FFE7F4;
    padding: 11.125em 0 3.688em;
    /* border: solid; */
}

.title {
    color: #111;
    font-family: 'Lato', sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    /* border: solid; */
}

.search {
    border-radius: 9px;
    width: 35em;
    height: 4em;
    color: #8A8A8A;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    margin: auto;
    background: url("/public/images/search_icon.png") no-repeat 13px center;
    background-color: #FFF;
    padding-left: 3em;
    border: none;
}

@media (max-width: 1024px) {

    .container {
        background: #FFE7F4;
        padding: 2em 0 3.688em;
    }
    

    .title {
        font-size: 21px;
        word-break: keep-all;
    }

    .search {
        width: 90vw;
        font-size: 12px;
        padding-left: 4em;
    }
}