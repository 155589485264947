.container {
    padding: 8.215em 0 1.367em;
    max-width: 75em;
    margin: auto;
}

.values_title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.define {
    color: var(--Primary, #EB2590);
    font-family: 'Playfair Display', serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.side_note {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1.875em;
    margin-bottom: 2em;
}

.blocks_container {
    /* display: flex; */
    display: block;
    padding-top: 3.5em;
    /* border: solid; */
    width: 75em;
    margin: auto;
}

.part {
    display: flex;
    flex-wrap: wrap;
}

.block1, .block2, .block3, .block4, .block5, .block6 {
    border-radius: 31px;
    background: #FFE1F6;
    /* float: left; */
    /* display: inline-block; */
    margin-left: 1.375em;
    margin-bottom: 4.188em;
    padding: 4.625em 1.813em 4.625em 2.813em;
    flex: 30%;
}

.block2 {
    background: #F2FFF7;
}

.block3 {
    background: #F4F4F4;
}

.block4 {
    background: #FFEDE4;
}

.block5 {
    background: #D9D9D9;
}

.block6 {
    background: #FFE1F6;
}

.block_title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.block_body {
    color: #5D5D5D;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 .2em;
}


@media (max-width: 1024px) {
    .container {
        /* padding: 17.215em 3.625em 10.367em; */
        padding: 0 1em;
    }

    .values_title {
        width: auto;
        font-size: 42px;
        display: block;
        margin: auto;
        margin-top: 2em;
        word-break: keep-all;
        /* border: solid; */
        text-align: left;
    }
    .define {
        font-size: 42px;
    }

    .part {
        display: flex;
        overflow: hidden;
        gap: 1em;
        flex-wrap: nowrap;
        /* padding-right: 1em; */
    }
    
    .blocks_container {
        /* display: flex; */
        display: block;
        padding: 0;
        padding-top: 3.5em;
        /* border: solid; */
        /* width: 18em; */
        margin: auto;
        width: auto;
        overflow: hidden;
        scroll-behavior: smooth;
        transition: margin-left .2s linear;
    }

    .block1, .block2, .block3, .block4, .block5, .block6 {
        margin-left: 0;
        padding: 4.625em 1.813em 4.625em 1.813em;
        /* padding: 0; */
        display: block;
        min-width: 15.75em;
        max-width: 15.75em;
        height: 19.5em;
        flex: 1;
        /* border: solid; */
    }

    .block_body {
        word-break: keep-all;
        font-size: 14px;
        margin: auto;
        display: block;
    }

    .block_title {
        font-size: 16px;
    }

    .side_note {
        display: block;
        width: auto;
        margin: auto;
        margin-top: 1em;
        word-break: keep-all;
        font-size: 16px;
    }
}