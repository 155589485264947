.call_to_action {
    width: 178px;
    height: 54px;
    border-radius: 16px;
    border: 1px solid var(--Primary, #EB2590);
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: #fff;
}

.call_to_action:hover {
    background-color: var(--Primary, #EB2590);
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

