.container {
    padding: 2.503em 0 8.75em;
}

.title {
    color: #EB2590;
    font-family: 'Lato', sans-serif;
    font-size: 59px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* padding-left: .5em; */
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: auto;
    width: 7em;
    /* border: solid; */
}

.small {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* padding-left: 1.5em; */
    margin-left: 0;
    margin-right: auto;
    width: 23em;
}

.sub_container {
    display: flex;
    margin: auto;
    max-width: 75em;
    padding-top: 2.988em;
    /* border: solid; */
}
.img-partition {
    flex: 1;
    padding-left: 5.385em;
}

.img-partition > img {
    display: block;
    margin-left: auto;
    margin-right: 0;
}

.text-partition {
    flex: 1;
    padding: 2.628em 0;
}

.quote {
    color: #F598D8;
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.name {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.action_text {
    color: #3A001F;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5.93em;
    text-align: center;
}

.btn_container{
    display: block;
    /* border: solid; */
    width: 11.825em;
    margin: auto;
}

@media (max-width: 1024px) {
    .container {
        /* padding: 12.503em 0 3.75em; */
        padding: 0 .6em;
    }

    .sub_container {
        display: block;
        width: auto;
    }
    .img-partition {
        display: block;
        padding-left: 0;
    }
    .img-partition > img {
        width: 16em;
        display: block;
        margin: auto;
    }
    .text-partition {
        display: block;
        padding: 1em 0;
    }
    .quote, .name {
        padding: 0 .8em;
        word-break: keep-all;
    }
    .quote {
        font-size: 30px;
    }
    .name {
        text-align: center;
    }
    .title, .small {
        padding: 0;
        font-size: 16px;
        margin: auto;
        
        display: block;
    }

    .title {
        font-size: 42px;
        margin-top: 2em;
        text-align: center;
        width: auto;
        max-width: 8em;
        /* margin-top: 2em; */
        /* border: solid; */
    }

    .small {
        margin-top: 3em;
        margin-bottom: 3em;
        /* border: solid; */
        width: auto;
        /* max-width: 22em; */
        text-align: center;
        padding: .8em;
    }

    .action_text {
        word-break: keep-all;
    }
}