.footer-nav__container {
    /* float: left; */
    flex: 1;
    /* padding: 0 2.65em; */
    /* border: solid 1px;     */
}


.footer-nav__ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
   
    /* border: solid; */
}

.footer-nav__li {
    margin-bottom: 1.2em;
    /* border: solid; */
    width: 10em;
    
}
.footer-nav__li a {
    text-decoration: none;
    color: #5B5B5B;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footer-nav__title {
    margin: 0;
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1.4375em;
}

.footer-nav__li > span {
    color: #5B5B5B;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footer-nav__address-title {
    display: block;
    font-weight: bolder;
    margin-bottom: .7em;

}

.footer-nav__address {
    color: #5B5B5B;
}

.br {
    display: block;
}

@media (max-width: 1024px) {
    .footer-nav__container {
        float: none;
        padding: 0 0.875em;
        /* border: solid 1px;     */
        /* width: 100%; */
        /* border: solid; */
    }
    .footer-nav__title {
        color: #000;
        cursor: pointer;
        padding: 1.125em 0 0; 
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
    }
    
    .footer-nav__title::after {
        content: url("/public/images/Arrow\ -\ Down\ 2.png");
        float: right;
    }
    
    .down::after {
        content: url("/public/images/Arrow\ -\ Down\ 2\ opp.png");
    }
    
    .footer-nav__ul {
        padding: 0 18px;
        /* display: none; */
        overflow: hidden;
        list-style: none;
        border-bottom: solid 1px #EFEFEF;
        height: 0;
        transition: height .5s ease;
        /* border: solid; */

    }
    
    .footer-nav__li {
        margin-bottom: 0.625em;
        padding: 1em 0;
        /* border: solid; */
    }
    .footer-nav__li a {
        text-decoration: none;
        color: #5B5B5B;
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    

    
    .footer-nav__address-title {
        display: block;
        font-weight: bolder;
        margin-bottom: .7em;
    }
    
    .footer-nav__address {
        min-width: 80vw;  
        color: #5B5B5B;
        /* border: solid;   */
        /* font-weight: 100; */
    }

    .br {
        display: inline;
    }

    .display-block {
        /* display: block !important;
         */

         height: 25em;

         /* border: solid; */
    }
    
}

