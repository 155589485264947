.container {
    display: flex;
    border-radius: 23px;
    margin: 7.438em auto;
    max-width: 75em;
    background-image:  linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url("/public/images/accelerator_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.text_container, .img_container {
    flex: 1;
}

.text_container {
    padding: 11.5em 0 3.875em 4em;
}

.img_container {
    padding-top: 8.5em;
}

.signup_container {
    display: flex;
    margin: 7.438em auto;
    max-width: 75em;
    /* border: solid; */
}

.title {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 39px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.img {
    display: block;
    /* margin-left: auto; */
    /* margin-right: 0; */
    /* border: solid; */
    /* margin-bottom: 0;
    margin-top: 8em; */
    
}



.partner {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 39px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.note {
    color: #A0A0A0;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.next_line {
    display: block;
}

.accelerator, .incubator {
    padding: 0 4.813em;
    /* border: solid; */
}

.accelerator > .signup_title::before {
    content: url("/public/images/accelerator_logo.png");
    margin-right: 1em;
}

.incubator > .signup_title::before {
    content: url("/public/images/incubator_logo.png");
    margin-right: 1em;
}

.accelerator > .signup_logo {
   display: none;
}

.incubator > .signup_logo {
   display: none;
}

.signup_title {
    color: var(--Footer-Background, #141414);
    /* text-align: center; */
    font-family: 'Lato', sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 54.74px */
    text-transform: capitalize;
}

.text_body {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: keep-all;
    text-align: justify;
}

.link {
    color: var(--Footer-Background, #141414);
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
    margin-top: 2em;
}

.link > a {
    color: var(--Footer-Background, #141414);
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2em;
}

.link > a:hover {
    text-decoration: none;
    color: var(--Footer-Background, #141414);
}

.link::after {
    content: url("/public/images/Arrow\ -\ Left.png");
    vertical-align: -.25em;
    margin-left: 2em;
}

.tab {
    display: none;
}

@media (max-width: 1024px) {
    .container {
        display: flex;
        background: #000;
        margin: 7.438em 1em 2em;
        background-image:  linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url("/public/images/accelerator_incubator_mobile.png");
        height: 42.063em;
        flex-direction: column;
    }
    
    .text_container {
        padding: 6.604em 1em .5em;

    }

    .next_line {
        display: inline;
    }

    .title, .partner {
        font-size: 25px;
    }

    .note {
        font-size: 15px;
    }

    .img_container {
        padding-top: 0;
        /* border: solid; */
    }

    .img {
        width: 15em;
        height: 15em;
        display: block;
        margin: auto;
        /* border: solid; */
    }

 

    .accelerator, .incubator {
        flex: 1;
        padding: 0 1em 0 0;
        margin: 1em 1em 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* flex-wrap: wrap; */
        height: 13em;
        /* margin-left: -500vw; */
        /* flex: 100%; */
        min-width: 100vw;
        /* border: solid; */
        /* display: none; */
        /* opacity: 0; */
        /* position: absolute; */
        
    }


    .signup_container {
        margin: 2.438em 1em;
        display: flex;
        overflow: hidden;
        /* flex-wrap: wrap; */
        transition: margin-left .5s linear;
        scroll-behavior: smooth;
    }

    .signup_title {
        font-size: 30px;
        margin-top: 2em;
    }

    .link {
        display: block;
        text-align: left;
        /* max-width: 70%; */
        margin: auto;
        /* border: solid; */
        font-size: 12px;
        margin-top: 1.5em;
    }
    
    .link::after {
        content: url("/public/images/Arrow\ -\ Left.png");
        vertical-align: -.48em;
        margin-left: .6em;
    }

    .tab {
        border-radius: 20px;
        background: #ECECEC;
        display: block;
        margin: auto 1em;
        /* width: 17.625em; */
        height: 2.538em;
        padding: .65em 0;
        text-align: center;
        display: flex;
        /* margin-top: 10em; */
    }

    .tab1 {
        flex: 1;
        color: #545454;
        text-align: center;
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 119%; /* 20.23px */
        text-transform: capitalize;
    }

    .tab2 {
        flex: 1;
        color: #545454;
        text-align: center;
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 119%; /* 20.23px */
        text-transform: capitalize;
    }

    .active {
        background: #848484;
        color: #FFF;
        border-radius: 20px;
        width: 19.625em;
        height: 2.538em;
        padding: .65em 0;
        margin: -.7em 0;
    }

    .signup_title {
        display: none;
    }

    .accelerator > .signup_logo {
        flex: .1;
        display: block;
        content: url("/public/images/accelerator_logo.png");
        margin-right: 1em;
    }
    
    .incubator > .signup_logo {
        flex: .1;
        display: block;
        content: url("/public/images/incubator_logo.png");
        margin-right: 1em;
    }

    .text_body {
        flex: 1;
        color: var(--Footer-Dark, #747474);
        font-family: 'Lato', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        /* max-width: 0vw; */
        padding-right: 1em;
        /* border: solid; */
    }

    
    /* .signup_container {
        overflow: hidden;
    } */

   
}


