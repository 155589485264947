.container {
    background: #FFE7F4;
    padding: 8.125em 0 3.517em;
    display: block;
    margin: auto;
    margin-bottom: 3em;
}

.title {
    color: var(--Primary, #EB2590);
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.date_updated {
    color: #000;
    font-family: 'Playfair Display', serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 20.23px */
    text-transform: capitalize;
    text-align: center;
}


@media (max-width: 1024px) {
    .title {
        font-size: 36px;
    }

    .date_updated {
        font-size: 13px;
    }
}