.container {
    padding: 10.563em 9.875em;
    position: relative;
    display: block;
    margin: auto;
    max-width: 75em;
    /* border: solid; */
}

.quote {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    max-width: 26.875em;
    overflow: auto;
}

.highlight {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.quoter {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    clear: both;
}

@media (max-width: 1024px) { 
    .container {
        padding: 10.563em 1em;
    }
    
    .quote, .highlight {
        margin-top: -6em;
        font-size: 20px;
    }

    .quoter {
        font-size: 19px;
    }
}