.container {
    padding: 2.938em 4.438em 5em;
    /* border: solid; */
    max-width: 85em;
    display: block;
    margin: auto;
}

.title {
    color: #F73;
    font-family: 'Lato', sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 83.3px */
    text-transform: capitalize;
    padding-left: 1em;
    word-break: keep-all;
    
}

.next_line {
    display: block;
}
.steps__container {
    display: flex;
}

.one, .two, .three {
    flex: 1;
    /* border: solid; */
}

.step_one, .step_two, .step_three {
    border-radius: 200px 200px 0px 0px;
    padding: 1.938em 0em 0; 
    width: 22.75em;
    height: 29.625em;
    margin: auto;
}
.step_one {
    background-image: linear-gradient(to bottom,  rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url("/public/images/step_one.png");
}
.step_two {
    background-image: linear-gradient(to bottom,  rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url("/public/images/step_two.png");
}
.step_three {
    background-image: linear-gradient(to bottom,  rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url("/public/images/step_three.png");
}

.step__number {
    margin-top: 5.5em;
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 119%; /* 38.08px */
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 0;
}

.step__title {
    color: #F73;
    font-family: 'Lato', sans-serif;
    font-size: 58px;
    font-style: normal;
    font-weight: 900;
    line-height: 119%; /* 69.02px */
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 1.938em;
    margin-top: .1em;
}

.step_two > .step__title {
    color: var(--P2, #F598D8);
}

.step_three > .step__title {
    color: #3EBA73;
}

.step__link > a {
    color: #FFF;
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 119%; /* 23.8px */
    text-transform: capitalize;
}

.step__link::after {
    content: url("/public/images/Arrow_right.png");
    margin-left: 1em;
    vertical-align: -.1em;
}

.step__link {
    text-align: center;
}

.step__link> a:hover {
    text-decoration: underline;
    color: #fff;
}

@media (max-width: 1024px) {
    .container {
        padding: 2.938em 1em;
    }    

    .title {
        font-size: 60px;
        padding-left: .1em;
        text-align: left;
        font-size: 25px;
    }

    .steps__container {
        margin-top: 4em;
        overflow: hidden;
        gap: 2em;
        scroll-behavior: smooth;
        transition: margin-left .2s linear;
    }
    
    /* .one, .two, .three {
        display: block;
        border: solid;
    } */

    .step_one, .step_two, .step_three {
        width: 13.625em;
        height: 18.938em;
        margin-top: 1em;
    }

    .step__number {
        margin-top: 4em;
        font-size: 20px;
    }

    .step__title {
        font-size: 39px;
    }

    .step__link > a {
        font-size: 12px;
    }

    .step__link::after {
        vertical-align: -.2em;
    }

    .step_one {
        background-size: cover;
    }
}