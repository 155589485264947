.container {
    display: block;
    padding: 7.136em 4.375em;
    /* border: solid; */
    margin-bottom: 3.625em;
}

.join_container {
    border-radius: 20px;
    background: #FFD7C3;
    display: flex;
    margin: auto;
    padding: 2.473em;
    max-width: 75em;
}

.join_container > img, .letterings {
    flex: 1;
}

.word-one {
    color: #3B3B3B;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.word-two {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 8em;
    word-break: keep-all;
    text-align: left;
}
.word-two > span {
    color: var(--Secondary, #F73);
    font-family: Playfair Display;
    font-size: 46px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}
.letterings {
    padding: 8.149em 2.289em 8.624em;
}
p > .link {
    color: #F73;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 119%; /* 23.8px */
    text-transform: capitalize;
}

p > .link:hover {
    color: #F73;
}
.link::after {
    content: url("/public/images/Arrow_right.png");
    vertical-align: middle;
    margin-left: 1em;
}

/* .img2 {
    display: none;
} */

@media (max-width: 1024px) {
    .container {
        padding: 0 1em;
        /* border: solid; */
        /* width: 19em; */
        margin: 3em auto;
    }

    .join_container {
        display: flex;
        padding: 0 0 2em;
        /* margin: auto; */
        flex-direction: column-reverse;
    }

    .join_container > img, .letterings {
        display: block;
    }

    .join_container > img {
        width: 90%;
        margin: auto;
    }

    .word-one, .word-two {
        word-break: keep-all;
        text-align: center;
        width: auto;
    }

    .word-two, .word-two > span {
       font-size: 30px;
    }
    
    .word-one {
        text-align: center;
        font-size: 13px;
        width: 20em;
        display: block;
        margin: auto;
        margin-bottom: 1em;
        /* border: solid; */
    }

    .letterings {
        padding: 4.149em 1.289em 4.624em;
        text-align: center;
        display: block;
        margin: auto;
    }
/* 
    .img1 {
        display: none;
    } */
}