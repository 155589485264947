.container {
    background: #000;
    padding: 3.609em 3.754em 4.253em;
    margin-top: 7.625em;
}

.tagline {
    color: #535353;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 60em;
    margin: auto;
    display: block;
    margin-bottom: 1em;
    /* border: solid; */
}

.title {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 76.16px */
    text-transform: capitalize;
    max-width: 18.8em;
    margin: auto;
    display: block;
    /* border: solid; */
}

@media (max-width: 1024px) {
    .container {
        padding: 3.609em 1em 4.253em;
    }

    .tagline {
        font-size: 16px;
    }

    .title {
        font-size: 51px;
    }
}