.card_container {
    /* width: 363.697px;
    height: 467.445px; */
    /* flex-shrink: 0; */
    border-radius: 27px 27px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 3px rgba(220, 220, 220, 0.25);
    margin: auto;
    padding: 0;
}

@media (max-width: 1024px) {
    .card_container {
        margin-top: 2em;
    }
}