.container {
    /* padding: 8em 0 0 0; */
    background-image: url('/public/images/collaboration.png'), linear-gradient(0deg, rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70));
    padding: 8.593em 0 1.736em 0;
    background-size: cover;
    background-blend-mode: overlay;
    background-repeat: no-repeat;
}
.img-container {
    margin: auto;
    max-width: 75em;
    height: 38.188em;
}
.overlay { 
    display: block;
    width: 100%;
    height: 100%; 
    background-color: rgba(0,0,0,0.5);
}

.title {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 69px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    word-break: keep-all;
    width: 15em;
    margin-top: 2em;
    margin-bottom: 1em;
}

.learning {
    color: #EB2590;
    font-family: 'Lato', sans-serif;
    font-size: 69px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.cohort {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
    padding-left: 2.417em;
}

@media (max-width: 1024px) {

    .container {
        background-image: url('/public/images/collabo_mobile.png'), linear-gradient(0deg, rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70));
        background-size: cover;
        padding: 4.593em 0 1.736em 0;
        margin-top: 6em;
        border: solid;
        /* height: 30em; */
    }
  
    .img-container {
        background-image: none;
        /* padding: 10.593em 0.6em 4.736em 0.6em; */
        /* background-color: rgb(0, 0, 0); */
        padding: 6.593em 1em 2.736em;
    }
    
    .title {
        width: auto;
        display: block;
        margin: auto;
        margin-bottom: 1em;
        font-size: 42px;
        word-break: keep-all;
    }

    .learning {
        font-size: 42px;
    }

    .cohort {
        display: none;
        margin: auto;
        /* border: solid; */
        width: 7em;
        margin-top: 1em;
        padding-left: 0;
    }
}