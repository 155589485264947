.footer {
    bottom: 0;
    display: flex;
    /* border: solid; */
    clear: both;
    overflow: auto;
    padding: 2em 0 2em;
    margin: auto;
    max-width: 75em;
}


.logo {
    width: 151.004px;
    /* margin: auto; */
    display: block;
}

.img_container {
    /* display: inline-block; */
    /* vertical-align: baseline; */
    margin-top: .2em;
    /* border: solid 1px #000; */
    flex: 1;
    
}
.footer_nav {
    flex: 4;
    /* margin-right: 5.875em; */
    /* border: solid; */
    padding: 0 0 0 .8em;
    display: flex;
    /* gap: .6em; */
    
}


@media (max-width: 1024px) {
    .footer {
        bottom: 0;
        display: block;
        /* border: solid; */
        clear: none;
        overflow: auto;
        padding: 1em 0 0 .5em;
        /* max-width: 20em; */
    }
    .logo {
        width: 151.004px;
        display: block;
        /* vertical-align: baseline; */
        margin-left: .5em;
        margin-top: 7.5em;
        margin-bottom: 3.4375em;
        /* border: solid 1px #000; */
        float: none;
    }
    .footer_nav {
        display: block;
        float: none;
        margin-right: 0;
        /* border: solid; */
        padding: 0;
    }
    .img_container {
        /* display: inline-block; */
        /* vertical-align: baseline; */
        margin-top: .2em;
        /* border: solid 1px #000; */
        float: none;
    }
}