.title {
    color: #3A001F;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 51px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 3em;
}

.next_line {
    display: block;
}

@media (max-width: 1024px) {
    .title {
        font-size: 33px;
    }
}