.space {
    flex: 30%;
    /* border: solid; */
    margin-top: 3em;
}

.space_title {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 21.41px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 25.478px */
    text-transform: capitalize;
    /* border: solid; */
    width: 17em;
    margin: auto;
    margin-top: 1.5em;
}

.space_title::after {
    content: url("/public/images/Arrow\ -\ Right\ 2.png");
    float: right;
    margin-right: 2em;
}

.space_description {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 17.41px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 20.718px */
    text-transform: capitalize;
    /* border: solid; */
    width: 21em;
    margin: auto;
    margin-top: 1.5em;
    margin-bottom: 1em;
}

.img {
    display: block;
    margin: auto;
}

@media (max-width: 1024px) {
   .space {
    min-width: 22em;
    max-width: 22em;
   }
}