.partners__container {
    /* margin-top: 1em; */
    /* margin-bottom: 2em; */
    /* opacity: 0.8; */
    background: #FFF;
    padding: 6.063em 1.875em;
}

.img__container {
    display: flex;
    /* border: solid; */
    padding-top: 2em;
    align-content: center;
    justify-content: center;
    max-width: 74.375em;
    margin: auto;
}

.img {
    flex: 1;
    /* border: solid; */
    padding: 2em 0 1em;  
}

.img:last-child {
    padding: 2.7em 1em 1em;
}

.img > img {
    display: block;
    margin: auto;
    /* width: 18.268em; */
    /* border: solid; */
}


.partners__container > p {
    flex: none;
    color: #666;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.img:first-child {
    padding: 0;
}

.img:first-child > img {
    width: 18.268em;
    opacity: 1;
}
@media (max-width: 1024px) {
    .partners__container {
        padding: 2em 0 6em;
        
    }

    .img__container {
        /* display: block; */
        width: 90vw;
        /* border: solid   ; */
        flex-wrap: wrap;
        /* margin-left: -1em; */
        /* margin-right: 1em; */
        align-items: center;
        margin: auto;
        gap:1em;
    }

    .img {
       flex: 30%;
        margin: .5em auto;
        padding: 0;
        width: 8.25em;
        /* border: solid; */

    }

        
    .img > img {
        width: 8.25em;
        /* border: solid; */
    }
    
    
    
    
    
    .img:first-child > img {
        width:  8.25em;
        opacity: 1;
    }
    
    .img:nth-child(2) > img {
        width: 3.563em;
        margin-top: .7em;
        /* border: solid; */
    }
    
    .img:nth-child(3) > img {
        width: 7.188em;
        margin-top: .5em;
    }

    .img:nth-child(4) > img {
        width: 4.375em;
    }

    .img:last-child {
        padding: 0;
    }
    
    .img:last-child > img {
        width: 7.75em;
        margin-top: .3em;
        /* border: solid; */
    }

    .partners__container > p {
        word-break: keep-all;
        width: 15em;
        margin: auto;
    }

}