.container {
    margin: 2em auto;
    background-image:  linear-gradient(to bottom, rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.69)), url("/public/images/job_banner.png");
    border-radius: 23px;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 33.834em; */
    padding: 7em 0;
    max-width: 75em;
}

.sub_container {
    display: block;
    margin: auto;
    /* border: solid; */
    max-width: 39.765em;
}

.tag {
    color: #C4C4C4;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin: auto;
}

.title {
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 67px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 79.73px */
    text-transform: capitalize;
    margin: auto;
    word-break: keep-all;
}

.next_line {
    display: block;
}

.proud {
    color: #FFF;
    font-family: 'Playfair Display', serif;
    font-size: 67px;
    font-style: italic;
    font-weight: 700;
    line-height: 119%;
    text-transform: capitalize;
}
.dot {
    color: #626262;
    font-family: 'Lato', sans-serif;
    font-size: 67px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
    text-transform: capitalize;
}

.video_link {
    color: #C8C8C8;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 15em;
    display: block;
    margin: auto;
    /* border: solid; */
}

.video_link::before {
    content: url("/public/images/video_icon.png");
    vertical-align: -.5em;
    margin-right: 1em;
}

@media (max-width: 1024px) {
    .container {
        margin: 2em 1em;
        padding: 5em 0 3em;
        background-size: cover;
        background-image:  linear-gradient(to bottom, rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.69)), url("/public/images/careers_bg_mobile.png");
    }

    .title, .proud, .dot {
        font-size: 44px;
        text-align: center;
    }

    .title {
        /* border: solid; */
        max-width: 6.5em;
    }
    
    .tag {
        font-size: 16px;
        margin-bottom: 1em;
    }

    .video_link {
        margin-top: 4em;
        margin-bottom: 0;
    }

    .next_line {
        display: inline;
    }

}