.container {
    display: block;
    margin-top: 22em;
}

.top {
    padding: 0;
    max-width: 75em;
    display: block;
    margin: auto;
}

.title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: 119%;
    text-transform: capitalize;
    margin-bottom: 0;
}

.pink {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: 119%; /* 83.3px */
    text-transform: capitalize;
}

.note {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 26.18px */
    text-transform: capitalize;
    margin-top: 1em;
}

.bottom {
    display: flex;
    /* border: solid; */
    margin-top: 12em;
    
}

.partition, .partition2, .partition3 {
    /* flex: 33.33%; */
    /* flex: 0 0 33.33%; */
    /* border: solid; */
    flex: 1;
}

.partition > img {
    display: block;
    margin-top: 3.75em;
}

.partition > img:nth-child(2) {
    display: block;
    margin-top: 3.75em;
    height: 16.65em;
}

.partition2 > img {
    display: block;
    margin: auto;
    /* margin-left: 2.438em; */
    margin-top: 3.75em;
    width: 30em;
}

.partition2 > img:first-child {
    height: 43em;
}

.partition2 > img:nth-child(2) {
    height: 16.1em;
}

.partition3 > img {
    display: block;
    /* float: right; */
    /* margin-left: 2.438em; */
    margin-top: 3.75em;
    margin-left: auto;
    margin-right: 0;
}

.partition3 > img:first-child {
    height: 31.3em;
}


@media (max-width: 1024px) {
    .container {
        display: block;
        margin-top: 7em;
    }

    .top {
        padding: 0 1em;
    }
    

    .title, .pink {
        font-size: 30px;
        text-align: center;
        display: block;
    }

    .note {
        text-align: center;
        font-size: 16px;
    }

    .bottom {
        /* display: block; */
        clear: both;
        margin-top: -2em;
        /* border: solid; */
    }

    .partition > img,  
    .partition2 > img,    
    .partition3 > img {
       width: 5em;
       display: block;
       /* margin: auto; */
       margin-top: 2em;
       /* float: none; */
       /* border: solid; */
    }

    .partition > img:last-child {
        height: auto;
    }

    .partition2 > img:first-child {
        width: 7.188em;
        height: auto;
        margin-top: 3em;
    }

    .partition2 > img:last-child {
        width: 8.118em;
        height: auto;
        margin-top: 4em;
        /* border: solid; */
    }

    .partition3 > img:first-child {
        height: auto;
    }
    
    .partition3 > img:last-child {
        height: auto;
        margin-top: 5.3em;
    }
}