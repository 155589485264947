.container {
    margin-top: 2em;
    padding: 2em 0;
    display: flex;
    margin: auto;
    max-width: 75em;
}

.tagline {
    color: #949494;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.next_line {
    display: block;
}

.title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
    text-transform: capitalize;
}

.pink {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 85.68px */
    text-transform: capitalize;
}

.text_container, .img_container {
    flex: 1;
}

.text_body {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.img {
    display: block;
    margin: auto;
    margin-right: 0;
    /* margin-top: 3em;
    width: 17em; */
}



@media (max-width: 1024px) {
    .container {
        padding: 2em 1em;
        /* display: block; */
        flex-direction: column;
    }

    .tagline {
        font-size: 16px;
    }

    .title, .pink {
        font-size: 39px;
    }

    .text_body {
        font-size: 18px;
    }

    .text_container, .img_container {
        display: block;
    }

    .img {
        display: block;
        margin: auto;
        margin-top: 3em;
        width: 17em;
    }
}