.ol {
    margin: .5em auto;
    display: block;
    max-width: 68em;
    padding: 1em 0 5em;
    /* border: solid; */
}

.li {
    color: #747474;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: .6em .4em;
    word-break: keep-all;
}

.email {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

@media (max-width: 1024px) {
    .ol {
        margin: .5em .2em;
    }
    
    .li {
        padding: .5em .5em;
    }

    .li, .email {
        font-size: 15px;
    }
   
}