.container {
    margin: 0em auto 7em;
    display: block;
    background: url("/public/images/milestones_bg.png");
    background-color: #FFFBF2;
    padding: 1.75em 4.875em 8em;
    overflow: hidden;
    max-width: 74.375em;
    /* border: solid; */
    /* height: 90em; */
}

.title {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 61px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* margin-bottom: 3.625em; */
}

.black_founders_card, .disbursed_loan, .launched {
    display: flex;
    border-radius: 27px;
    background: #FFE9F8;
    width: 42.313em;    
    padding: 5em 3.25em;
}

.k_1 {
    margin-top: -10em;
    margin-left: 31em;
    margin-bottom: 3em;
}

.f_1 {
    margin-left: 54.8em;
    margin-top: -7.2em;
}

.f_2 {
    margin-left: 42.3em;
    margin-top: -5.3em;
    position: absolute;
    z-index: 2;
}

.i_3 {
    margin-top: -3em;
    height: 5em;
    margin-left: 5em;
}

.l_1 {
    margin-top: -10.3em;
    margin-left: 8em;
}

.l_2 {
    /* margin-top: -55em; */
    /* margin-top: 0; */
    margin-bottom: 0;
    margin-left: 67em;
    /* border: solid; */
    /* width: auto; */
    /* height: 3em; */
  
}

.l_3 {
    margin-top: -1em;
    margin-left: 60.9em;
}

.l_4 {
    /* margin-top: -12.5em; */
    margin-left: 3em;
}

.l_5 {
    /* margin-top: -18em; */
    margin-left: 63em;
}

.d_1 {
    /* border: solid; */
    margin-top: -26.7em;
    margin-left: 36.8em;
}

.v_1 {
    /* border: solid; */
    vertical-align: top;
    margin-top: -.5em;
    margin-left: 40em;
}

.v_2 {
    /* border: solid; */
    display: block;
    /* margin-top: -6.93em; */
    margin-left: 3.72em;
}

.v_3 {
    /* border: solid; */
    display: block;
    margin-left: -1.9em;
    margin-top: -5.9em;
}

.v_4 {
    display: block;
    margin-top: -14em;
    margin-left: -.6em;
}

.m_4 {
    margin-top: -15em;
    margin-left: 0em;
    margin-bottom: 12em;
}

.v_5 {
    display: block;
    margin-left: -3em;
    /* margin-top: -.9em; */
}

.v_6 {
    display: block;
    margin-left: 23.5em;
    /* margin-top: -5.17em; */
    position: relative;
    z-index: 2;
}

.v_7, .v_8 {
    display: none;
}

.disbursed_loan {
    border-radius: 27px;
    background: #D6FFE7;  
    margin-left: 24em;
    margin-top: -.7em;
}

.launched {
    clear: both;
    border-radius: 27px;
    background: #FFF1D6;
    margin: auto;
    /* margin-top: -3.7em; */
    position: absolute;
    margin-left: 10em;
    z-index: 1;
    /* border: solid; */
    /* z-index: 1;
    position: relative;*/
    /* overflow: auto; */
}

.left, .right {
    flex: 1;
    padding: 0 1em;
}

.date {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.disbursed_loan > .date {
    color: #7B7B7B;
}

.card_title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text_body {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
}

@media (max-width: 1024px) {
    .container {
        padding: .75em 0 0em;
        max-width: 100%;
    }

    .title {
        font-size: 38px;
        text-align: left;
        padding-left: .5em;
    }

    .black_founders_card, .disbursed_loan, .launched {
        width: 92%;    
        padding: 4em .5em 2em;
        margin: auto;
    }
    
    .disbursed_loan {
        /* margin-top: -1em; */
        margin-right: 0;
    }

    .launched {
        margin: auto;
        margin-top: -1em;
        /* margin-left: 10em; */
        z-index: 1;
        position: relative;
    }

    .date {
        font-size: 12px;
    }

    .card_title {
        font-size: 17px;
    }

    .text_body {
        font-size: 14px;
    }

    .k_1 {
        margin-top: -8em;
        margin-left: 9em;
    }
    
    .f_1 {
        margin-left: 16.8em;
        margin-top: -2em;
        width: 2.875em;
        height: 2.875em;
    }
    
    .f_2 {
        margin-left: 17em;
        margin-top: -3em;
        width: 3em;
        height: 3em;
        position: absolute;
        z-index: 2;
    }
    
    .i_3 {
        margin-top: -1em;
        display: none;
    }
    
    .l_1 {
        /* margin-top: -25em; */
        margin-left: 8em;
        display: none;
    }
    
    .l_2 {
        margin-top: -1em;
        margin-left: calc(85vw);
        /* margin-right: 0; */
        margin-bottom: 5em;
        width: 3.313em;
        height: 3.313em;
    }
    
    .l_3 {
        margin-top: -1em;
        margin-left: 60.9em;
    }
    
    .l_4 {
        margin-top: -1em;
        margin-left: 3em;
        display: none;
    }
    
    .l_5 {
        margin-top: -1em;
        margin-left: 63em;
    }
    
    .d_1 {
        /* border: solid; */
        margin-top: -1.7em;
        margin-left: 4.2em;
        width: 2.063em;
        height: 2.063em;
    }
    
    .v_1 {
        /* border: solid; */
        /* vertical-align: top; */
        margin-top: -1.5em;
        margin-left: 40em;
        display: none;
    }
    
    .v_2 {
        /* border: solid; */
        display: block;
        margin-top: -1.93em;
        margin-left: 3.72em;
        display: none;
    }
    
    .v_3 {
        /* border: solid; */
        display: block;
        margin-left: -1.9em;
        margin-top: -1.4em;
        display: none;
    }
    
    .v_4 {
        display: none;
        margin-top: -1em;
        /* margin-left: 1em; */
        /* margin-right: 0; */
        margin-left: -.6em;
    }
    
    .m_4 {
        margin-top: 6em;
        margin-left: 2em;
        width: 2.213em;
        height: 2.213em;
        position: absolute;
    }
    
    .v_5 {
        display: none;
        margin-left: -3em;
        margin-top: -.9em;
    }
    
    .v_6 {
        display: none;
        margin-left: 3.5em;
        margin-top: -1.17em;
        position: relative;
        z-index: 2;
    }

    .v_7 {
        display: block;
        margin-left: 4.8em;
        margin-top: -1.17em;
        width: 15em;
        /* position: relative; */
        /* z-index: 2; */
    }

    .v_8 {
        display: block;
        margin-left: 4.8em;
        margin-top: -1em;
        width: 13em;
        position: relative;
        z-index: 2;
    }
}


