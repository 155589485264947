.loan__section {
    /* height: 54.563em; */
    background: url("/public/images/home_loan_background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0;
    padding: 5.403em 4.063em 8em;
}

.sub__container {
    display: flex;
    height: 49.563em;
    max-width: 74em;
    margin: auto;
}

.text__container {
    flex: 1;
    padding-top: 10em;
}

.loan__tagline {
    width: 12em;
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 4.625em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* margin-left: 2.063em; */
    /* top: 7.733em; */

}

.loan__small {
    color: #CFCFCF;
    font-family: 'Lato', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 2.675em;
    margin-bottom: 2.375em;
    width: 26.5em;
    word-break: keep-all;
}
.logo__container {
    flex: 1;
    display: block; 
    text-align: right; 
    width: 100%;
    
}


@media (max-width: 1024px) {
    .loan__section {
        display: block;
        background-size: cover;
        padding: 5.403em 1em 10.688em;
    }

    .sub__container{
        display: flex;
        height: auto;
        flex-direction: column-reverse;
    }

    .loan__tagline {
        font-size: 32px;
        word-break: keep-all;
        /* width: 98%; */
        width: auto;
        text-align: left;
        /* border: solid; */
    }
    .loan__small {
        font-size: 16px;
        word-break: keep-all;
        /* width: 98%; */
        width: auto;
        /* border: solid; */
    }
}