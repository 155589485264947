.container {
    display: block;
    margin: 7.625em auto;
}

.title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 39px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
}

.note {
    color: #000;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.img {
    display: block;
    margin: auto;
}