
.container {
    background: #FFEEFA;
}

.sub_container {
    display: flex;
    max-width: 75em;
    margin: auto;
}

.img-partition {
    flex: 1;
    padding: 3.949em 0 5.03em 0;
    /* border: solid; */
}

.text-partition {
    flex: 2;
    padding-top: 4.033em;
   
}

.title {
    color: var(--Primary, #EB2590);
    font-family: 'Playfair Display', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* padding-left: 2.25em */
    max-width: 27em;
    display: block;
    margin: auto;
    /* border: solid; */
    margin-bottom: .5em;
}

.name {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: auto;
    margin-right: 0;
    /* border: solid; */
    width: 21.8em;
}

.about {
    color: #4B4B4B;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 29em;
    word-break: keep-all;
    margin-left: auto;
    margin-right: 0;
}


@media (max-width: 1024px) {
    .container {
       margin-top: 2em;
        background: #FFEEFA;
    }

    .sub_container {
        flex-direction: column;
    }

    
    
    .img-partition {
       display: block;
        /* padding: 3.949em 2.715em 5.03em 5.813em; */
        padding: 0;
        margin: auto;
        padding-top: 3em;
        /* border: solid; */
    }

    .img-partition > img {
        width: 17em;
        display: block;
        margin: auto;
    }
    
    .text-partition {
        display: block;
        padding: 4.033em 1em;
    }
    
    .about{
        word-break: keep-all;
        width: auto;
        font-size: 16px;
    }

    .name {
        word-break: keep-all;
        text-align: center;
        width: auto;
        font-size: 26px;
    }

    .title {
        margin-top: 2em;
        padding: 0;
        word-break: keep-all;
        padding: 0 1em;
        text-align: center;
        font-size: 30px;
    }

}