.container {
    margin: 0;
    text-align: center;
    background: #000;
    padding: 5.625em 0 5.475em;
}

.tagline {
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 28.41px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 33.808px */
    text-transform: capitalize;
}

.title {
    background: linear-gradient(91deg, #F598D8 1.82%, #EC288C 20.95%, #EB2590 24.51%, #EB2590 26.54%,
     #EB2590 41.8%, #F598D8 87.06%, #F598D8 99.49%, #F598D8 99.5%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 71px;
    font-style: normal;
    font-weight: 800;
    line-height: 119%; /* 84.49px */
    text-transform: capitalize;
}

.next_line {
    display: block;
}

.down_note {
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 27.41px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 32.618px */
    text-transform: capitalize;
}

@media (max-width: 1024px) {
    .container {
        padding: 5.625em 1em 5.475em;
    }
    
    .title {
        word-break: keep-all;
    }

    .down_note {
        word-break: keep-all;
    }

    .tagline {
        font-size: 25.41px;
    }
    
    .title {
        font-size: 45px;
    }

    .down_note {
        font-size: 16.41px;
        margin-top: 3em;
    }
}