.container {
    padding: 1em 1.75em;
    max-width: 78em;
    margin: auto;
}
.p {
    color: #393939;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word;
    word-break: keep-all;
    text-align: justify;
}
.copyright {
    margin-top: 3.375em;
}

@media (max-width: 1024px) {
    .copyright {
        text-align: center;
        max-width: 20em;
    }
}