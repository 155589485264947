.container {
    display: block;
    flex: 1;
    margin-bottom: 3em;
    /* margin-right: 2em; */
    /* border: solid; */

}

.img {
    display: block;
    margin: auto;
}


.text_container {
    background: #FFF;
    padding: 1.813em 1em;
    width: 19.8em;
    display: block;
    margin: auto;
}

.title {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.text_body {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 23.8px */
    text-transform: capitalize;
}

@media (max-width: 1024px) { 
    .container {
        margin-right: 0;
        flex: 1;
    }

    .text_container, .img {
        display: block;
        margin: auto;
        width: 18.578em;
    }

    .title {
        font-size: 22px;
    }

    .text_body {
        font-size: 18px;
        min-height: 7em;
    }
    
    .img {
        min-width: 18.578em;
        border-radius: 0 27px 0 0;
    }

}