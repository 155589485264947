.container{
    flex: 33.333333%;
}

.text_container {
    width: 19.313em;
}

.heading {
    display: flex;
    padding: 1em 0;
}

.author {
    flex: 1.3;
    color: #5B5B5B;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: auto;
}

.status {
    flex: .7;
    color: #707070;
    font-family: 'Lato', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    border-radius: 3px;
    background: #F8F8F8;
    text-align: center;
    padding: 0.5em 0;
}

.text_body {
    color: #200011;
    font-family: 'Lato', sans-serif;
    font-size: 17.41px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 20.718px */
    text-transform: capitalize;
}

.news_date {
    color: #464646;
    font-family: 'Lato', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    clear: both;
}

@media (max-width: 1024px) {
    .container{
       display: block;
    }

    .img {
        /* width: 17.313em; */
        display: block;
        margin: auto;
    }

    .text_container {
        /* width: 17.313em; */
        display: block;
        margin: auto;
        margin-bottom: 3em;
    }

    .text_body {
        margin-top: auto;
    }
    
}