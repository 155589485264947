.ellipse__container {
    display: inline-block;
    margin-left: -15.875em;
    margin-top: -7.688em;
}

.ellipse__content {
    /* display: inline-block; */
    position: relative;
    margin-left: -15.875em;
    top: -15.875em;
    width: 31.438em;
    height: 31.438em;
    border-radius: 31.438em;
    border: 50px solid rgba(235, 37, 144, 0.05);
}