@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');



@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";




@media (max-width: 1024px) {

      /* Hide the default scrollbar */
      .custom-scrollbar::-webkit-scrollbar {
        display: none;
    }
  
    /* Style the scrollbar track */
    .custom-scrollbar {
        scrollbar-width: thin; /* For Firefox */
        scrollbar-color: transparent transparent; /* For Firefox */
    }
  
    /* Style the scrollbar thumb */
    .custom-scrollbar::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}