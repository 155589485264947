.container {
    background: #FFF8FD;
    padding: 2em 0;
}

.sub_container {
    display: flex;
    margin: auto;
    max-width: 75em;
    /* border: solid; */
}

.text_container, .img_container {
    flex: 2;
}

.img_container {
    flex: .9;
    overflow: hidden;
    /* border: solid; */
}


.img {
    display: block;
    margin: 2em auto;
    width: 22.75em;
    /* min-width: 22.75em; */
    height: 30.75em;
    margin-left: 0;
    border-radius: 207px 207px 0 0;
}

.tagline {
    color: #949494;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 5em;
}

.title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 49px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%; /* 58.31px */
    text-transform: capitalize;
    margin-top: 1em;
}

.next_line {
    display: block;
}

.testimonial_container {
    min-width: 32.93em;
    /* min-height: 15.25em; */
    /* height: 20.25em; */
    height: fit-content;
    border-radius: 90px 0px;
    background: #FFF;
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.025);
    padding: 1.188em 2.501em;
    margin-top: 3em;
}

.heading {
    display: flex;
    padding: 1em 1.387em;
}

.name, .position {
    flex: 1;
    text-align: left;
    color: #000;
    font-family: 'Playfair Display', serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(92deg, #EB2590 33.09%, #F73 88.68%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.position {
    text-align: right;
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1.5em;
}


.navigators {
    display: flex;
    /* border: solid; */
    margin: 2em auto;
    width: 13em;
    justify-content: center;
}

.nav {
    /* display: inline-block; */
    vertical-align: middle;
}

.nav:hover {
    cursor: pointer;
}

.more {
    margin: auto 2em;
    display: inline-block;
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.testimonial_body {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

@media (max-width: 1024px) {
    .container {
        background: #FFF8FD;
        padding: 2em 1em;
    }

    .tagline {
        font-size: 16px;
    }

    .title {
        font-size: 38px;
    }

    .name {
        font-size: 25px;
    }

    .testimonial_body {
        font-size: 16px;
    }

    .position {
        font-size: 8px;
    }
    
    .sub_container {
        display: flex;
        flex-direction: column;
    }
    
    .text_container, .img_container {
        flex: 1;
    }

    .testimonial_container {
        min-width: 22em;
        padding: 1em 1em;
        height: fit-content;
        margin-top: 2em;
    }

    .img_container {
        margin: 2em auto;
        max-width: 19em;
        /* border: solid; */
        overflow: hidden;
    }

    .img {
        width: 19em;
        height: 26em;
        display: block;
        margin: auto;
    }
}