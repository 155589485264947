.banner__container {
    display: flex;
    padding-top: 5.438em;
    /* border: solid; */
    max-width: 75em;
    margin: auto;
    /* overflow: scroll; */
}

.partition {
    flex: 1;
    /* border: solid; */
    padding: 12.063em 0 0 .375em;
}

.partition_left {
    margin-left: -20em;
}

.word_one {
    text-align: left;
    color: #949494;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* border: solid; */
    width: 15.813em;
    /* margin: auto; */
    margin-top: -3em;
    margin-bottom: 3em;
    
}

.word_two {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    word-break: keep-all;
    width: 8.5em;
     /* border: solid; */
     display: block;
     /* margin: auto; */
}

.word_two > span {
    color: var(--Primary, #EB2590);
    font-family: 'Playfair Display', serif;
    font-size: 56px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}

.word_three {
    color: var(--Footer-Dark, #747474);
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: keep-all;
    width: 22.5em;
}

.img {
    display: block;
    margin-right: 0;
    margin-left: auto;
    margin-top: -8em;
    /* border: solid; */
    /* position: absolute; */
    width: 26.875em;
}

@media (max-width: 1024px) {
    .banner__container {
        display: block;
        /* margin-top: 15.063em; */
        padding: 1.063em 1em 0 1em;
        /* border: solid; */
        margin-top: 4em;
    }
    
    .partition {
        flex: none;
        display: block;
        /* border: solid; */
        padding: 0;
    }

    .partition_left {
        margin: auto;
    }
    .img {
        margin-right: 0em;
        display: block;
        margin: auto;
        margin-top: -15em;
        width: 80%;
        margin-bottom: 2em;
        padding: 0;
        /* border: solid; */
        /* position: absolute; */
    }
    
    .word_one {
        /* border: solid; */
        width: auto;
        margin: auto;
        margin-bottom: 2em;
        display: block;
        text-align: center;
    }
    
    .word_two {
        font-size: 32px;
        width: 10em;
         /* border: solid; */
         display: block;
         margin: auto;
         margin-bottom: 1em;
         text-align: center;
    }

    .word_two > span {
        font-size: 36px;
    }

    .word_three {
        font-size: 16px;
        width: 21em;
        display: block;
        margin: auto;
        text-align: center;
         /* border: solid; */
    }
}