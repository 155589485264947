.container {
    display: block;
    padding: 12.813em 8em;
    margin: auto;
    max-width: 75em;
}

.text {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
}

.pink {
    color: var(--Primary, #EB2590);
    font-family: 'Lato', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media (max-width: 1024px) {
    .container {
        padding: 4.813em 1em;
    }

    .text, .pink {
        font-size: 19px;
    }

    .text {
        margin: auto;
        display: block;
        /* border: solid; */
    }
}